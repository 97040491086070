import * as React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { createCurrencyFormatter } from 'src/utils/currency'

import { useSignUp } from './useSignUp'

function parsePositiveInteger(value) {
  const parsedValue = Number.parseInt(value, 10)

  return Number.isFinite(parsedValue)
    ? Math.max(0, parsedValue)
    : 0
}


export function PlanStep() {

  const { values, setFieldValue, userPrices } = useSignUp()

  function handleCountChange(field, value, min = 0) {
    setFieldValue(
      field,
      Math.max(min, parsePositiveInteger(value)),
    )
  }

  const currencyFormatter = React.useMemo(
    () => createCurrencyFormatter(),
    [],
  )

  if (!userPrices) {
    return 'Loading...'
  }

  const billingIntervalPrices = userPrices[values.billingInterval]

  return (
    <>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>

          <Card>
            <CardContent>
              <Typography variant="h3">
                Select your billing interval:
              </Typography>

              <TextField
                select
                value={values.billingInterval}
                onChange={event => setFieldValue('billingInterval', event.target.value)}
              >
                <MenuItem value="MONTHLY">Monthly</MenuItem>
                <MenuItem value="YEARLY">Yearly</MenuItem>
              </TextField>
            </CardContent>
          </Card>
        </Grid> */}

        <Grid item xs={12} sm={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h3">
                Financial Users
              </Typography>

              <Typography sx={{ mb: 2 }}>
                Full project management along with billing and financial data
              </Typography>

              <Typography variant="h3" sx={{ mb: 2 }} align="center">
                {`${
                  currencyFormatter.format(billingIntervalPrices.FINANCIAL /100)
                } / ${
                  values.billingInterval === 'YEARLY' ? 'year' : 'month'
                }`}
              </Typography>

              <TextField
                inputProps={{
                  style: { fontSize: 32, textAlign: 'center' },
                }}
                value={values.financialUsersLicenseCount || 0}
                onChange={event => handleCountChange(
                  'financialUsersLicenseCount',
                  event.target.value,
                  1,
                )}
                label="Financial Users"
                type="number"
                helperText="All subscriptions required a minimum of 1 Full User"
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h3">
                Production Users
              </Typography>

              <Typography sx={{ mb: 2 }}>
                Perfect for designers and jr. project managers
              </Typography>

              <Typography variant="h3" sx={{ mb: 2 }} align="center">
                {`${
                  currencyFormatter.format(billingIntervalPrices.PRODUCTION /100)
                } / ${
                  values.billingInterval === 'YEARLY' ? 'year' : 'month'
                }`}
              </Typography>

              <TextField
                inputProps={{
                  style: { fontSize: 32, textAlign: 'center' },
                }}
                value={values.productionUsersLicenseCount || 0}
                onChange={event => handleCountChange(
                  'productionUsersLicenseCount',
                  event.target.value,
                )}
                label="Production Users"
                type="number"
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h3">
                Limited Users
              </Typography>

              <Typography sx={{ mb: 2 }}>
                Just the right size for interns and drafters
              </Typography>

              <Typography variant="h3" sx={{ mb: 2 }} align="center">
                {`${
                  currencyFormatter.format(billingIntervalPrices.LIMITED /100)
                } / ${
                  values.billingInterval === 'YEARLY' ? 'year' : 'month'
                }`}
              </Typography>

              <TextField
                inputProps={{
                  style: { fontSize: 32, textAlign: 'center' },
                }}
                value={values.limitedUsersLicenseCount || 0}
                onChange={event => handleCountChange(
                  'limitedUsersLicenseCount',
                  event.target.value,
                )}
                label="Limited Users"
                type="number"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
