import * as React from 'react'
import PropTypes from 'prop-types'

import useModelList from 'src/components/useModelList'
import useModelInstance from 'src/components/useModelInstance'

import {
  SubscriptionPriceModel,
  AdminSettingsModel,
} from 'src/data-model'

import { SubscriptionPricesContext } from './SubscriptionPricesContext'


export function SubscriptionPricesProvider({ children }) {

  const { results: prices = [], status: pricesStatus } = useModelList({
    model: SubscriptionPriceModel,
    limit: 1000,
  })

  const { instance: settings, status: settingsStatus } = useModelInstance({
    model: AdminSettingsModel,
    id: 'SETTINGS',
  })

  function getPrices({ accountType, accessLevel, billingInterval }) {
    return prices.filter(price => accountType === price.accountType
      && accessLevel === price.accessLevel
      && billingInterval === price.billingInterval
    )
  }

  if (pricesStatus === 'loading' || settingsStatus === 'loading') {
    return 'Loading...'
  }

  return (
    <SubscriptionPricesContext.Provider
      value={{ prices, settings, getPrices }}
    >
      {children}
    </SubscriptionPricesContext.Provider>
  )
}

SubscriptionPricesProvider.propTypes = {
  /** Wrapped content of the provider */
  children: PropTypes.node,
}
