import {
  createTimestampFields,
} from './mixins'

import {
  modelFactory,
  stringFieldFactory,
} from 'pebble-orm'


export const Image = modelFactory({
  name: 'Image',
  collection: 'images',
  fields: [
    ...createTimestampFields(),

    stringFieldFactory({
      name: 'ownerId',
    }),

    stringFieldFactory({
      name: 'type',
      defaultValue: 'photo',
    }),

    stringFieldFactory({
      name: 'rootRef',
    }),

    stringFieldFactory({
      name: 'relatedRef',
    }),

    stringFieldFactory({
      name: 'name',
      allowNull: false,
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'description',
      allowNull: false,
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'originalUrl',
      defaultValue: '',
      allowNull: false,
    }),

    stringFieldFactory({
      name: 'croppedUrl',
      defaultValue: '',
      allowNull: false,
    }),

    stringFieldFactory({
      name: 'thumbnailUrl',
      defaultValue: '',
      allowNull: false,
    }),
  ],
})


export const ImageRefModel = modelFactory({
  name: 'ImageReferenceModel',
  collection: 'image-refs',
  isSubmodel: true,
  fields: [
    stringFieldFactory({
      name: 'imageId',
    }),

    stringFieldFactory({
      name: 'url',
    }),

    stringFieldFactory({
      name: 'thumbnailUrl',
    }),
  ]
})