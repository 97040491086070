import { firebaseDb, firebaseFunctions } from 'src/services/firebase'
import { AccountModel } from 'src/data-model'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'


/**
 * Create a account
 */
 export function useCreateAccount() {
  const loader = useLoader()
  const snackbar = useSnackbar()

  async function createAccount ({ values, onSuccess, onError, isExistingCustomer = false }) {
    loader.open('Creating account...')

    const data = AccountModel.toDb(values, true)

    const initializeAccountStripeInfo = firebaseFunctions.httpsCallable(
      'initializeAccountStripeInfo'
    )

    try {

      const newDoc = await firebaseDb.collection(AccountModel.collection).add(data)

      const ref = firebaseDb.collection(AccountModel.collection).doc(newDoc.id)
      const doc = await ref.get()

      const account = AccountModel.fromDb(doc.id, doc.data())

      const response = await initializeAccountStripeInfo({
        ...AccountModel.toApi(account),
        isExistingCustomer,
      })

      if (response?.data?.status !== 'success') {
        // Delete failed account
        await firebaseDb.collection(AccountModel.collection).doc(doc.id).delete()

        throw response?.error ?? new Error('There was a problem creating Stripe account.')
      }

      snackbar.addMessage({
        text: 'Account created.',
        type: 'success',
      })

      onSuccess(account.id)
    } catch (error) {
      snackbar.addMessage({
        text: error?.message ?? 'There was an error account creating account.',
        type: 'error',
      })

      onError(error)
    } finally {
      loader.close()
    }
  }

  return createAccount
}


/**
 * Update an account
 */
 export function useUpdateAccount() {
  const loader = useLoader()
  const snackbar = useSnackbar()

  async function updateAccount ({ accountId, values, onSuccess, onError, onSettled, shouldUpdateBilling = false }) {
    loader.open('Updating account...')

    const updateAccountSubscription = firebaseFunctions.httpsCallable(
      'updateAccountSubscription'
    )

    try {
      // const ref = firebaseDb.collection(AccountModel.collection).doc(accountId)
      // await ref.update(values)
      // const doc = await ref.get()
      // const account = AccountModel.fromDb(accountId, doc.data())

      const response = await updateAccountSubscription({
        accountId,
        ...values,
      })

      if (response?.data?.status !== 'success') {
        throw response?.error ?? new Error('There was a problem updating Stripe account.')
      }

      snackbar.addMessage({
        text: 'Account updated.',
        type: 'success',
      })

      onSuccess?.(accountId)
    } catch (error) {
      snackbar.addMessage({
        text: error?.message ?? 'There was a account updating account.',
        type: 'error',
      })

      onError?.(error)
    } finally {
      loader.close()
      onSettled?.()
    }
  }

  return updateAccount
}
