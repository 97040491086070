import * as React from 'react'
import PropTypes from 'prop-types'

import LoadingButton from '@material-ui/lab/LoadingButton'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import DialogContent from '@material-ui/core/DialogContent'

import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'
import { firebaseFunctions } from 'src/services/firebase'

import LinkBehavior from 'src/components/LinkBehavior'
import { useUsers } from 'src/components/UsersProvider'


export function GhostLoginButton({ account, user, ...buttonProps }) {
  const { handleLaunchApp } = useUsers()

  const isUnpaid = account?.stripeSubscriptionStatus === 'unpaid'
    && account?.accountType === 'LIVE'

  const [showWarning, setShowWarning] = React.useState(false)

  const [paymentValidation, setPaymentValidation] = React.useState({
    status: 'idle',
    info: null,
  })

  const check = React.useCallback(
    async accountToCheck => {
      const checkAccountStatus = firebaseFunctions.httpsCallable(
        'checkAccountStatus'
      )

      setPaymentValidation({
        status: 'loading',
        info: null,
      })

      const response = await checkAccountStatus({ ...accountToCheck })

      if (response?.data?.status !== 'success') {
        // TODO
        setPaymentValidation({
          status: 'error',
          info: response?.data,
        })

        return
      }

      setPaymentValidation({
        status: 'all-good',
        info: response.data,
      })
    },
    [],
  )

  React.useEffect(
    () => {
      if (account.subscriptionType !== 'AUTOMATIC') {
        setPaymentValidation({
          status: 'all-good',
          info: {
            paymentMethodRequired: false,
          },
        })
      } else {
        check(account)
      }
    },
    [account, check]
  )

  async function callFileMaker() {
    // loader.open('Launching Base Builders...')

    // formRef.current?.submit()
    await handleLaunchApp(user)
  }

  function handleClick(event) {
    event.stopPropagation()

    const {
      paymentMethodRequired = true,
      hasPaymentMethod = false,
      isDelinquent = false,
    } = paymentValidation?.info

    if ((paymentMethodRequired && !hasPaymentMethod) || isDelinquent || isUnpaid) {
      setShowWarning(true)
    } else {
      callFileMaker()
    }
  }

  return (
    <>
      <LoadingButton
        {...buttonProps}
        variant="contained"
        onClick={handleClick}
        disabled={buttonProps.disabled || paymentValidation?.status !== 'all-good'}
        loading={paymentValidation?.status === 'loading'}
      >
        Launch App
      </LoadingButton>

      <Dialog
        open={showWarning}
        onClose={() => setShowWarning(false)}
        maxWidth="sm"
      >
        <ClosableDialogTitle onClose={() => setShowWarning(false)}>
          Payment Method Required
        </ClosableDialogTitle>

        <DialogContent>
          {Boolean(paymentValidation?.info?.isDelinquent) && (
            <Typography variant="h6" color="error" sx={{ mb: 2 }}>
              Your account is currently past due!
            </Typography>
          )}

          <Typography variant="subtitle">
            Please update your account payment method to prevent any disurption to your service. Thank you!
          </Typography>

          <Link
            display="block"
            component={LinkBehavior}
            to={`/accounts/${account.id}`}
            sx={{ mb: 4, mt: 2  }}
          >
            Click here to go to acccount settings...
          </Link>

          <div style={{ width: '100%', textAlign: 'center' }}>
            <Button
              sx={{ fontSize: 24 }}
              variant="contained"
              onClick={callFileMaker}
              disabled={isUnpaid}
            >
              Launch App
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

GhostLoginButton.propTypes = {
  account: PropTypes.object,
  user: PropTypes.object,
}
