import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { useAccount } from 'src/components/AccountProvider'
import {
  AccessLevelChoices,
} from 'src/data-model'

export function AccountUsersFilters() {

  const {
    filters,
    setFilterValue,
    setFilterAccessGroup,
    accessGroups,
    profitCenters,
  } = useAccount()

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <TextField
          value={filters.accessLevel}
          onChange={event => setFilterValue('accessLevel', event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
          disabled={Boolean(filters.accessGroup)}
        >
          <MenuItem value="">All Access Levels</MenuItem>

          {AccessLevelChoices.getChoices().map(choice => (
            <MenuItem key={choice} value={choice}>
              {AccessLevelChoices.getLabel(choice)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={3}>
        <TextField
          value={filters.activeStatus}
          onChange={event => setFilterValue('activeStatus', event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Statuses</MenuItem>
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={3}>
        <TextField
          value={filters.accessGroup?.id ?? ''}
          onChange={event => {
            const accessGroupId = event.target.value
            const accessGroup = accessGroups.find(ag => ag.id === accessGroupId)
            setFilterAccessGroup(accessGroup ?? null)
          }}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Access Groups</MenuItem>

          {accessGroups.map(ag => (
            <MenuItem key={ag.id} value={ag.id}>
              {ag.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={3}>
        <TextField
          value={filters.profitCenter?.id ?? ''}
          onChange={event => {
            const profitCenterId = event.target.value
            const profitCenter = profitCenters.find(pc => pc.id === profitCenterId)
            setFilterValue('profitCenter', profitCenter ?? null)
          }}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Profit Centers</MenuItem>

          {profitCenters.map(pc => (
            <MenuItem key={pc.id} value={pc.id}>
              {pc.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}
