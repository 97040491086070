import { firebaseDb } from 'src/services/firebase'
import { ProfitCenterModel } from 'src/data-model'
import { DateTime } from 'luxon'
import { createGlobalId } from './file-maker'


export async function syncProfitCenters({ accountId, fmProfitCenters, fbProfitCenters }) {

  const fmProfitCenterIds = new Set(fmProfitCenters.map(ag => ag._ID_Office))

  // Find the groups still in the Firebase db that need to be removed.
  fbProfitCenters.forEach(async fbProfitCenter => {
    if (!fmProfitCenterIds.has(fbProfitCenter.id)) {
      await firebaseDb.collection(ProfitCenterModel.collection).doc(fbProfitCenter.id).delete()
    }
  })

  // Iterate through the FileMaker access groups and either create, update, or
  // leave the same.
  fmProfitCenters.forEach(async fmProfitCenter => {
    const {
      _KF_Active: isActive = 1,
      _OfficeName: name = '',
      _ID_Office: fmid,
    } = fmProfitCenter

    const id = createGlobalId({ accountId, fmid })

    const fbProfitCenter = fbProfitCenters.find(fbAg => fbAg.id === id)
      || ProfitCenterModel.create({ id, accountId, fmid })

    // Sync all the values from the FileMaker instance
    fbProfitCenter.name = name
    fbProfitCenter.isActive = Boolean(isActive)
    fbProfitCenter.modified = DateTime.local()

    await firebaseDb.collection(ProfitCenterModel.collection).doc(id).set(
      ProfitCenterModel.toDb(fbProfitCenter)
    )
  })
}