import * as React from 'react'
import { SignUpContext } from './SignUpContext'


export function useSignUp() {
  const context = React.useContext(SignUpContext)

  if (context === undefined) {
    throw new Error('useSignUp must be used within SignUpProvider')
  }

  return context
}
