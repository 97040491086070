export function compareProfitCenterToFileMaker(profitCenter, fileMakerRecord) {
  if (!profitCenter) {
    return 'create_firebase'
  }

  if (!fileMakerRecord) {
    return 'create_file_maker'
  }

  const namesAreDifferent = profitCenter.name !== fileMakerRecord._OfficeName
  const isActiveIsDifferent = profitCenter.isActive !== Boolean(fileMakerRecord._KF_Active)
  const shouldUpdate = namesAreDifferent || isActiveIsDifferent

  if (shouldUpdate) {
    return 'update'
  }

  return 'all_good'
}
