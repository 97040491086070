import { firebaseDb, firebaseFunctions } from 'src/services/firebase'
import { UserModel } from 'src/data-model'
import { createGlobalId } from './file-maker'

// These fmids are used for special guest accounts that allow the file maker
// client to check to the previous session info. This allows the users to auto
// log in when the page is refreshed. We need to ignore them when syncing the
// users.
const SPECIAL_ADMIN_IDS = [
  'BaseBuilders',
  'jacobi',
]

export async function importUsersFromFileMaker({ account, fmUsers, fbUsers, accessGroups, profitCenters }) {
  // Store the fmids of the users already created.
  const existingFmids = new Set(fbUsers.map(fbUser => fbUser.fmid))

  // Key/Values => fmid/Access Group
  const accessGroupsByFmid = accessGroups.reduce((result, ag) => {
    result[ag.fmid] = ag
    return result
  }, {})

  // Key/Value => fmid/Profit Centers
  const profitCentersByFmid = profitCenters.reduce((result, pc) => {
    result[pc.fmid] = pc
    return result
  }, {})

  // Iterate through the FileMaker access groups and either create, update, or
  // leave the same.
  for (let fmUser of fmUsers) {
    const {
      StaffEmail: email = '',
      _KF_Active: isActive = 1,
      StaffNamePreferred: name = '',
      _ID_Office_f: profitCenterFmid = '',
      _ID_Access_f: accessGroupFmid = '',
      _ID_Staff: fmid,
    } = fmUser

    // Ignore special admin users for LIVE accounts
    if (account.accountType === 'LIVE' && SPECIAL_ADMIN_IDS.includes(fmid)) {
      continue
    }

    // Ignore existing users
    if (existingFmids.has(fmid)) {
      continue
    }

    const id = createGlobalId({ accountId: account.id, fmid })

    const accessGroup = accessGroupsByFmid[accessGroupFmid]
    const accessGroupId = accessGroup?.id ?? ''
    const accessLevel = accessGroup?.accessLevel ?? 'LIMITED'
    const profitCenterId = profitCentersByFmid[profitCenterFmid]?.id ?? ''

    const fbUser = UserModel.create({
      id,
      accountId: account.id,
      email,
      fmid,
      name,
      accessGroupId,
      profitCenterId,
      accessLevel,
      isActive: Boolean(isActive),
    })

    const ref = firebaseDb.collection(UserModel.collection).doc(id)
    await ref.set(UserModel.toDb(fbUser))

    const updateFileMakerUser = firebaseFunctions.httpsCallable('updateFileMakerUser')
    const updateFileMakerResponse = await updateFileMakerUser({ userId: fbUser.id })

    if (updateFileMakerResponse.data.status !== 'success') {
      console.log('Fail', updateFileMakerResponse)
      await ref.delete()
      throw new Error(updateFileMakerResponse?.error ?? 'Something went wrong syncing with FileMaker')
    }

    const updateAuthUser = firebaseFunctions.httpsCallable('updateAuthUser')
    const updateAuthUserResponse = await updateAuthUser({ userId: fbUser.id })

    if (updateAuthUserResponse.data.status !== 'success') {
      // await ref.delete()
      // console.log({ updateAuthUserResponse })
      // throw new Error(updateAuthUserResponse?.error ?? 'Something went creating auth user')
    }
  }
}


export async function deleteUser({ userId }) {
  const deleteFileMakerUser = firebaseFunctions.httpsCallable('deleteFileMakerUser')

  await deleteFileMakerUser({ userId })

  await firebaseDb.collection('users').doc(userId).delete()
}
