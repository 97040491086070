import * as React from 'react'
import PropTypes from 'prop-types'
import { useAuth } from 'src/components/AuthProvider'
import { UserModel } from 'src/data-model'
import useModelList from 'src/components/useModelList'
import { UsersContext } from './UsersContext'
import { firebaseDb } from 'src/services/firebase'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { UserLogModel } from 'src/data-model'

// TODO: the accountName and BBID got reversed :()
async function logUser(user, accountName, BBID, resourceName, resourceId) {
  const userLog = UserLogModel.create({
    accountId: user.accountId,
    accountName,
    BBID,
    resourceName,
    resourceId,
    userId: user.id,
    fmid: user.fmid,
    accessLevel: user.accessLevel,
    name: user.name,
    email: user.email,
  })

  await firebaseDb.collection(UserLogModel.collection).add(
    UserLogModel.toDb(userLog)
  )
}


export function UsersProvider({ children }) {
  const loader = useLoader()
  const { user: systemUser } = useAuth()

  const { results: users = [], status } = useModelList({
    model: UserModel,
    filter: query => query.where('authUserId', '==', systemUser?.id),
    filterKey: systemUser?.id,
    skip: !systemUser?.id,
  })

  const activeUsers = React.useMemo(
    () => users.filter(user => user.isActive),
    [users]
  )
  const isAccountAdmin = activeUsers.some(user => user.isAccountAdmin)
  const usersCount = users.length
  const isSimpleUser = !systemUser.isAdmin && !isAccountAdmin && usersCount === 1

  const handleLaunchApp = React.useCallback(
    async (user) => {
      loader.open('Launching app...')
      try {
        // Extract all the data needed to build the url request.
        // TODO: should probably denormalize this a bit so we can have super-
        // quick logins.
        const accountDoc = await firebaseDb
          .collection('accounts')
          .doc(user.accountId)
          .get()

        const {
          accountType,
          interfaceFileName,
          resourceId,
          name: accountName,
          BBID,
          stripeSubscriptionStatus,
        } = accountDoc.data()

        if (stripeSubscriptionStatus === 'unpaid' && accountType === 'LIVE') {
          alert('This account is currently suspendend. Please contact your account administrator.')
          return
        }

        const resourceDoc = await firebaseDb
          .collection('resources')
          .doc(resourceId)
          .get()

        const { domain, name: resourceName } = resourceDoc.data()

        const clientHost = process.env.REACT_APP_CLIENT_HOST

        const returnPath = isSimpleUser
           ? '/logout'
           : ''

        const form = document.createElement('form')
        document.body.appendChild(form)
        form.method = 'post'
        form.action = `https://${domain}/fmi/webd/${interfaceFileName}?homeurl=${clientHost}${returnPath}`

        // Create username
        const userIdInput = document.createElement('input')
        userIdInput.type = 'hidden'
        userIdInput.name = 'user'
        userIdInput.value = user.fmid
        form.appendChild(userIdInput)

        // Create password
        const passwordInput = document.createElement('input')
        passwordInput.type = 'hidden'
        passwordInput.name = 'pwd'
        passwordInput.value = user.fmPassword
        form.appendChild(passwordInput)

        try {
          await logUser(user, BBID, accountName, resourceName, resourceDoc.id)
        } catch (err) {
          // TODO: send this log out. But we don't want to accidentally hold
          // up logging in.
          console.log({ err })
        }

        form.submit()
      } catch (error) {
        loader.close()
        console.log({ error })
      }
    },
    [loader, isSimpleUser]
  )

  return (
    <UsersContext.Provider
      value={{
        status,
        users: activeUsers,
        isAccountAdmin,
        usersCount,
        handleLaunchApp,
        isSimpleUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}

UsersProvider.propTypes = {
  /** The wrapped content of the provider. */
  children: PropTypes.node,
}
