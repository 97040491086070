import { firebaseFunctions, firebaseDb } from 'src/services/firebase'
import { ResourceModel } from 'src/data-model'


export function createGlobalId({ accountId, fmid }) {
  return `${accountId}__${fmid}`
}


export async function getFileMakerAccountData({ account }) {
  if (!account.resourceId) {
    return {}
  }

  const ref = firebaseDb.collection(ResourceModel.collection).doc(account.resourceId)
  const doc = await ref.get()

  const { domain = null } = doc.data()
  const { databaseFileName = null } = account

  const exportFileMakerAccount = firebaseFunctions.httpsCallable('exportFileMakerAccount')

  const result = await exportFileMakerAccount({
    domain: domain,
    database: databaseFileName,
    accountId: account.id,
  })

  return result
}
