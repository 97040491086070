import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@material-ui/core/styles'
import {
  CardElement,
} from '@stripe/react-stripe-js'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { FormHelperText } from '@material-ui/core'



export const StripeCardField = ({ onChange }) => {
  const theme = useTheme()

  const [error, setError] = React.useState(null)

  // const [clientSecret, setClientSecret] = React.useState(null)

  // console.log({ metadata })

  // const requestPaymentIntentMutation = usePostMutation(API__REQUEST_PAYMENT_INTENT, {
  //   onError: error => {
  //     console.log('Error')
  //     console.log({ error })
  //     setError(error)
  //   },
  //   onSuccess: result => {
  //     setClientSecret(result.data.clientSecret)
  //   },
  // })

  // React.useEffect(
  //   () => {
  //     requestPaymentIntentMutation.mutate({ amount,  metadata })
  //   },
  //   [amount, stripeAccountId]
  // )

  const options = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: theme.palette.text.hint,
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontFamily: theme.typography.fontFamily,
        fontSize: '14px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#fce883' },
        '::placeholder': { color: theme.palette.text.hint },
      },
      invalid: {
        iconColor: theme.palette.error.main,
        color: theme.palette.error.main,
      },
    }
  }

  const fieldStyle = {
    padding: 6,
    backgroundColor: theme.palette.grey[100],
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 999,
    borderColor: error ? theme.palette.error.main : theme.palette.grey[300],
  }

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <InputLabel>Card Details</InputLabel>
      <div style={fieldStyle}>
        <CardElement
          options={options}
          onChange={event => {
            onChange(event)
            setError(event?.error ?? null)
          }}
        />
      </div>

      {Boolean(error) && <FormHelperText error>{error.message}</FormHelperText>}

    </FormControl>
  )
}


StripeCardField.propTypes = {
  onChange: PropTypes.func,
}