import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import Stack from '@material-ui/core/Stack'
import Switch from 'genjo-ui/core/Switch'
import { firebaseFunctions } from 'src/services/firebase'
import useModelList from 'src/components/useModelList'
import { SubscriptionPriceModel } from 'src/data-model'

import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'

import { createCurrencyFormatter } from 'src/utils/currency'
import { firebaseDb } from 'src/services/firebase'


export function AdminStripe() {
  const loader = useLoader()
  const snackbar = useSnackbar()

  const { results: prices = [] } = useModelList({
    model: SubscriptionPriceModel,
    filterKey: '',
  })

  async function handleSyncPrices() {
    try {
      loader.open('Syncing prices from Stripe...')

      const syncSubscriptionPrices = firebaseFunctions.httpsCallable('syncSubscriptionPrices')
      await syncSubscriptionPrices()

      snackbar.addMessage({
        text: 'Prices synced.',
        type: 'success',
      })
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem syncing prices.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  async function handleSetDefault(event) {
    const { name: priceId } = event.target

    const priceToUpdate = prices.find(price => price.id === priceId)

    if (!priceToUpdate) {
      console.log(`Could not find price: ${priceId}`)
      return
    }

    const partitionPrices = prices.filter(price => price.accountType === priceToUpdate.accountType
      && price.accessLevel === priceToUpdate.accessLevel
      && price.billingInterval === priceToUpdate.billingInterval
    )

    try {
      // Iterate over the current prices in the same partition making sure the
      // selected price is set to the default and the others turned off.
      const updatePromises = partitionPrices.map(price => {
        const isDefault = price.id === priceId

        return firebaseDb
          .collection(SubscriptionPriceModel.collection)
          .doc(price.id)
          .update({ isDefault })
      })

      await Promise.all(updatePromises)

      snackbar.addMessage({
        text: 'Prices updated.',
        type: 'success',
      })
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({
        text: error?.message ?? 'There was an error updating prices.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  const currencyFormatter = createCurrencyFormatter()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card style={{ overflowX: 'auto' }}>
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Subscription Prices</Typography>
              <Button
                variant="contained"
                onClick={handleSyncPrices}
              >
                Sync Prices
              </Button>

            </Stack>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Variant</TableCell>
                  <TableCell>Access Level</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Billing Interval</TableCell>
                  <TableCell>Default</TableCell>
                  <TableCell align="right">Amout</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {prices.map(price => (
                  <TableRow key={price.id}>
                    <TableCell>{price.id}</TableCell>
                    <TableCell>{price.variant}</TableCell>
                    <TableCell>{price.accessLevel}</TableCell>
                    <TableCell>{price.accountType}</TableCell>
                    <TableCell>{price.billingInterval}</TableCell>
                    <TableCell>
                      <Switch
                        checked={price.isDefault}
                        name={price.id}
                        onChange={handleSetDefault}
                      />
                    </TableCell>
                    <TableCell align="right">{currencyFormatter.format(price.amount / 100)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
