import {
  booleanFieldFactory,
  modelFactory,
  stringFieldFactory,
  dateTimeFieldFactory,
} from 'pebble-orm'

import { createTimestampFields } from './mixins'

import { AccessLevelChoices } from './choices'


// ID = accountId__fmid
export const UserModel = modelFactory({
  name: 'UserModel',
  collection: 'users',
  fields: [
    ...createTimestampFields(),

    stringFieldFactory({
      name: 'accountId',
    }),

    stringFieldFactory({
      name: 'fmid',
    }),

    stringFieldFactory({
      name: 'authUserId',
    }),

    stringFieldFactory({
      name: 'fmPassword',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'email',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'name',
      defaultValue: '',
    }),

    booleanFieldFactory({
      name: 'isAccountAdmin',
      defaultValue: false,
    }),

    booleanFieldFactory({
      name: 'isActive',
      defaultValue: true,
    }),

    stringFieldFactory({
      name: 'accessLevel',
      choices: AccessLevelChoices.getChoices(),
      defaultValue: AccessLevelChoices.LIMITED,
    }),

    stringFieldFactory({
      name: 'accessGroupId',
    }),

    stringFieldFactory({
      name: 'profitCenterId',
    }),

    dateTimeFieldFactory({
      name: 'termsAcceptedOn',
      defaultValue: null,
    }),
  ],
})
