import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// See: https://material-ui.com/guides/composition/#link
// From: https://github.com/mui-org/material-ui/blob/master/examples/nextjs/src/Link.js
export const LinkBehavior = React.forwardRef((props, ref) => {
  const { to, ...rest } = props

  return (
    <Link ref={ref} to={to} {...rest} />
  )
})

LinkBehavior.displayName = 'LinkBehavior'

LinkBehavior.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
