import * as React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LoadingButton from '@material-ui/lab/LoadingButton'

import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'
import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'
import AutoFormattedTextField from 'genjo-ui/core/AutoFormattedTextField'
import AddressField from 'genjo-ui/core/AddressField'

import { AccountModel } from 'src/data-model'
import useInstanceValues from 'src/components/useInstanceValues'
import { firebaseDb } from 'src/services/firebase'


const _ALLOWED_FIELDS = [
  'name', 'email', 'phoneNumber', 'address', 'ownerId', 'city', 'country',
  'state', 'geopoint', 'postalCode'
]


export function EditDetails({ account, onClose }) {
  const loader = useLoader()
  const snackbar = useSnackbar()

  const [isProcessingGeoquery, setIsProcessingGeoquery] = React.useState(false)

  const { values, setFieldValue, setValues, isDirty } = useInstanceValues({
    model: AccountModel,
    instance: account,
    fields: _ALLOWED_FIELDS,
  })

  async function handleSubmit(event) {
    event.preventDefault()

    if (isProcessingGeoquery) {
      return
    }

    try {
      loader.open('Saving account details...')

      await firebaseDb.collection(AccountModel.collection).doc(account.id).update(
        AccountModel.toDb(values)
      )

      snackbar.success('Account details updated.')

      onClose()
    } catch (error) {
      console.log({ error })
      snackbar.error(error?.message ?? 'Something went wrong.')
    } finally {
      loader.close()
    }

  }

  function handleAddressChange(event) {
    setFieldValue('address', event.target.value)
  }

  function handleGeocoderResultsChange(results) {
    const { components = {}, geopoint } = results
    const { postalCode, country, state, city } = components

    setValues({
      ...values,
      geopoint,
      postalCode,
      country,
      state,
      city,
    })
  }

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <form
        onSubmit={handleSubmit}
      >
        <ClosableDialogTitle onClose={onClose}>Edit Account Details</ClosableDialogTitle>

        <DialogContent>
          {Boolean(values) ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Company Name"
                  required
                  value={values.name}
                  onChange={event => setFieldValue('name', event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Email Address"
                  required
                  type="email"
                  value={values.email}
                  onChange={event => setFieldValue('email', event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <AutoFormattedTextField
                  label="Phone Number"
                  mask="(###) ###-####"
                  value={values.phoneNumber}
                  autoFormat
                  onChange={(event, newValue) => setFieldValue('phoneNumber', newValue)}
                />
              </Grid>

              <Grid item xs={12}>
                <AddressField
                  label="Address"
                  value={values.address}
                  onBlur={() => {}}
                  onInputChange={handleAddressChange}
                  onGeocoderSuccess={handleGeocoderResultsChange}
                  onGeocode={() => setIsProcessingGeoquery(true)}
                  onGeocoderSettled={() => setIsProcessingGeoquery(false)}
                />
              </Grid>
            </Grid>
          ) : 'Loading...'}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
          >
            Cancel
          </Button>

          <LoadingButton
            variant="contained"
            disabled={!isDirty || isProcessingGeoquery}
            loading={isProcessingGeoquery}
            type="submit"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

EditDetails.propTypes = {
  account: PropTypes.object,
  onClose: PropTypes.func,
}
