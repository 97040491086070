import * as React from 'react'

import LinkBehavior from 'src/components/LinkBehavior'
import { useLocation, matchPath } from 'react-router-dom'

import {
  Group,
  Text,
  UnstyledButton,
  ThemeIcon,
} from '@mantine/core'


export const MainLink = ({ icon, color = 'gray', label, to, path = '/', end = false }) => {
  const { pathname } = useLocation()
  const isSelected = matchPath({ path, end }, pathname)

  return (
    <UnstyledButton
      component={LinkBehavior}
      to={to}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        backgroundColor: !isSelected
          ? 'transparent'
          : theme.colorScheme === 'dark'
          ? theme.colors[color][6]
          : theme.colors[color][0],

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      })}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  )
}