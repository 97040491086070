import { BrowserRouter } from 'react-router-dom'

// This provides backwards compatibility with the `makeStyles` API in MUI.
// Since MUI v5 uses @emotion for css, this makes sure that hook-based styles
// are inserted in the correct order when redering the final css.
// See: https://next.material-ui.com/guides/interoperability/
// Material-UI needs to specify inject order to allow customization
// See: https://next.material-ui.com/guides/migration-v4/#styled-engine
// This fixes an issue related to a warning when using "first-child"
// selector. More about this here:
// https://github.com/mui-org/material-ui/issues/24894
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import RootProvider from 'src/components/RootProvider'
import Pages from './pages'

const cache = createCache({
  key: 'css',
  prepend: true,
})

cache.compat = true


function App() {
  return (
    <CacheProvider value={cache}>
      <BrowserRouter>
        <RootProvider>
          <Pages />
        </RootProvider>
      </BrowserRouter>
    </CacheProvider>
  )
}

export default App
