import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Cropper from 'react-cropper'

import AutorenewIcon from '@material-ui/icons/AutorenewRounded'
import ZoomOutIcon from '@material-ui/icons/ZoomOutRounded'
import ZoomInIcon from '@material-ui/icons/ZoomInRounded'
import RotateLeftIcon from '@material-ui/icons/RotateLeftRounded'
import RotateRightIcon from '@material-ui/icons/RotateRightRounded'


import '../../../node_modules/cropperjs/dist/cropper.css'



const useStyles = makeStyles({
  iconButton: {
    height: 36,
    width: 36,
  },
  icon: {
    fontSize: 32,
  },
})

export const ImageCropper = ({
  src,
  minCropBoxWidth = 0,
  minCropBoxHeight = 0,
  initialAspectRatio = NaN,
  aspectRatio = NaN,
  responsive = true,
  guides = true,
  viewMode = 1,
}) => {
  const classes = useStyles()

  const reset = () => window.cropper && window.cropper.reset()
  const zoomIn = () => window.cropper && window.cropper.zoom(0.1)
  const zoomOut = () => window.cropper && window.cropper.zoom(-0.1)
  const rotateLeft = () => window.cropper && window.cropper.rotate(-30)
  const rotateRight = () => window.cropper && window.cropper.rotate(30)

  React.useEffect(() => {
    return () => {
      window.cropper = null
    }
  })

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item style={{ width: '100%', flex: 1 }}>
        <Cropper
          style={{ height: '100%' }}
          src={src}
          // Cropper.js options
          guides={guides}
          crop={() => {}}
          onInitialized={cropper => (window.cropper = cropper)}
          aspectRatio={aspectRatio}
          initialAspectRatio={initialAspectRatio}
          viewMode={viewMode}
          minCropBoxWidth={minCropBoxWidth}
          minCropBoxHeight={minCropBoxHeight}
          zoomOnTouch={false}
          zoomOnWheel={false}
          dragMode="move"
          responsive={responsive}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={1} alignItems="center" justify="center">
          <Grid item>
            <Tooltip title="Reset to Original">
              <IconButton className={classes.iconButton} onClick={reset}>
                <AutorenewIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title="Zoom Out">
              <IconButton className={classes.iconButton} onClick={zoomOut}>
                <ZoomOutIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title="Zoom In">
              <IconButton className={classes.iconButton} onClick={zoomIn}>
                <ZoomInIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title="Rotate 90° Left">
              <IconButton className={classes.iconButton} onClick={rotateLeft}>
                <RotateLeftIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title="Rotate 90° Right">
              <IconButton className={classes.iconButton} onClick={rotateRight}>
                <RotateRightIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ImageCropper.propTypes = {
  aspectRatio: PropTypes.number,
  guides: PropTypes.bool,
  initialAspectRatio: PropTypes.number,
  minCropBoxHeight: PropTypes.number,
  minCropBoxWidth: PropTypes.number,
  responsive: PropTypes.bool,
  /** The source of the image */
  src: PropTypes.string,
  viewMode: PropTypes.oneOf([1, 2, 3]),
}
