import * as React from 'react'
import { useAuth } from 'src/components/AuthProvider'
import UserMenu from 'src/components/UserMenu'

import { useMediaQuery } from '@mantine/hooks'

import { useLocation } from 'react-router-dom'

import {
  Image,
  Navbar as MantineNavbar,
  Group,
  Stack,
  Text,
  Drawer,
  UnstyledButton,
  ThemeIcon,
  ScrollArea,
  Divider,
} from '@mantine/core'

import { X, Menu2, Logout } from 'tabler-icons-react'


export function Navbar({ links, mobileLinks }) {
  const matches = useMediaQuery('(min-width: 800px)')
  const { logout } = useAuth()
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false)

  const location = useLocation()

  React.useLayoutEffect(
    () => setDrawerIsOpen(false),
    [location]
  )

  const brand = (
    <Image
      src={`${process.env.PUBLIC_URL}/img/logo/logo_horizontal.svg`}
      alt="Base Builders Logo"
    />
  )

  if (!matches) {
    return (
      <>
        <MantineNavbar
          position={{ bottom: 0, left: 0, right: 0 }}
          height={64}
          sx={theme => ({
            borderTop: `1px solid ${theme.colors.gray[3]}`,
          })}
        >
          <div
            style={{
              position: 'absolute',
              inset: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 8,
            }}
          >
            {mobileLinks}

            <UnstyledButton
              onClick={() => setDrawerIsOpen(true)}
              sx={(theme) => ({
                display: 'block',
                flex: 1,
                padding: 0,
                borderRadius: theme.radius.sm,
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
              })}
            >
              <Stack position="center" align="center" spacing={0}>
                <ThemeIcon variant="outline" sx={{ border: 'none' }}>
                  <Menu2 size={16} />
                </ThemeIcon>

                <Text size="xs">Menu</Text>
              </Stack>
            </UnstyledButton>
          </div>
        </MantineNavbar>

        <Drawer
          opened={drawerIsOpen}
          onClose={() => setDrawerIsOpen(false)}
          padding="md"
          position="bottom"
          size="85%"
        >
          <Stack justify={'space-between'} style={{ height: 'calc(100% - 48px)' }}>
            <ScrollArea style={{ height: '100%' }} type="auto" offsetScrollbars scrollbarSize={20}>
              {brand}
              {links}

              <UnstyledButton
                onClick={logout}
                sx={(theme) => ({
                  display: 'block',
                  width: '100%',
                  padding: theme.spacing.xs,
                  borderRadius: theme.radius.sm,
                  color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
                })}
              >
                <Group>
                  <ThemeIcon variant="light">
                    <Logout size={16} />
                  </ThemeIcon>

                  <Text size="sm">Logout</Text>
                </Group>
              </UnstyledButton>
            </ScrollArea>

            <Divider />

            <UnstyledButton
              onClick={() => setDrawerIsOpen(false)}
              sx={(theme) => ({
                display: 'block',
                width: '100%',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
              })}
            >
              <Group>
                <ThemeIcon variant="light">
                  <X size={16} />
                </ThemeIcon>

                <Text size="sm">Close</Text>
              </Group>
            </UnstyledButton>
          </Stack>
        </Drawer>
      </>
    )
  }

  return (
    <MantineNavbar p="xs" width={{ base: 300 }}>
      <MantineNavbar.Section mt="xs">
        {brand}
      </MantineNavbar.Section>
      <MantineNavbar.Section grow mt="md">
        {links}
      </MantineNavbar.Section>
      <MantineNavbar.Section>
        <UserMenu />
      </MantineNavbar.Section>
    </MantineNavbar>
  )
}

Navbar.propTypes = {

}
