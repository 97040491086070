import * as React from 'react'
import PropTypes from 'prop-types'

import { useParams } from 'react-router-dom'
import useModelInstance from 'src/components/useModelInstance'
import useModelList from 'src/components/useModelList'
import {
  AccountModel,
  UserModel,
  AccessGroupModel,
  ProfitCenterModel,
} from 'src/data-model'

import { AccountContext } from './AccountContext'
import { filterUsers } from './utils'


// Used to sort access groups
const ACCESS_LEVEL_VALUE = {
  LIMITED: 2,
  PRODUCTION: 1,
  FINANCIAL: 0,
}


function sortAccessGroups(a, b) {
  const aLevel = ACCESS_LEVEL_VALUE[a.accessLevel]
  const bLevel = ACCESS_LEVEL_VALUE[b.accessLevel]
  const deltaLevel = aLevel - bLevel

  if (deltaLevel) {
    return deltaLevel
  }

  // If the access level is the same, sort by name.
  if (a.name < b.name) {
    return -1
  }

  if (b.name < a.name) {
    return 1
  }

  return 0
}



export function AccountProvider({ children }) {
  const { accountId } = useParams()

  const { instance: account, status: accountStatus } = useModelInstance({
    model: AccountModel,
    id: accountId,
  })

  const { results: users, status: usersStatus } = useModelList({
    limit: 1000,
    model: UserModel,
    filter: query => query.where('accountId', '==', account?.id),
    filterKey: account?.id,
    skip: !account,
  })

  const userLicenseCounts = React.useMemo(
    () => users.reduce((result, user) => {
      if (user.isActive) {
        result[user.accessLevel] += 1
      }

      return result
    }, { FINANCIAL: 0, PRODUCTION: 0, LIMITED: 0 }),
    [users],
  )

  const licensesAvailable = React.useMemo(
    () => ({
      FINANCIAL: account?.financialUsersLicenseCount - userLicenseCounts?.FINANCIAL,
      PRODUCTION: account?.productionUsersLicenseCount - userLicenseCounts?.PRODUCTION,
      LIMITED: account?.limitedUsersLicenseCount - userLicenseCounts?.LIMITED,
    }),
    [userLicenseCounts, account]
  )

  const { results: accessGroups = [], status: accessGroupsStatus } = useModelList({
    model: AccessGroupModel,
    filter: query => query.where('accountId', '==', account?.id),
    filterKey: account?.id,
    skip: !account,
  })

  // Sort access groups by name and access level
  accessGroups.sort(sortAccessGroups)

  const { results: profitCenters, status: profitCentersStatus } = useModelList({
    model: ProfitCenterModel,
    filter: query => query.where('accountId', '==', account?.id),
    filterKey: account?.id,
    skip: !account,
  })

  const [filters, setFilters] = React.useState({
    search: '',
    accessLevel: '',
    activeStatus: 'active',
    accessGroup: null,
    profitCenter: null,
  })

  const filteredUsers = React.useMemo(
    () => users.filter(user => filterUsers(user, filters)),
    [users, filters]
  )

  function setFilterValue(filter, value) {
    if (filters.hasOwnProperty(filter)) {
      setFilters(f => ({ ...f, [filter]: value }))
    }
  }

  function setFilterAccessGroup(accessGroup = null) {
    setFilters(s => ({
      ...s,
      accessLevel: accessGroup?.accessLevel ?? '',
      accessGroup,
    }))
  }

  function clearFilters() {
    setFilters({
      search: '',
      accessLevel: '',
      activeStatus: '',
      accessGroup: null,
      profitCenter: null,
    })
  }

  const status = React.useMemo(
    () => {
      let isLoading = false
      let hasError = false
      let isSuccess = true
      for (let s of [accountStatus, usersStatus, accessGroupsStatus, profitCentersStatus]) {
        if (s === 'loading') {
          isLoading = true
          isSuccess = false
        }

        if (s === 'error' || s === 'notFound') {
          hasError = true
          isSuccess = false
        }
      }

      return isLoading
        ? 'loading'
        : hasError
        ? 'error'
        : isSuccess
        ? 'success'
        : 'idle'
    },
    [accountStatus, usersStatus, accessGroupsStatus, profitCentersStatus]
  )

  if (!account) {
    return 'Loading...'
  }

  return (
    <AccountContext.Provider
      value={{
        status,
        account,
        accountStatus,
        users,
        filteredUsers,
        userLicenseCounts,
        licensesAvailable,
        usersStatus,
        accessGroups,
        accessGroupsStatus,
        profitCenters,
        profitCentersStatus,
        filters,
        setFilterValue,
        setFilterAccessGroup,
        clearFilters,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

AccountProvider.propTypes = {
  /** The id of the account to be provided. */
  accountId: PropTypes.string,
  /** The content of the provider. */
  children: PropTypes.node,
}
