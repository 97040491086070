import * as React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { useAccount } from 'src/components/AccountProvider'
import { AccessLevelChoices } from 'src/data-model'



export function AccessLevelField({ value, ...textFieldProps }) {
  const { licensesAvailable } = useAccount()

  function getAvailableLicensesString(accessLevel) {
    const availableCount = licensesAvailable[accessLevel]
    const pluralize = availableCount === 1 ? '' : 's'

    return availableCount <= 0
      ? 'No available licenses'
      : `${availableCount} license${pluralize} available`
  }

  return (
    <TextField
      {...textFieldProps}
      value={value || ''}
      select
      SelectProps={{
        displayEmpty: true,
      }}
    >
      <MenuItem value="" disabled>Please select an access level...</MenuItem>

      {AccessLevelChoices.getChoices().map(choice => (
        <MenuItem
          key={choice}
          value={choice}
          required
          disabled={licensesAvailable[choice] <= 0}
        >
          {`${
            AccessLevelChoices.getLabel(choice)
          } User (${
            getAvailableLicensesString(choice)
          })`}
        </MenuItem>
      ))}
    </TextField>
  )
}

AccessLevelField.propTypes = {
  value: PropTypes.oneOf(AccessLevelChoices.getChoices()),
}
