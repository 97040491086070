import * as React from 'react'

import LinkBehavior from 'src/components/LinkBehavior'
import { useLocation, matchPath } from 'react-router-dom'

import {
  Stack,
  Text,
  UnstyledButton,
  ThemeIcon,
} from '@mantine/core'


export const MobileLink = ({ icon, color = 'gray', label, to, path = '/', end = false }) => {
  const { pathname } = useLocation()
  const isSelected = matchPath({ path, end }, pathname)

  return (
    <UnstyledButton
      component={LinkBehavior}
      to={to}
      sx={(theme) => ({
        display: 'block',
        flex: 1,
        padding: 0,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        backgroundColor: !isSelected
          ? 'transparent'
          : theme.colorScheme === 'dark'
          ? theme.colors[color][6]
          : theme.colors[color][0],
      })}
    >
      <Stack position="center" align="center" spacing={0}>
        <ThemeIcon color={color} variant="outline" sx={{ border: 'none' }}>
          {icon}
        </ThemeIcon>

        <Text size="xs">{label}</Text>
      </Stack>
    </UnstyledButton>
  )
}