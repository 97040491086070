import { convertAccessLevelToChoice } from 'src/services/access-groups'


export function compareAccessGroupToFileMaker(accessGroup, fileMakerRecord) {
  if (!accessGroup) {
    return 'create_firebase'
  }

  if (!fileMakerRecord) {
    return 'create_file_maker'
  }

  const convertFmLevel = convertAccessLevelToChoice(fileMakerRecord.AG_AccessLevel)

  const namesAreDifferent = accessGroup.name !== fileMakerRecord.AG_Name
  const levelsAreDifferent = accessGroup.accessLevel !== convertFmLevel
  const shouldUpdate = namesAreDifferent || levelsAreDifferent

  if (shouldUpdate) {
    return 'update'
  }

  return 'all_good'
}
