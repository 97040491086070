import { firebaseDb } from 'src/services/firebase'
import { ResourceModel, AccountModel } from 'src/data-model'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'


/**
 * Create a resource
 */
 export function useCreateResource() {
  const loader = useLoader()
  const snackbar = useSnackbar()

  async function createResource ({ values, onSuccess, onError }) {
    loader.open('Creating resource...')

    const data = ResourceModel.toDb(values, true)

    try {
      const doc = await firebaseDb.collection(ResourceModel.collection).add(data)

      snackbar.addMessage({
        text: 'Resource created.',
        type: 'success',
      })

      onSuccess?.(doc.id)
    } catch (error) {
      console.log({ error })

      snackbar.addMessage({
        text: error?.message ?? 'There was a resource creating resource.',
        type: 'error',
      })

      onError?.(error)
    } finally {
      loader.close()
    }
  }

  return createResource
}


/**
 * Update a resource
 */
 export function useUpdateResource() {
  const loader = useLoader()
  const snackbar = useSnackbar()

  async function updateResource ({ resourceId, values, onSuccess, onError }) {
    loader.open('Saving resource...')

    const data = ResourceModel.toDb(values)

    try {
      await firebaseDb.collection(ResourceModel.collection).doc(resourceId).update(data)

      snackbar.addMessage({
        text: 'Resource saved.',
        type: 'success',
      })

      onSuccess?.()
    } catch (error) {
      console.log(error)

      snackbar.addMessage({
        text: error?.message ?? 'There was a resource saving resource.',
        type: 'error',
      })

      onError?.(error)
    } finally {
      loader.close()
    }
  }

  return updateResource
}


/**
 * Delete a resource
 */
 export function useDeleteResource() {
  const loader = useLoader()
  const snackbar = useSnackbar()

  async function deleteResource ({ resourceId, onSuccess, onError, onSettled }) {
    loader.open('Deleting resource...')

    try {
      // Check that no accounts are using the requested resource.
      const accountsSnapshot = await firebaseDb.collection(AccountModel.collection).where('resourceId', '==', resourceId).get()

      if (accountsSnapshot.docs.length) {
        throw new Error('This resource is currently being used.')
      }

      await firebaseDb.collection(ResourceModel.collection).doc(resourceId).delete()

      snackbar.addMessage({
        text: 'Resource deleted.',
        type: 'success',
      })

      onSuccess?.()
    } catch (error) {
      console.log(error)

      snackbar.addMessage({
        text: error?.message ?? 'There was a resource deleting resource.',
        type: 'error',
      })

      onError?.(error)
    } finally {
      loader.close()
      onSettled?.()
    }
  }

  return deleteResource
}