export function calculateBillingValues({ values, userPrices, onboardingPrice }) {
  const billingIntervalPrices = userPrices[values.billingInterval]

  const yearlyMultiplier = values.billingInterval === 'YEARLY'
    ? 1
    : 1

  const financialPrice = billingIntervalPrices.FINANCIAL
  const productionPrice = billingIntervalPrices.PRODUCTION
  const limitedPrice = billingIntervalPrices.LIMITED

  const financialSubtotal = financialPrice
    * yearlyMultiplier
    * values.financialUsersLicenseCount

  const productionSubtotal = productionPrice
    * yearlyMultiplier
    * values.productionUsersLicenseCount

  const limitedSubtotal = limitedPrice
    * yearlyMultiplier
    * values.limitedUsersLicenseCount

  const subscriptionSubtotal = financialSubtotal
    + productionSubtotal
    + limitedSubtotal

  const total = subscriptionSubtotal + onboardingPrice

  return {
    financialPrice,
    financialSubtotal,

    productionPrice,
    productionSubtotal,

    limitedPrice,
    limitedSubtotal,

    subscriptionSubtotal,
    total,
  }
}