import { DateTime } from 'luxon'

import {
  modelFactory,
  stringFieldFactory,
  dateTimeFieldFactory,
} from 'pebble-orm'


export const UserLogModel = modelFactory({
  name: 'UserLogModel',
  collection: 'userLogs',
  fields: [
    stringFieldFactory({
      name: 'accountId',
      required: true,
    }),

    stringFieldFactory({
      name: 'accountName',
    }),

    stringFieldFactory({
      name: 'resourceId',
    }),

    stringFieldFactory({
      name: 'resourceName',
    }),

    stringFieldFactory({
      name: 'BBID',
    }),

    stringFieldFactory({
      name: 'userId',
      required: true,
    }),

    stringFieldFactory({
      name: 'fmid',
    }),

    stringFieldFactory({
      name: 'accessLevel',
    }),

    stringFieldFactory({
      name: 'name',
    }),

    stringFieldFactory({
      name: 'email',
    }),

    dateTimeFieldFactory({
      name: 'timestamp',
      defaultValue: () => DateTime.local(),
    }),
  ],
})
