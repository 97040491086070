import * as React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'

import BackButton from 'src/components/BackButton'

// import SortableTableHeadCell from 'genjo-ui/core/SortableTableHeadCell'
import { firebaseFunctions } from 'src/services/firebase'


export function SystemUser() {
  const { systemUserId } = useParams()

  const [status, setStatus] = React.useState('idle')
  const [user, setUser] = React.useState(null)

  React.useEffect(
    () => {
      setStatus('loading')
    },
    [systemUserId]
  )

  React.useEffect(
    () => {
      if (status !== 'loading') {
        return
      }

      const getSystemUser = firebaseFunctions.httpsCallable('getSystemUser')

      let isMounted = true
      ;(async () => {
        try {
          const result = await getSystemUser({ systemUserId })

          if (isMounted) {
            setUser(result.data.user)
            setStatus('success')
          }
        } catch (error) {
          console.log({ error })
        }
      })()

      return () => isMounted = false
    },
    [status, systemUserId]
  )

  return (
    <>
      <BackButton to="/admin/system-users">Back to system users</BackButton>
      <Typography variant="h4">{user?.email || 'Loading...'}</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Actions</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Details</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
