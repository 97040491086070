import * as React from 'react'
import PropTypes from 'prop-types'
import { useUsers } from 'src/components/UsersProvider'
import { useAuth } from 'src/components/AuthProvider'
import LoadingContainer from 'src/components/LoadingContainer'
import { DateTime } from 'luxon'
import Terms from 'src/components/Terms'

const TERMS_DATE = DateTime.fromISO('2022-01-01').toMillis()


export function AppLauncher({ children }) {
  const {
    handleLaunchApp,
    users,
    isSimpleUser,
    status: usersStatus,
  } = useUsers()

  // We need a ref to track when we've initiated the asyc launch function. This
  // prevents the script from getting called multiple times by the use effect
  // before it complete.
  const launcherStarted = React.useRef(false)

  const { user: systemUser } = useAuth()

  const termsAreGood = systemUser.termsAcceptedOn > TERMS_DATE

  React.useEffect(
    () => {
      if (usersStatus === 'success' && isSimpleUser && termsAreGood && !launcherStarted.current) {
        launcherStarted.current = true
        handleLaunchApp(users[0])
      }
    },
    [usersStatus, isSimpleUser, handleLaunchApp, users, termsAreGood, launcherStarted]
  )

  if (usersStatus === 'error') {
    return 'Error loading user records...'
  }

  if (!termsAreGood) {
    return <Terms />
  }

  if (!isSimpleUser && usersStatus === 'success') {
    return (
      children
    )
  }

  return <LoadingContainer />
}

AppLauncher.propTypes = {
  children: PropTypes.node,
}
