import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import LinearProgress from '@material-ui/core/LinearProgress'


const useStyles = makeStyles(theme => ({
  root: {
    height: 12,
    borderRadius: 6,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 6,
    backgroundColor: theme.palette.secondary.main,
  },
  progressContainer: {
    position: 'absolute',
    bottom: 16,
    left: 16,
    right: 16,
    overflow: 'hidden',
  },
}))

export const ImageUploaderProgressBar = ({ progress }) => {
  const classes = useStyles()

  return (
    <div className={classes.progressContainer}>
      <LinearProgress
        variant="determinate"
        value={progress}
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary,
          bar: classes.bar,
        }}
      />
    </div>
  )
}

ImageUploaderProgressBar.propTypes = {
  /** The value of the progress bar as a number between 0 and 100. */
  progress: PropTypes.number,
}
