import * as React from 'react'

import useModelList from 'src/components/useModelList'
import { AccountModel, ResourceModel, SubscriptionPriceModel } from 'src/data-model'

import Table from '@material-ui/core/Table'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'
import TableResultsLoader from 'genjo-ui/core/TableResultsLoader'
import SortableHeader from 'genjo-ui/core/SortableHeader'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'
import SearchField from 'genjo-ui/core/SearchField'
import { useNavigate } from 'react-router-dom'

// import SortableTableHeadCell from 'genjo-ui/core/SortableTableHeadCell'
import { CreateAccountDialog } from './CreateAccountDialog'
import { firebaseFunctions } from 'src/services/firebase'
import { BillingDataButton } from 'src/components/BillingDataButton'
import { Badge, Button } from '@mantine/core'

const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

function formatCurrency(value) {
  return currencyFormatter.format(value)
}

function getOrdinal(n) {
  if (n > 3 && n < 21) return `${n}th`

  switch (n % 10) {
    case 1:  return `${n}st`
    case 2:  return `${n}nd`
    case 3:  return `${n}rd`
    default: return `${n}th`
  }
}

const BADGE_COLORS = {
  active: 'green',
  canceled: 'gray',
  unpaid: 'red',
  past_due: 'red',
}

export function AdminAccounts() {
  const navigate = useNavigate()
  const loader = useLoader()
  const snackbar = useSnackbar()

  const [sortBy, setSortBy] = React.useState({ value: 'BBID', direction: 'asc' })

  const { results: resources = [] } = useModelList({
    model: ResourceModel,
  })

  const { results: prices = [] } = useModelList({
    model: SubscriptionPriceModel,
  })

  const pricesById = React.useMemo(
    () => prices.reduce((result, price) => {
      result[price.id] = price
      return result
    }, {}),
    [prices]
  )

  const [resourceMap, setResourceMap] = React.useState({})
  React.useEffect(
    () => {
      setResourceMap(resources.reduce((result, resource) => {
        result[resource.id] = resource
        return result
      }, {}))
    },
    [resources]
  )

  const { status, results: rawAccounts = [] } = useModelList({
    model: AccountModel,
    limit: 1000,
  })

  const [searchQuery, setSearchQuery] = React.useState('')
  const [filterType, setFilterType] = React.useState('LIVE')
  const [filterResourceId, setFilterResourceId] = React.useState('')
  const [filterSystemType, setFilterSystemType] = React.useState('')
  const [filterStatus, setFilterStatus] = React.useState('active')
  const [filterInterval, setFilterInterval] = React.useState('')
  const [filterMonth, setFilterMonth] = React.useState('')
  const [filterSubType, setFilterSubType] = React.useState('')
  const [filterStart, setFilterStart] = React.useState('')
  const [filterEnd, setFilterEnd] = React.useState('')

  const filterAccounts = React.useCallback(
    account => {
      if (searchQuery) {
        const pattern = new RegExp(searchQuery, 'gi')

        if (!pattern.test(account.name) && !pattern.test(account.BBID)) {
          return false
        }
      }

      if (filterType && account.accountType !== filterType) {
        return false
      }

      if (filterSystemType) {
        if (filterSystemType === 'OLD' && !account.isOldSchool) return false
        if (filterSystemType === 'NEW' && account.isOldSchool) return false
      }

      if (filterResourceId && account.resourceId !== filterResourceId) {
        return false
      }

      if (filterStatus) {
        if (filterStatus === 'canceled') {
          if (account.stripeSubscriptionStatus !== filterStatus) return false
        } else {
          if (account.stripeSubscriptionStatus === 'canceled') return false
        }
      }

      if (filterSubType) {
        if (account.subscriptionType !== filterSubType) return false
      }

      if (filterInterval) {
        if (account.billingInterval !== filterInterval) return false
      }

      if (filterMonth) {
        if (account.billingMonth && account.displayMonth !== filterMonth) return false
      }

      if (filterStart) {
        if (parseInt(account.billingDay, 10) < filterStart) return false
      }

      if (filterEnd) {
        if (parseInt(account.billingDay, 10) > filterEnd) return false
      }

      return true
    },
    [
      filterStart,
      filterEnd,
      filterInterval,
      filterMonth,
      filterResourceId,
      filterSubType,
      filterStatus,
      filterType,
      filterSystemType,
      searchQuery,
    ],
  )

// Append billing data calculations
const [accounts, totals] = React.useMemo(
  () => rawAccounts.reduce((result, account) => {
    let billingData

    try {
      billingData = account.billingData
        ? JSON.parse(account.billingData)
        : {}
    } catch (err) {
      console.log(`Could not load billing data for: ${account.name}`)
    }

    const calculatedInvoice = account.stripeSubscriptionStatus === 'active' ? (
      (pricesById[account.financialUserPriceId]?.amount || 0) * account.financialUsersLicenseCount
      + (pricesById[account.productionUserPriceId]?.amount || 0) * account.productionUsersLicenseCount
      + (pricesById[account.limitedUserPriceId]?.amount || 0) * account.limitedUsersLicenseCount
    ) : 0

    const modifiedAnchor = account.stripeBillingCycleAnchor?.plus({ days: 1 })

    const delta = (billingData?.due || 0) - calculatedInvoice

    const displayMonth = account.billingInterval === 'MONTHLY'
      ? "\u2014"
      : modifiedAnchor?.toFormat('LLLL')

    const billingMonth = account.billingInterval === 'MONTHLY'
      ? null
      : modifiedAnchor?.toFormat('LL')

    const day = modifiedAnchor?.toFormat('d')
    const billingDay = modifiedAnchor?.toFormat('dd')

    const accountWithCalcs = {
      ...account,
      balance: billingData?.balance || 0,
      delta,
      due: billingData?.due || 0,
      displayMonth,
      billingMonth,
      displayDay: getOrdinal(day),
      billingDay,
    }

    if (filterAccounts(accountWithCalcs)) {
      result[0].push(accountWithCalcs)

      result[1].balance += accountWithCalcs.balance
      result[1].delta += accountWithCalcs.delta
      result[1].due += accountWithCalcs.due
      result[1].financial += accountWithCalcs.financialUsersLicenseCount
      result[1].production += accountWithCalcs.productionUsersLicenseCount
      result[1].limited += accountWithCalcs.limitedUsersLicenseCount
    }

    return result
  }, [[], {
      due: 0,
      balance: 0,
      delta: 0,
      users: 0,
      financial: 0,
      production: 0,
      limited: 0,
  }]),
  [
    rawAccounts,
    pricesById,
    filterAccounts,
  ]
)

  accounts.sort((a, b) => {
    const aValue = sortBy.value === 'resource'
      ? resourceMap[a.resourceId]?.name
      : a[sortBy.value]

    const bValue = sortBy.value === 'resource'
      ? resourceMap[b.resourceId]?.name
      : b[sortBy.value]

    if (aValue < bValue) {
      return sortBy.direction === 'asc' ? -1 : 1
    }

    if (aValue > bValue) {
      return sortBy.direction === 'asc' ? 1 : -1
    }

    return 0
  })

  const [counts, setCounts] = React.useState({
    TOTAL: 0,
    FINANCIAL: 0,
    PRODUCTION: 0,
    LIMITED: 0,
  })

  React.useEffect(
    () => {
      const newCounts = {
        TOTAL: 0,
        FINANCIAL: 0,
        PRODUCTION: 0,
        LIMITED: 0,
      }

      accounts.forEach(account => {
        if (account.accountType === 'LIVE') {
          newCounts.TOTAL += account.activeUserCount
          newCounts.FINANCIAL += account.activeFinancialUserCount
          newCounts.PRODUCTION += account.activeProductionUserCount
          newCounts.LIMITED += account.activeLimitedUserCount
        }
      })

      setCounts(newCounts)
    },
    [accounts]
  )

  const [isCreating, setIsCreating] = React.useState(false)

  function openCreateDialog() {
    setIsCreating(true)
  }

  function closeCreateDialog() {
    setIsCreating(false)
  }

  function handleHeaderClick(event, value, direction) {
    setSortBy({ value, direction })
  }

  async function handleUpdateUserCounts() {
    try {
      loader.open('Updating user counts...')
      const updateUserCounts = firebaseFunctions.httpsCallable('updateUserCounts')

      const result = await updateUserCounts()

      if (result?.data?.status !== 'success') {
        throw new Error(result?.data?.error)
      }

      snackbar.success('Counts updated.')
    } catch (err) {
      snackbar.error(err?.message || 'Something went wrong.')
    } finally {
      loader.close()
    }
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 2 }}>
        <Typography variant="h1">Accounts</Typography>
        <div style={{ flex: 1 }} />
        <Button
          onClick={openCreateDialog}
        >
          + Add Account
        </Button>
        <Button
          onClick={handleUpdateUserCounts}
        >
          Update User Counts
        </Button>

        <BillingDataButton />
      </Stack>

      <Typography variant="h2">{`Total Active Users: ${counts.TOTAL}`}</Typography>
      <Stack direction="row" alignItems="center" spacing={2}>
        {['FINANCIAL', 'PRODUCTION', 'LIMITED'].map(accessLevel => (
          <Card key={accessLevel} style={{ flex: 1 }}>
            <CardContent>
              <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
                <Grid item style={{ width: '100%' }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {accessLevel}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h2">{counts[accessLevel]}</Typography>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        <SearchField
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder="Search accounts..."
        />

<TextField
          value={filterSystemType}
          onChange={event => setFilterSystemType(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All System Types</MenuItem>
          <MenuItem value="OLD">Old School</MenuItem>
          <MenuItem value="NEW">Cloud</MenuItem>
        </TextField>

        <TextField
          value={filterResourceId}
          onChange={event => setFilterResourceId(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Resources</MenuItem>
          {resources.map(resource => (
            <MenuItem key={resource.id} value={resource.id}>
              {resource.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          value={filterType}
          onChange={event => setFilterType(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Types</MenuItem>
          <MenuItem value="LIVE">Live</MenuItem>
          <MenuItem value="DEMO">Demo</MenuItem>
        </TextField>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
        

        <TextField
          value={filterStatus}
          onChange={event => setFilterStatus(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Statuses</MenuItem>
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="canceled">Canceled</MenuItem>
        </TextField>

        <TextField
          value={filterSubType}
          onChange={event => setFilterSubType(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Billing Methods</MenuItem>
          <MenuItem value="AUTOMATIC">Stripe</MenuItem>
          <MenuItem value="SEND_INVOICE">Invoice</MenuItem>
        </TextField>

        <TextField
          value={filterInterval}
          onChange={event => setFilterInterval(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Billing Intervals</MenuItem>
          <MenuItem value="MONTHLY">Monthly</MenuItem>
          <MenuItem value="YEARLY">Yearly</MenuItem>
        </TextField>

        <TextField
          value={filterMonth}
          onChange={event => setFilterMonth(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Months</MenuItem>
          <MenuItem value="January">January</MenuItem>
          <MenuItem value="February">February</MenuItem>
          <MenuItem value="March">March</MenuItem>
          <MenuItem value="April">April</MenuItem>
          <MenuItem value="May">May</MenuItem>
          <MenuItem value="June">June</MenuItem>
          <MenuItem value="July">July</MenuItem>
          <MenuItem value="August">August</MenuItem>
          <MenuItem value="September">September</MenuItem>
          <MenuItem value="October">October</MenuItem>
          <MenuItem value="November">November</MenuItem>
          <MenuItem value="December">December</MenuItem>
        </TextField>

        <TextField
          value={filterStart}
          onChange={event => setFilterStart(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">
            Any start day
          </MenuItem>

          {Array.from({ length: 31 }).map((_, index) => (
            <MenuItem value={index + 1}>
              {getOrdinal(index + 1)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          value={filterEnd}
          onChange={event => setFilterEnd(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">
            Any end day
          </MenuItem>

          {Array.from({ length: 31 }).map((_, index) => (
            <MenuItem value={index + 1}>
              {getOrdinal(index + 1)}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <SortableHeader
                {...sortBy}
                sortingKey="BBID"
                onClick={handleHeaderClick}
              >
                BBID
              </SortableHeader>
            </TableCell>
            <TableCell>
              <SortableHeader
                {...sortBy}
                sortingKey="name"
                onClick={handleHeaderClick}
              >
                Name
              </SortableHeader>
            </TableCell>
            <TableCell>
              <SortableHeader
                {...sortBy}
                sortingKey="accountType"
                onClick={handleHeaderClick}
              >
                Account Type
              </SortableHeader>
            </TableCell>

            <TableCell>
              <SortableHeader
                {...sortBy}
                sortingKey="resource"
                onClick={handleHeaderClick}
              >
                Resource
              </SortableHeader>
            </TableCell>

            <TableCell align="right">
              <SortableHeader
                {...sortBy}
                sortingKey="activeUserCount"
                onClick={handleHeaderClick}
                align="right"
              >
                Active Users
              </SortableHeader>
            </TableCell>

            <TableCell align="right">
              <SortableHeader
                {...sortBy}
                sortingKey="activeFinancialUserCount"
                onClick={handleHeaderClick}
                align="right"
              >
                Financial
              </SortableHeader>
            </TableCell>

            <TableCell align="right">
              <SortableHeader
                {...sortBy}
                align="right"
                sortingKey="activeProductionUserCount"
                onClick={handleHeaderClick}
              >
                Production
              </SortableHeader>
            </TableCell>

            <TableCell align="right">
              <SortableHeader
                {...sortBy}
                sortingKey="activeLimitedUserCount"
                onClick={handleHeaderClick}
                align="right"
              >
                Limited
              </SortableHeader>
            </TableCell>

            <TableCell align="center">
              <SortableHeader
                {...sortBy}
                sortingKey="stripeSubscriptionStatus"
                onClick={handleHeaderClick}
                align="center"
              >
                Status
              </SortableHeader>
            </TableCell>

            <TableCell align="right">
              <SortableHeader
                {...sortBy}
                sortingKey="due"
                onClick={handleHeaderClick}
                align="right"
              >
                Next Invoice
              </SortableHeader>
            </TableCell>

            <TableCell align="right">
              <SortableHeader
                {...sortBy}
                sortingKey="delta"
                onClick={handleHeaderClick}
                align="right"
              >
                Delta
              </SortableHeader>
            </TableCell>

            <TableCell align="right">
              <SortableHeader
                {...sortBy}
                sortingKey="balance"
                onClick={handleHeaderClick}
                align="right"
              >
                Credit
              </SortableHeader>
            </TableCell>

            <TableCell>
              <SortableHeader
                {...sortBy}
                sortingKey="billingInterval"
                onClick={handleHeaderClick}
                align="left"
              >
                Interval
              </SortableHeader>
            </TableCell>

            <TableCell>
              <SortableHeader
                {...sortBy}
                sortingKey="billingMonth"
                onClick={handleHeaderClick}
                align="left"
              >
                Month
              </SortableHeader>
            </TableCell>

            <TableCell>
            <SortableHeader
                {...sortBy}
                sortingKey="billingDay"
                onClick={handleHeaderClick}
                align="left"
              >
                Day
              </SortableHeader>
            </TableCell>

            <TableCell>
              <SortableHeader
                {...sortBy}
                sortingKey="subscriptionType"
                onClick={handleHeaderClick}
                align="left"
              >
                Method
              </SortableHeader>
            </TableCell>

          </TableRow>
        </TableHead>

        <TableBody>
          {accounts.map(account => {

            return (
              <TableRow hover key={account.id} onClick={() => navigate(`/accounts/${account.id}`)}>
                <TableCell>{account.BBID}</TableCell>
                <TableCell>{`${account.accountType === 'DEMO' ? '(DEMO) ' : ''}${account.name}`}</TableCell>
                <TableCell>{account.isOldSchool ? 'Old School' : 'Cloud'}</TableCell>
                <TableCell>{resourceMap[account.resourceId]?.name}</TableCell>
                <TableCell align="right">{account.activeUserCount}</TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: account.activeFinancialUserCount - account.financialUsersLicenseCount > 0
                      ? 'error.main'
                      : account.activeFinancialUserCount - account.financialUsersLicenseCount < 0
                      ? 'warning.main'
                      : 'success.main'
                  }}
                >
                  {`${account.activeFinancialUserCount}/${account.financialUsersLicenseCount}`}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: account.activeProductionUserCount - account.productionUsersLicenseCount > 0
                      ? 'error.main'
                      : account.activeProductionUserCount - account.productionUsersLicenseCount < 0
                      ? 'warning.main'
                      : 'success.main'
                  }}
                >
                  {`${account.activeProductionUserCount}/${account.productionUsersLicenseCount}`}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: account.activeLimitedUserCount - account.limitedUsersLicenseCount > 0
                      ? 'error.main'
                      : account.activeLimitedUserCount - account.limitedUsersLicenseCount < 0
                      ? 'warning.main'
                      : 'success.main'
                  }}
                >
                  {`${account.activeLimitedUserCount}/${account.limitedUsersLicenseCount}`}
                </TableCell>

                <TableCell>
                  <Badge
                    color={BADGE_COLORS[account.stripeSubscriptionStatus] || 'gray'}
                  >
                    {account.stripeSubscriptionStatus}
                  </Badge>
                </TableCell>

                <TableCell align="right">
                  {formatCurrency((account.due || 0) / 100)}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    color: account.delta < 0
                      ? 'error.main'
                      : account.delta > 0
                      ? 'success.main'
                      : '#aaa'
                  }}
                >
                  {`${account.delta > 0  ? '+' : ''}${formatCurrency(account.delta / 100)}`}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    color: account?.balance ? 'error.main' : 'unset'
                  }}
                >
                  {formatCurrency((account.balance || 0)/ 100)}
                </TableCell>

                <TableCell>
                  {account.billingInterval === 'MONTHLY'
                    ? 'Monthly'
                    : 'Yearly'
                  }
                </TableCell>

                <TableCell>
                  {account.displayMonth}
                </TableCell>

                <TableCell>
                  {account.displayDay}
                </TableCell>

                <TableCell>
                  {account.subscriptionType === 'AUTOMATIC'
                    ? 'Stripe'
                    : 'Invoice'
                  }
                </TableCell>
              </TableRow>
            )
          })}

          <TableResultsLoader
            isLoading={status === 'loading'}
            count={accounts.length}
            colSpan={16}
          />
        </TableBody>

        {Boolean(accounts.length) && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4}>
                {`Showing ${accounts.length} account${accounts.length === 1 ? '' : 's'}`}
              </TableCell>

              <TableCell align="right">
                {counts.TOTAL}
              </TableCell>

              <TableCell align="right">
                {`${counts.FINANCIAL}/${totals.financial}`}
              </TableCell>

              <TableCell align="right">
                {`${counts.PRODUCTION}/${totals.production}`}
              </TableCell>

              <TableCell align="right">
                {`${counts.LIMITED}/${totals.limited}`}
              </TableCell>

              <TableCell align="right" colSpan={2}>
                {formatCurrency(totals.due / 100)}
              </TableCell>

              <TableCell
                align="right"
                sx={{
                  color: totals.delta < 0
                    ? 'error.main'
                    : totals.delta > 0
                    ? 'success.main'
                    : '#aaa'
                }}
              >
                {formatCurrency(totals.delta / 100)}
              </TableCell>

              <TableCell
                align="right"
                sx={{
                  color: totals.balance < 0
                    ? 'error.main'
                    : totals.balance > 0
                    ? 'success.main'
                    : '#aaa'
                }}
              >
                {formatCurrency(totals.balance / 100)}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>

      <CreateAccountDialog
        isOpen={isCreating}
        onClose={closeCreateDialog}
      />
    </>
  )
}
