import {
  modelFactory,
  numberFieldFactory,
  stringFieldFactory,
  booleanFieldFactory,
} from 'pebble-orm'


import {
  AccountTypeChoices,
  BillingIntervalChoices,
  AccessLevelChoices,
} from './choices'


export const SubscriptionPriceModel = modelFactory({
  name: 'SubscriptionPriceModel',
  collection: 'subscriptionPrices',
  fields: [
    stringFieldFactory({
      name: 'stripePriceId',
      required: true,
    }),

    numberFieldFactory({
      name: 'amount',
      defaultValue: 0,
    }),

    stringFieldFactory({
      name: 'currency',
      defaultValue: 'usd',
    }),

    stringFieldFactory({
      name: 'accessLevel',
      choices: AccessLevelChoices.getChoices(),
      defaultValue: AccessLevelChoices.LIMITED,
    }),

    stringFieldFactory({
      name: 'accountType',
      choices: AccountTypeChoices.getChoices(),
      defaultValue: AccountTypeChoices.DEMO,
    }),

    stringFieldFactory({
      name: 'billingInterval',
      choices: BillingIntervalChoices.getChoices(),
      defaultValue: BillingIntervalChoices.YEARLY,
    }),

    stringFieldFactory({
      name: 'variant',
      required: true,
    }),

    booleanFieldFactory({
      name: 'isDefault',
      defaultValue: false,
    }),
  ],
})
