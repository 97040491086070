import * as React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'genjo-ui/core/ThemeProvider'
import { DateTime } from 'luxon'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from "recharts"

import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'
import Cardinal from 'src/components/Cardinal'


const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function formatCurrency(value) {
  return currencyFormatter.format(value)
}



function createPoint(_, index) {
  const month = index + 1

  return {
    month,
    label: DateTime.local().set({ month }).toFormat('MMMM'),
    firstHalf: 0,
    secondHalf: 0,
    total: 0,
  }
}

function legendFormatter(value, entry, index) {
  return value === 'firstHalf'
    ? 'First Half'
    : value === 'secondHalf'
    ? 'Second Half'
    : 'Total'
}

function tooltipFormatter(value, name, props) {
  return name === 'firstHalf'
    ? [formatCurrency(value), 'First Half']
    : name === 'secondHalf'
    ? [formatCurrency(value), 'Second Half']
    : [formatCurrency(value), 'Total']
}

function initializeData({ accounts, pricesById }) {
  const data = Array.from({ length: 12 }, createPoint)

  const sortedAccounts = accounts.sort((a, b) => {
    if (a.BBID < b.BBID) return -1
    if (a.BBID > b.BBID) return 1
    return 0
  })

  for (const account of sortedAccounts) {
    if (!account.billingStart) {
      console.log(`Account ${account.name}(${account.isOldSchool ? 'OLD': '*'}) has no billing start!`)
      continue
    }


    const financialValue = (pricesById[account.financialUserPriceId] ?? 0) * account.financialUsersLicenseCount
    const productionValue = (pricesById[account.productionUserPriceId] ?? 0) * account.productionUsersLicenseCount
    const limitedValue = (pricesById[account.limitedUserPriceId] ?? 0) * account.limitedUsersLicenseCount
    const totalValue = financialValue + productionValue + limitedValue

    const monthIndex = account.billingStart.month - 1

    const half = account.billingStart.day <= 15 ? 'firstHalf' : 'secondHalf'

    if (account.billingInterval === 'YEARLY') {
      data[monthIndex][half] += totalValue
      data[monthIndex].total += totalValue
    } else {
      for (let i = 0; i < 12; i += 1) {
        data[i][half] += totalValue
        data[i].total += totalValue
      }
    }

  }

  return { data }
}


export function MonthlyRevenue({ accounts, pricesById }) {

  const { data } = React.useMemo(
    () => initializeData({ accounts, pricesById }),
    [accounts, pricesById]
  )

  const total = data.reduce((t, d) => t + d.total, 0)

  return (
    <div style={{ fontSize: 12 }}>
      <Stack alignItems="flex-end" direction="row" spacing={1}>
        <Typography variant="h6">
          Monthly Revenue
        </Typography>

        <div style={{ flex: 1 }} />

        <Cardinal
          color={colors.indigo[600]}
          value={formatCurrency(total)}
          label="Total Revenue"
        />
      </Stack>
      <ResponsiveContainer height={300} width="100%">
        <ComposedChart
          width="100%"
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" label interval={0} />
          <YAxis />
          <Tooltip formatter={tooltipFormatter} />
          <Legend
            formatter={legendFormatter}
          />
          <Bar dataKey="firstHalf" stackId="a" fill={colors.indigo[400]} />
          <Bar dataKey="secondHalf" stackId="a" fill={colors.indigo[200]} />
          <Line dataKey="total" type="monotone" stroke={colors.orange[500]} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

MonthlyRevenue.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  pricesById: PropTypes.object,
}
