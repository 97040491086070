import * as React from 'react'
import PropTypes from 'prop-types'

import Stack from '@material-ui/core/Stack'
import Typography from '@material-ui/core/Typography'


function round(n, places = 2) {
  const factor = 10 ** places
  return (Math.round(n * factor) / factor).toFixed(places)
}


export function Cardinal({ value, label, places = 2, color = 'text.primary' }) {

  return (
    <Stack spacing={1} direction="row" alignItems="flex-end">
      <Typography variant="h2" sx={{ color }}>
        {typeof value === 'number'
          ? round(value, places)
          : value
        }
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
    </Stack>
  )
}

Cardinal.propTypes = {
  value: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
  label: PropTypes.string,
  places: PropTypes.number,
  color: PropTypes.string,
}
