import * as React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import CopyIcon from '@material-ui/icons/ContentCopyRounded'
import DoneIcon from '@material-ui/icons/CheckRounded'


export const CopyButton = ({ value, children, ...buttonProps }) => {
  const [isCopied, setIsCopied] = React.useState(false)

  function handleCopy() {
    if (!navigator.clipboard) {
      return
    }

    navigator.clipboard.writeText(value)
    setIsCopied(true)
  }

  // Reset the `isCopied` flag if the value changes.
  React.useEffect(
    () => {
      setIsCopied(false)
    },
    [value]
  )

  return (
    <Button
      {...buttonProps}
      onClick={handleCopy}
      startIcon={isCopied ? <DoneIcon /> : <CopyIcon />}
    >
      {isCopied ? 'Copied!' : children}
    </Button>
  )
}

CopyButton.propTypes = {
  /** The value that will be added to the clipboard. */
  value: PropTypes.string,
  /** The content of the button. */
  children: PropTypes.node,
}
