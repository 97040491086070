import * as React from 'react'
import { SubscriptionPricesContext } from './SubscriptionPricesContext'

export function useSubscriptionPrices() {
  const context = React.useContext(SubscriptionPricesContext)

  if (context === undefined) {
    throw new Error(`useSubscription prices must be within SubscriptionPricesContext`)
  }

  return context
}
