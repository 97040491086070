import {
  UserLogModel,
} from 'src/data-model'
import useModelList from 'src/components/useModelList'

const FIREBASE_QUERY_LIMIT = 10000

export function useLogs({ account, start, end, units }) {
  // So...turns out Firebase has a hard limit on queries at 10,000. Instead of
  // making something dynamic and complicated, we're just going grab the next
  // 10,000 if the first query exceeds the limit, and then the next 10,000.
  // It took about 3 months of adding new customers to reach this level.
  // So we'll revisit this when we grow more (i.e. a good problem to have).
  const { results: logs1= [], status: status1, snapshotId: sid1 } = useModelList({
    model: UserLogModel,
    filter: query => {
      let q = query.orderBy('timestamp', 'desc')

      if (account) {
        q = q.where('accountId', '==', account.id)
      }

      return q
        .where('timestamp', '>=', start.toMillis())
        .where('timestamp', '<=', end.toMillis())
    },
    filterKey: `${account?.id}__${start.toFormat('yyyy-MM-dd')}__${end.toFormat('yyyy-MM-dd')}__${units}`,
    limit: FIREBASE_QUERY_LIMIT,
  })

  const lastLog1 = logs1?.[FIREBASE_QUERY_LIMIT - 1]

  const { results: logs2= [], status: status2, snapshotId: sid2 } = useModelList({
    model: UserLogModel,
    filter: query => {
      let q = query.orderBy('timestamp', 'desc').startAfter(lastLog1?.timestamp?.toMillis())

      if (account) {
        q = q.where('accountId', '==', account.id)
      }

      return q
        .where('timestamp', '>=', start.toMillis())
        .where('timestamp', '<=', end.toMillis())
    },
    filterKey: `${account?.id}__${start.toFormat('yyyy-MM-dd')}__${end.toFormat('yyyy-MM-dd')}__${units}__${lastLog1?.id}`,
    limit: FIREBASE_QUERY_LIMIT,
    skip: !lastLog1,
  })

  const lastLog2 = logs2?.[FIREBASE_QUERY_LIMIT - 1]

  const { results: logs3 = [], status: status3, snapshotId: sid3 } = useModelList({
    model: UserLogModel,
    filter: query => {
      let q = query.orderBy('timestamp', 'desc').startAfter(lastLog1?.timestamp?.toMillis())

      if (account) {
        q = q.where('accountId', '==', account.id)
      }

      return q
        .where('timestamp', '>=', start.toMillis())
        .where('timestamp', '<=', end.toMillis())
    },
    filterKey: `${account?.id}__${start.toFormat('yyyy-MM-dd')}__${end.toFormat('yyyy-MM-dd')}__${units}__${lastLog2?.id}`,
    limit: FIREBASE_QUERY_LIMIT,
    skip: !lastLog2,
  })


  // Combine the two lists.
  const logs = [ ...logs1, ...logs2, ...logs3 ]

  const status = lastLog2
    ? status3
    : lastLog1
    ? status2
    : status1

  const snapshotId = `${sid1}__${sid2}__${sid3}`

  return { logs, status, snapshotId }
}
