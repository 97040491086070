import * as React from 'react'

import { Outlet } from 'react-router-dom'

import Shell from 'src/components/Shell'
import Navbar from 'src/components/Navbar'
import MainLink from 'src/components/MainLink'
import MobileLink from 'src/components/MobileLink'
import { useAuth } from 'src/components/AuthProvider'

import {
  Text,
} from '@mantine/core'

import {
  Building,
  Users,
  Server,
  CreditCard,
  Activity,
  Map,
  ChartBar,
  ArrowBack,
  ChartArrowsVertical,
} from 'tabler-icons-react'


export function AdminInner() {
  const { user } = useAuth()

  const mainLinks = (
    <>
      <Text size="lg" weight={500}>
        Administrator Tools
      </Text>

      {user.isAdmin && (
        <>
          <MainLink
            label="Accounts"
            color="blue"
            icon={<Building size={16} />}
            to={`/admin`}
            path="/admin"
            end
          />

          <MainLink
            label="Users"
            color="green"
            icon={<Users size={16} />}
            to={`/admin/system-users`}
            path="/admin/system-users"
          />

          <MainLink
            label="Severs"
            color="grape"
            icon={<Server size={16} />}
            to={`/admin/resources`}
            path="/admin/resources"
          />

          <MainLink
            label="Billing"
            color="orange"
            icon={<CreditCard size={16} />}
            to={`/admin/stripe`}
            path="/admin/stripe"
          />

          <MainLink
            label="Charts"
            color="lime"
            icon={<ChartBar size={16} />}
            to={`/admin/charts`}
            path="/admin/charts"
          />

          <MainLink
            label="Activity"
            color="grape"
            icon={<Activity size={16} />}
            to={`/admin/activity`}
            path="/admin/activity"
          />

          <MainLink
            label="Stats"
            color="red"
            icon={<ChartArrowsVertical size={16} />}
            to={`/admin/stats`}
            path="/admin/stats"
          />

          <MainLink
            label="Map"
            color="cyan"
            icon={<Map size={16} />}
            to={`/admin/map`}
            path="/admin/map"
          />
        </>
      )}
    </>
  )

  const mobileLinks = (
    <>
      <MobileLink
        label="Back"
        color="gray"
        icon={<ArrowBack size={16} />}
        end
        to="/"
      />

      {user.isAdmin && (
        <>
          <MobileLink
            label="Accounts"
            color="blue"
            icon={<Building size={16} />}
            to={`/admin`}
            path="/admin"
            end
          />

          <MobileLink
            label="Charts"
            color="lime"
            icon={<ChartBar size={16} />}
            to={`/admin/charts`}
            path="/admin/charts"
          />

          <MobileLink
            label="Activity"
            color="grape"
            icon={<Activity size={16} />}
            to={`/admin/activity`}
            path="/admin/activity"
          />

          <MobileLink
            label="Module stats"
            color="red"
            icon={<ChartArrowsVertical size={16} />}
            to={`/admin/stats`}
            path="/admin/stats"
          />
        </>
      )}
    </>
  )

  const navbar = <Navbar links={mainLinks} mobileLinks={mobileLinks} />

  return (
    <Shell navbar={navbar}>
      <Outlet />
    </Shell>
  )
}


export const Admin = () => {

  return (
    <AdminInner />
  )
}