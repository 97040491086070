import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'
import DateField from 'genjo-ui/core/DateField'
import Checkbox from 'genjo-ui/core/Checkbox'

import {
  AccountModel,
} from 'src/data-model'

import { useCreateAccount } from 'src/services/accounts'

import { useNavigate } from 'react-router-dom'
import PrintableError from 'src/components/PrintableError'
import ResourceField from 'src/components/ResourceField'
import AccountTypeField from 'src/components/AccountTypeField'
import SubscriptionTypeField from 'src/components/SubscriptionTypeField'
import BillingIntervalField from 'src/components/BillingIntervalField'
import SubscriptionPriceField from 'src/components/SubscriptionPriceField'
import SubscriptionPricesProvider from 'src/components/SubscriptionPricesProvider'


export const CreateAccountDialog = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate()

  const [account, setAccount] = React.useState(AccountModel.create())

  const [error, setError] = React.useState(null)

  const [isExistingCustomer, setIsExistingCustomer] = React.useState(true)

  React.useEffect(
    () => {
      setAccount(AccountModel.create())
    },
    [isOpen],
  )

  const setFieldValue = (field, value) => {
    setAccount({ ...account, [field]: value })
  }

  const createAccount = useCreateAccount()

  function handleSubmit(event) {
    event.preventDefault()

    createAccount({
      values: account,
      isExistingCustomer,
      onSuccess: accountId => {
        navigate(`/accounts/${accountId}`)
      },
      onError: setError,
    })
  }

  const hasRequiredFields = Boolean(account.name
    && account.BBID
    && account.resourceId
    && account.databaseFileName
    && account.interfaceFileName
  )

  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <SubscriptionPricesProvider>
        <form onSubmit={handleSubmit}>
          <ClosableDialogTitle onClose={onClose}>Create Account</ClosableDialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <PrintableError error={error} />

              <Grid item xs={12}>
                <Typography variant="h3">
                  Account Details
                </Typography>
              </Grid>

              <Grid item xs={7}>
                <TextField
                  label="Name"
                  variant="filled"
                  value={account.name ?? ''}
                  required
                  onChange={event => setFieldValue('name', event.target.value)}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label="Base Builders ID"
                  variant="filled"
                  required
                  value={account.BBID ?? ''}
                  onChange={event => setAccount({
                    ...account,
                    BBID: event.target.value,
                    databaseFileName: `${event.target.value}_Data`,
                    interfaceFileName: `${event.target.value}_App`,
                  })}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Billing Email"
                  variant="filled"
                  value={account.email ?? ''}
                  onChange={event => setFieldValue('email', event.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Phone Number"
                  variant="filled"
                  value={account.phoneNumber ?? ''}
                  onChange={event => setFieldValue('phoneNumber', event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3">
                  Server Setup
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <ResourceField
                  required
                  label="Resource"
                  variant="filled"
                  value={account.resourceId ?? ''}
                  onChange={event => setFieldValue('resourceId', event.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Database File Name"
                  variant="filled"
                  required
                  value={account.databaseFileName ?? ''}
                  onChange={event => setFieldValue('databaseFileName', event.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="Interface File Name"
                  variant="filled"
                  required
                  value={account.interfaceFileName ?? ''}
                  onChange={event => setFieldValue('interfaceFileName', event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3">
                  Subscription Details
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <AccountTypeField
                  label="Account Type"
                  variant="filled"
                  required
                  value={account.accountType ?? ''}
                  onChange={event => setFieldValue('accountType', event.target.value)}
                />
              </Grid>

              <Grid item xs={4}>
                <SubscriptionTypeField
                  label="Subscription Type"
                  variant="filled"
                  required
                  value={account.subscriptionType ?? ''}
                  onChange={event => setFieldValue('subscriptionType', event.target.value)}
                />
              </Grid>

              <Grid item xs={4}>
                <BillingIntervalField
                  label="Billing Interval"
                  variant="filled"
                  required
                  value={account.billingInterval ?? ''}
                  onChange={event => setFieldValue('billingInterval', event.target.value)}
                />
              </Grid>

              <Grid item xs={7}>
                <SubscriptionPriceField
                  accountType={account.accountType}
                  billingInterval={account.billingInterval}
                  accessLevel="FINANCIAL"
                  label="Financial User Price"
                  variant="filled"
                  required
                  value={account.financialUserPriceId ?? ''}
                  onChange={event => setFieldValue('financialUserPriceId', event.target.value)}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label="Financial User Count"
                  variant="filled"
                  required
                  value={account.financialUsersLicenseCount ?? ''}
                  onChange={event => setFieldValue('financialUsersLicenseCount', event.target.value)}
                  type="number"
                  min={0}
                  max={5000}
                />
              </Grid>

              <Grid item xs={7}>
                <SubscriptionPriceField
                  accountType={account.accountType}
                  billingInterval={account.billingInterval}
                  accessLevel="PRODUCTION"
                  label="Production User Price"
                  variant="filled"
                  required
                  value={account.productionUserPriceId ?? ''}
                  onChange={event => setFieldValue('productionUserPriceId', event.target.value)}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label="Production User Count"
                  variant="filled"
                  required
                  value={account.productionUsersLicenseCount ?? ''}
                  onChange={event => setFieldValue('productionUsersLicenseCount', event.target.value)}
                  type="number"
                  min={0}
                  max={5000}
                />
              </Grid>

              <Grid item xs={7}>
                <SubscriptionPriceField
                  accountType={account.accountType}
                  billingInterval={account.billingInterval}
                  accessLevel="LIMITED"
                  label="Limited User Price"
                  variant="filled"
                  required
                  value={account.limitedUserPriceId ?? ''}
                  onChange={event => setFieldValue('limitedUserPriceId', event.target.value)}
                />
              </Grid>

              <Grid item xs={5}>
                <TextField
                  label="Limited User Count"
                  variant="filled"
                  required
                  value={account.limitedUsersLicenseCount ?? ''}
                  onChange={event => setFieldValue('limitedUsersLicenseCount', event.target.value)}
                  type="number"
                  min={0}
                  max={5000}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h3">
                  Billing Date Setup
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  label="Is Existing Customer?"
                  control={
                    <Checkbox
                      checked={isExistingCustomer}
                      onChange={event => {
                        setIsExistingCustomer(event.target.checked)
                        setFieldValue(
                          'billingStart',
                          event.target.checked
                            ? DateTime.now().plus({ days: 1 })
                            : DateTime.now(),
                        )
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <FormControlLabel
                  label="Is Old School Account?"
                  control={
                    <Checkbox
                      checked={account.isOldSchool}
                      onChange={event => setFieldValue('isOldSchool', event.target.checked)}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <DateField
                  disabled={!isExistingCustomer}
                  label={isExistingCustomer
                    ? 'When is the next billing date?'
                    : 'New subscription will start on'
                  }
                  value={account.billingStart}
                  onChange={newValue => setFieldValue('billingStart', newValue)}
                  placeholder="Leave blank to start immediately."
                  DatePickerProps={{
                    minDate: DateTime.now().plus({ days: 1 }),
                    maxDate: DateTime.now().plus({
                      days: 1,
                      [account.billingInterval === 'MONTHLY' ? 'months' : 'years']: 1,
                    })
                  }}
                  helperText={`This date should be in the FUTURE and should be one ${
                    account.billingInterval === 'MONTHLY' ? 'month' : 'year'
                  } from their last billing date. The system will assume that the account is paid until the next billing date. The customer will need to update their billing information and payment method before this date to prevent service disruptions.`}
                />
              </Grid>

            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={!hasRequiredFields}
              type="submit"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </SubscriptionPricesProvider>
    </Dialog>

  )
}

CreateAccountDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
