import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'


export function ChangeCounter({ newCount, originalCount, title }) {
  const delta = newCount - originalCount

  const color = delta > 0
    ? 'success.main'
    : 'error.main'

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography variant="h6" display="inline">
        {`${title}: ${newCount}`}
      </Typography>

      {Boolean(delta) && (
        <Typography variant="h6" color={color} display="inline">
          {`(${delta > 0 ? '+' : '-'}${Math.abs(delta)})`}
        </Typography>
      )}
    </Stack>
  )
}

ChangeCounter.propTypes = {

}
