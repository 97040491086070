import * as React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { UserModel } from 'src/data-model'
import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'

import { firebaseFunctions, firebaseDb } from 'src/services/firebase'
import { useAccount } from 'src/components/AccountProvider'



export function DeactivateUserDialog({ userId, isOpen, onClose }) {
  const snackbar = useSnackbar()
  const loader = useLoader()

  const { users } = useAccount()
  const user = users?.find(u => u.id === userId)

  async function handleDeactivate() {
    try {
      loader.open('Deactivating user...')

      const ref = firebaseDb.collection(UserModel.collection).doc(user.id)
      await ref.update({
        isActive: false,
      })

      const updateFileMakerUser = firebaseFunctions.httpsCallable('updateFileMakerUser')
      const updateFileMakerResponse = await updateFileMakerUser({ userId: user.id })

      if ( updateFileMakerResponse.data.status !== 'success') {
        console.log({ updateFileMakerResponse })
        throw new Error(updateFileMakerResponse?.error ?? 'Something went wrong syncing with FileMaker')
      }

      const updateAuthUser = firebaseFunctions.httpsCallable('updateAuthUser')
      const updateAuthUserResponse = await updateAuthUser({ userId: user.id })

      if (updateAuthUserResponse.data.status !== 'success') {
        console.log({ updateAuthUserResponse })
        throw new Error(updateAuthUserResponse?.error ?? 'Something went creating auth user')
      }

      snackbar.addMessage({
        text: 'User updated',
        type: 'success',
      })

      onClose()
    } catch (error) {
      console.log({ error })
      const ref = firebaseDb.collection(UserModel.collection).doc(user.id)
      await ref.update({
        isActive: true,
      })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem updating user.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      style={{ minHeight: 400 }}
    >
      <ClosableDialogTitle onClose={onClose}>Deactivate User</ClosableDialogTitle>

      <DialogContent>
        <Typography sx={{ mb: 1 }}>
          {`${user?.name} will no longer have access to your account.`}
        </Typography>

        <Typography>
          {`Once deactivated, 1 ${user?.accessLevel} license will be available.`}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeactivate}
          variant="contained"
        >
          Deactivate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeactivateUserDialog.propTypes = {
  userId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
