import * as React from 'react'
import { DateTime } from 'luxon'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Menu from '@material-ui/core/Menu'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListSubheader from '@material-ui/core/ListSubheader'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Stack from '@material-ui/core/Stack'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import DialogActions from '@material-ui/core/DialogActions'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'
import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'
import useModelList from 'src/components/useModelList'
import LinkBehavior from 'src/components/LinkBehavior'
import DownloadIcon from '@material-ui/icons/DownloadRounded'
import RangeIcon from '@material-ui/icons/TuneRounded'
import {
  ResourceModel,
} from 'src/data-model'
import { firebaseDb } from 'src/services/firebase'
import { colors } from 'genjo-ui/core/ThemeProvider'
import DateField from 'genjo-ui/core/DateField'
import * as FileSaver from 'file-saver'
import { stringify } from 'csv-stringify/lib/sync'

import { Graph } from './Graph'

import { humanize } from 'src/utils/time'

import { useLogs } from './useLogs'


const accessLevelDatasets = [
  { value: 'LIMITED', label: 'Limited Users', index: 0, color: colors.blue[500] },
  { value: 'PRODUCTION', label: 'Production Users', index: 1, color: colors.orange[500] },
  { value: 'FINANCIAL', label: 'Financial Users', index: 2, color: colors.green[500] },
]

const accessLevels = {
  LIMITED: { label: 'Limited', color: 'info' },
  PRODUCTION: { label: 'Production', color: 'warning' },
  FINANCIAL: { label: 'Financial', color: 'success' },
}

const accessLevelOffsets = {
  FINANCIAL: 0,
  PRODUCTION: 1,
  LIMITED: 2,
}

const SLICE_SIZE = 50


export function ActivityLayout({ account }) {
  const loader = useLoader()
  const snackbar = useSnackbar()

  const [units, setUnits] = React.useState('hours')
  const [start, setStart] = React.useState(DateTime.local().setZone('America/Los_Angeles').startOf('week'))
  const [end, setEnd] = React.useState(DateTime.local().setZone('America/Los_Angeles').endOf('week'))
  const [accessLevel, setAccessLevel] = React.useState('')
  const [resourceId, setResourceId] = React.useState('')

  const [rangeMenuIsOpen, setRangeMenuIsOpen] = React.useState(false)
  const rangeButtonRef = React.useRef(null)

  function handleRangeChange(range) {
    setRangeMenuIsOpen(false)

    switch (range) {
      case 'today': {
        setStart(DateTime.local().startOf('day'))
        setEnd(DateTime.local().endOf('day'))
        break
      }

      case 'yesterday': {
        setStart(DateTime.local().minus({ days: 1 }).startOf('day'))
        setEnd(DateTime.local().minus({ days: 1 }).endOf('day'))
        break
      }

      case 'last_7_days': {
        setStart(DateTime.local().minus({ days: 6 }).startOf('day'))
        setEnd(DateTime.local().endOf('day'))
        break
      }

      case 'this_week': {
        setStart(DateTime.local().startOf('week'))
        setEnd(DateTime.local().endOf('week'))
        break
      }

      case 'last_week': {
        setStart(DateTime.local().minus({ weeks: 1 }).startOf('week'))
        setEnd(DateTime.local().minus({ weeks: 1 }).endOf('week'))
        break
      }

      case 'this_month': {
        setStart(DateTime.local().startOf('month'))
        setEnd(DateTime.local().endOf('month'))
        break
      }

      case 'last_month': {
        setStart(DateTime.local().minus({ months: 1 }).startOf('month'))
        setEnd(DateTime.local().minus({ months: 1 }).endOf('month'))
        break
      }

      case 'this_quarter': {
        setStart(DateTime.local().startOf('quarter'))
        setEnd(DateTime.local().endOf('quarter'))
        break
      }

      case 'last_quarter': {
        setStart(DateTime.local().minus({ quarters: 1 }).startOf('quarter'))
        setEnd(DateTime.local().minus({ quarters: 1 }).endOf('quarter'))
        break
      }

      default: {
        console.log('Invalid range')
      }
    }
  }

  const [currentSlice, setCurrentSlice] = React.useState(SLICE_SIZE)

  // const { results: logs = [], status, snapshotId } = useModelList({
  //   model: UserLogModel,
  //   filter: query => {
  //     let q = query.orderBy('timestamp', 'desc')

  //     if (account) {
  //       q = q.where('accountId', '==', account.id)
  //     }

  //     return q
  //       .where('timestamp', '>=', start.toMillis())
  //       .where('timestamp', '<=', end.toMillis())
  //   },
  //   filterKey: `${account?.id}__${start.toFormat('yyyy-MM-dd')}__${end.toFormat('yyyy-MM-dd')}__${units}`,
  //   limit: 10000,
  // })
  const { logs, status, snapshotId } = useLogs({
    account,
    start,
    end,
    units,
  })

  const filteredLogs = React.useMemo(
    () => logs.filter(log => {
      if (accessLevel && log.accessLevel !== accessLevel) {
        return false
      }

      if (resourceId && log.resourceId !== resourceId) {
        return false
      }

      return true
    }),
    [accessLevel, resourceId, logs]
  )

  const { results: resources = [] } = useModelList({
    model: ResourceModel,
    filter: query => query.orderBy('name'),
    limit: 100,
    skip: Boolean(account),
  })

  const [exportMenuOpen, setExportMenuOpen] = React.useState(false)
  const [exportStart, setExportStart] = React.useState(DateTime.now().startOf('month'))
  const [exportEnd, setExportEnd] = React.useState(DateTime.now().endOf('month'))
  const [exportByAccount, setExportByAccount] = React.useState(true)

  async function handleExport() {
    try {
      loader.open('Exporting activity log...')

      const header = exportByAccount
        ? ['BBID', 'Account', 'Total Users', 'Financial Users', 'Production Users', 'Limited Users', 'Total Logins', 'Financial Logins', 'Production Logins', 'Limited Logins']
        : ['BBID', 'Account', 'Name', 'Email', 'Access Level', 'Resource', 'Timestamp']

      const logSnaps = await firebaseDb
        .collection('userLogs')
        .orderBy('timestamp', 'desc')
        .where('timestamp', '>=', exportStart.toMillis())
        .where('timestamp', '<=', exportEnd.toMillis())
        .get()

      let rows = []
      if (exportByAccount) {
        const dataByAccount = {}
        logSnaps.docs.forEach(doc => {
          const data = doc.data()

          // Initialize the account row record
          // Use tracking sets for the user counts and convert to set.size later
          if (!dataByAccount[data.accountId]) {
            dataByAccount[data.accountId] = [data.accountName, data.BBID, new Set(), new Set(), new Set(), new Set(), 0, 0, 0, 0]
          }

          const offset = accessLevelOffsets[data.accessLevel]
          dataByAccount[data.accountId][2].add(data.userId)
          dataByAccount[data.accountId][3 + offset].add(data.userId)
          dataByAccount[data.accountId][6] += 1
          dataByAccount[data.accountId][7 + offset] += 1
        })

        rows = Object.values(dataByAccount).map(a => {
          a[2] = a[2].size
          a[3] = a[3].size
          a[4] = a[4].size
          a[5] = a[5].size

          return a
        })

        rows.sort((a, b) => a[0] > b[0]
          ? 1
          : a[0] < b[0]
          ? -1
          : 0
        )
      } else {
        logSnaps.docs.forEach(doc => {
          const data = doc.data()

          rows.push([
            data.accountName,
            data.BBID,
            data.name,
            data.email,
            data.accessLevel,
            data.resourceName,
            DateTime.fromMillis(data.timestamp).toISO(),
          ])
        })
      }

      // Convert to strings and escape newline characters.
      const adjustedRows = rows.map(row => row.map(v => `${v}`.replace(/\n/g, '\\n')))

      const csvString = stringify(adjustedRows, {
        columns: header,
        header: true,
      })

      const filename = `${
        exportByAccount ? 'log-by-account' : 'log-activity'
      }-${
        DateTime.now().toUTC().toISO()
      }.csv`

      const file = new File(
        [csvString],
        filename,
        {
          type: 'text/csv',
        }
      )

      FileSaver.saveAs(file)
      snackbar.success('Activity data exported.')
    } catch (err) {
      console.log({ err })
      snackbar.error(err?.message || 'Something went wrong.')
    } finally {
      loader.close()
      setExportMenuOpen(false)
    }
  }

  return (
    <Container maxWidth="md">
      <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 2 }}>
        <Typography variant="h1" component="h1" sx={{ flex: 1, mb: 1 }}>
          Activity Log
        </Typography>

        <DateField
          value={start}
          onChange={value => setStart(value.startOf('day'))}
        />

        <DateField
          value={end}
          onChange={value => setEnd(value.endOf('day'))}
        />

        <IconButton
          ref={rangeButtonRef}
          onClick={() => setRangeMenuIsOpen(s => !s)}
        >
          <RangeIcon />
        </IconButton>

        <Menu
          open={rangeMenuIsOpen}
          onClose={() => setRangeMenuIsOpen(false)}
          anchorEl={rangeButtonRef.current}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <MenuItem onClick={() => handleRangeChange('today')}>Today</MenuItem>
          <MenuItem onClick={() => handleRangeChange('yesterday')}>Yesterday</MenuItem>
          <MenuItem onClick={() => handleRangeChange('last_7_days')}>Last 7 Days</MenuItem>
          <MenuItem onClick={() => handleRangeChange('this_week')}>This Week</MenuItem>
          <MenuItem onClick={() => handleRangeChange('last_week')}>Last Week</MenuItem>
          <MenuItem onClick={() => handleRangeChange('this_month')}>This Month</MenuItem>
          <MenuItem onClick={() => handleRangeChange('last_month')}>Last Month</MenuItem>
          <MenuItem onClick={() => handleRangeChange('this_quarter')}>This Quarter</MenuItem>
          <MenuItem onClick={() => handleRangeChange('last_quarter')}>Last Quarter</MenuItem>
        </Menu>
      </Stack>

      {status === 'success' ? (
        <Graph
          logs={filteredLogs}
          start={start}
          end={end}
          units={units}
          status={status}
          snapshotId={`${snapshotId}-${accessLevel}-${resourceId}`}
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: 282,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h4">
            Loading...
          </Typography>
        </div>
      )}

      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} sx={{ mb: 2 }}>



        <TextField
          fullWidth={false}
          value={units}
          onChange={event => setUnits(event.target.value)}
          select
        >
          <MenuItem value="days">Daily</MenuItem>
          <MenuItem value="hours">Hourly</MenuItem>
        </TextField>

        <TextField
          fullWidth={false}
          value={accessLevel}
          onChange={event => setAccessLevel(event.target.value)}
          select
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">All Access Levels</MenuItem>
          {Object.values(accessLevelDatasets).map(al => (
            <MenuItem key={al.value} value={al.value}>
              {al.label}
            </MenuItem>
          ))}
        </TextField>

        {!account && (
          <TextField
            fullWidth={false}
            value={resourceId}
            onChange={event => setResourceId(event.target.value)}
            select
            SelectProps={{
              displayEmpty: true,
            }}
          >
            <MenuItem value="">All Resources</MenuItem>
            {resources.map(resource => (
              <MenuItem key={resource.id} value={resource.id}>
                {resource.name}
              </MenuItem>
            ))}
          </TextField>
        )}



        {!account && (
          <Button
            variant="contained"
            onClick={() => setExportMenuOpen(true)}
            startIcon={<DownloadIcon />}
          >
            Export
          </Button>
        )}
      </Stack>

      <List>
        <ListSubheader>Activity Log</ListSubheader>

        {logs.length === 0 && (
          <ListItem>
            <ListItemText>
              {status === 'loading'
                ? 'Loading...'
                : 'No activity recorded.'
              }
            </ListItemText>
          </ListItem>
        )}

        {filteredLogs.slice(0, currentSlice).map(log => (
          <ListItem
            key={log.id}
            sx={{
              mb: 1,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'divider',
              borderRadius: 1,
            }}
            button
            component={LinkBehavior}
            to={`/accounts/${log.accountId}/users/${log.userId}`}
          >
            <ListItemIcon sx={{ minWidth: 120 }}>
              <Typography variant="subtitle2" sx={{ mr: 2 }}>
                {humanize(log.timestamp)}
              </Typography>
            </ListItemIcon>

            <ListItemText>
              <Typography>{`${log.name} @ ${log.timestamp.toFormat('M/d/yy h:mm a')}`}</Typography>
              <Typography variant="caption" color="text.secondary">
                {`${log.BBID} | ${log.accountName}`}
              </Typography>
            </ListItemText>

            <ListItemText style={{ flex: 1, textAlign: 'right' }}>
              <Typography variant="caption">

              </Typography>
            </ListItemText>

            <ListItemSecondaryAction>
              <Chip
                style={{ width: 80 }}
                color={accessLevels[log.accessLevel].color}
                label={accessLevels[log.accessLevel].label}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}

        {logs.length >= currentSlice && (
          <ListItem
            button={logs.length >= currentSlice}
            onClick={logs.length >= currentSlice
              ? () => setCurrentSlice(s => s + SLICE_SIZE)
              : null
            }
            sx={{
              borderRadius: 1,
            }}
          >
            <ListItemText color="primary">
              {`${logs.length - currentSlice} more...`}
            </ListItemText>
          </ListItem>
        )}
      </List>

      <Dialog
        open={exportMenuOpen}
        onClose={() => setExportMenuOpen(false)}
        maxWidth="sm"
      >
        <ClosableDialogTitle onClose={() => setExportMenuOpen(false)}>
          Export Activity Log
        </ClosableDialogTitle>

        <DialogContent>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <DateField
                label="Start"
                value={exportStart}
                onChange={setExportStart}
              />
            </Grid>

            <Grid item xs={6}>
              <DateField
                label="End"
                value={exportEnd}
                onChange={setExportEnd}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Select Report Type"
                value={exportByAccount ? 'account' : 'raw'}
                onChange={event => setExportByAccount(event.target.value === 'account')}
                select
              >
                <MenuItem value="account">Users by account</MenuItem>
                <MenuItem value="raw">All activity events</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setExportMenuOpen(false)}>
            Cancel
          </Button>

          <Button onClick={handleExport} variant="contained">
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
