import * as React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useLocation } from 'react-router-dom'
import { SignUpLayout } from './SignUpLayout'
import { SignUpProvider } from './SignUpProvider'


const testStripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY)
const liveStripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY)


function useQuery() {
  return new URLSearchParams(useLocation().search)
}


export function SignUp() {
  const query = useQuery()
  const demo = query.get('demo')

  const stripePromise = demo === 'true'
    ? testStripePromise
    : liveStripePromise

  const accountType = demo === 'true' ? 'DEMO' : 'LIVE'

  return (
    <Elements stripe={stripePromise}>
      <SignUpProvider accountType={accountType}>
        <SignUpLayout />
      </SignUpProvider>

      {demo === 'true' && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            pointerEvents: 'none',
          }}
        >
          <span style={{ color: 'red', fontSize: 32 }}>*** DEMO ***</span>
        </div>
      )}
    </Elements>
  )
}
