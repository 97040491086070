import * as React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { useSignUp } from './useSignUp'


export function ConfirmDetails() {

  const { values } = useSignUp()

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Company Information
        </Typography>

        <Typography variant="subtitle2" color="text.secondary">
          Company Name
        </Typography>

        <Typography variant="body1" sx={{ mb: 1 }}>
          {values.companyName}
        </Typography>

        <Typography variant="subtitle2" color="text.secondary">
          Owner Full Name
        </Typography>

        <Typography variant="body1" sx={{ mb: 1 }}>
          {values.fullName}
        </Typography>

        <Typography variant="subtitle2" color="text.secondary">
          Email
        </Typography>

        <Typography variant="body1" sx={{ mb: 1 }}>
          {values.email}
        </Typography>

        <Typography variant="subtitle2" color="text.secondary">
          Phone Number
        </Typography>

        <Typography variant="body1" sx={{ mb: 1 }}>
          {values.phoneNumber}
        </Typography>
      </CardContent>
    </Card>
  )
}
