import * as React from 'react'

import { Outlet } from 'react-router-dom'
import AccountProvider, { useAccount } from 'src/components/AccountProvider'

import Shell from 'src/components/Shell'
import Navbar from 'src/components/Navbar'
import MainLink from 'src/components/MainLink'
import MobileLink from 'src/components/MobileLink'
import { useAuth } from 'src/components/AuthProvider'

import {
  Text,
} from '@mantine/core'

import {
  ArrowBack,
  Dashboard,
  Users,
  ChartBar,
  Activity,
  ClipboardText,
} from 'tabler-icons-react'


export function AccountInner() {
  const { user } = useAuth()
  const { account } = useAccount()


  const mainLinks = (
    <>
      <Text size="lg" weight={500}>
        {`${account?.BBID} | ${account?.name}`}
      </Text>

      <MainLink
        label="Back to dashboard"
        color="gray"
        icon={<ArrowBack size={16} />}
        to="/"
        end
      />

      <MainLink
        label="Account Overview"
        color="blue"
        icon={<Dashboard size={16} />}
        to={`/accounts/${account.id}`}
        path="/accounts/:accountId"
        end
      />

      <MainLink
        label="Manager Users"
        color="green"
        icon={<Users size={16} />}
        to={`/accounts/${account.id}/users`}
        path="/accounts/:accountId/users"
      />

      {!account.isOldSchool && (
        <>
          <MainLink
            label="Application"
            color="orange"
            icon={<ChartBar size={16} />}
            to={`/accounts/${account.id}/file-maker`}
            path="/accounts/:accountId/file-maker"
          />

          <MainLink
            label="Activity"
            color="grape"
            icon={<Activity size={16} />}
            to={`/accounts/${account.id}/admin-activity`}
            path="/accounts/:accountId/admin-activity"
          />
        </>
      )}

      {user.isAdmin && (
        <>
          <Text size="lg" mt="xl">Admin Tools</Text>

          <MainLink
            label="Back to admin accounts"
            color="gray"
            icon={<ArrowBack size={16} />}
            to="/admin"
            end
          />

          <MainLink
            label="Admin Details"
            color="red"
            icon={<ClipboardText size={16} />}
            to={`/accounts/${account.id}/admin-details`}
            path="/accounts/:accountId/admin-details"
          />

        </>
      )}
    </>
  )

  const mobileLinks = (
    <>
      <MobileLink
        label="Back"
        color="gray"
        icon={<ArrowBack size={16} />}
        end
        to="/"
      />

      <MobileLink
        label="Overview"
        color="blue"
        icon={<Dashboard size={16} />}
        to={`/accounts/${account.id}`}
        path="/accounts/:accountId"
        end
      />

      <MobileLink
        label="Users"
        color="green"
        icon={<Users size={16} />}
        to={`/accounts/${account.id}/users`}
        path="/accounts/:accountId/users"
      />
    </>
  )

  const navbar = <Navbar links={mainLinks} mobileLinks={mobileLinks} />

  return (
    <Shell navbar={navbar}>
      <Outlet />
    </Shell>
  )
}


export const Account = () => {
  return (
    <AccountProvider>
      <AccountInner />
    </AccountProvider>
  )
}