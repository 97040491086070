import * as React from 'react'

import { firebaseFunctions } from 'src/services/firebase'
import useDebounce from 'genjo-ui/core/useDebounce'


export function useInvoicePreview({ isDirty, account, subscription, newCounts }) {
  const [state, setState] = React.useState('idle')
  const [changeState, setChangeState] = React.useState('idle')
  const [result, setResult] = React.useState(null)

  const debouncedNewCounts = useDebounce(newCounts, 1000)

  React.useEffect(
    () => {
      if (isDirty) {
        setChangeState('loading')
      }
    },
    [isDirty, newCounts]
  )

  React.useEffect(
    () => {
      if (isDirty) {
        setState('loading')
      }
    },
    [isDirty, debouncedNewCounts]
  )

  React.useEffect(
    () => {
      if (state !== 'loading') {
        return
      }

      let isMounted = true
      const getSubscriptionPreview = firebaseFunctions.httpsCallable('getSubscriptionPreview')

      ;(async () => {
        const newItems = [
          {
            id: subscription.items.data.find(item => item.price.id === account.financialUserPriceId).id,
            quantity: debouncedNewCounts[0],
          },
          {
            id: subscription.items.data.find(item => item.price.id === account.productionUserPriceId).id,
            quantity: debouncedNewCounts[1],
          },
          {
            id: subscription.items.data.find(item => item.price.id === account.limitedUserPriceId).id,
            quantity: debouncedNewCounts[2],
          },
        ]

        try {
          const response = await getSubscriptionPreview({
            accountType: account.accountType,
            stripeCustomerId: account.stripeCustomerId,
            stripeSubscriptionId: account.stripeSubscriptionId,
            newItems,
          })

          if (isMounted) {
            setState('success')
            setChangeState('success')
            setResult(response)
          }
        } catch (err) {
          console.log({ err })
        }
      })()

      return () => isMounted = false
    },
    [state, debouncedNewCounts, subscription, account],
  )

  const derivedState = changeState === 'loading' || state === 'loading'
    ? 'loading'
    : state

  return [result, derivedState]
}
