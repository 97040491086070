import * as React from 'react'

import useModelInstance from 'src/components/useModelInstance'
import useInstanceValues from 'src/components/useInstanceValues'
import { ResourceModel } from 'src/data-model'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import BackIcon from '@material-ui/icons/ArrowBackRounded'

import SaveButtonsDialog from 'genjo-ui/core/SaveButtonsDialog'
import ConfirmationDialog from 'genjo-ui/core/ConfirmationDialog'
import Switch from 'genjo-ui/core/Switch'
import LinkBehavior from 'src/components/LinkBehavior'
import { useParams, useNavigate } from 'react-router-dom'

import { useUpdateResource, useDeleteResource } from 'src/services/resources'


const _ALLOWED_FIELDS = [
  'name', 'status', 'domain', 'ip', 'notes', 'region'
]


export function AdminResource() {
  const { resourceId } = useParams()
  const navigate = useNavigate()
  const updateResource = useUpdateResource()
  const deleteResource = useDeleteResource()



  const [isRequestingDelete, setIsRequestingDelete] = React.useState(false)

  const { status, instance: resource } = useModelInstance({
    model: ResourceModel,
    id: resourceId,
  })

  const { values, setFieldValue, isDirty, resetValues } = useInstanceValues({
    model: ResourceModel,
    instance: resource,
    fields: _ALLOWED_FIELDS,
  })

  function handleSave() {
    updateResource({ resourceId, values })
  }

  function handleRequestDelete() {
    setIsRequestingDelete(true)
  }

  function handleCancelRequestDelete() {
    setIsRequestingDelete(false)
  }

  function handleDelete() {
    deleteResource({
      resourceId,
      onSuccess: () => navigate('/admin/resources'),
      onSettled: handleCancelRequestDelete,
    })
  }

  if (status === 'loading' || !values) {
    return 'Loading...'
  }

  return (
    <>
      <Button
        startIcon={<BackIcon />}
        component={LinkBehavior}
        to={`/admin/resources`}
      >
        Back to Resources
      </Button>

      <Grid container spacing={1} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">{resource?.name ?? '(no name yet)'}</Typography>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            onClick={handleRequestDelete}
          >
            Delete Resource
          </Button>
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          <Typography variant="h6">
            Details
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={values.name}
                onChange={event => setFieldValue('name', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Domain"
                value={values.domain}
                onChange={event => setFieldValue('domain', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="IP"
                value={values.ip}
                onChange={event => setFieldValue('ip', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Region"
                value={values.region}
                onChange={event => setFieldValue('region', event.target.value)}
                select
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" disabled>Please select a region...</MenuItem>
                <MenuItem value="Las Vegas">Las Vegas</MenuItem>
                <MenuItem value="Atlanta">Atlanta</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                label="Active"
                control={(
                  <Switch
                    checked={values.status === 'ACTIVE'}
                    onChange={event => setFieldValue(
                      'status',
                      event.target.checked ? 'ACTIVE' : 'INACTIVE',
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Notes"
                value={values.notes}
                onChange={event => setFieldValue('notes', event.target.value)}
                multiline
                minRows={5}
                maxRows={10}
              />
            </Grid>

          </Grid>
        </CardContent>
      </Card>

      <SaveButtonsDialog
        isIn={isDirty}
        onCancel={resetValues}
        onSave={handleSave}
      />

      <ConfirmationDialog
        isOpen={isRequestingDelete}
        onClose={handleCancelRequestDelete}
        title="Delete Resource?"
        message="This will permanently delete this resource from the database."
        onConfirm={handleDelete}
      />
    </>
  )
}
