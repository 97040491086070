import * as React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import Stack from '@material-ui/core/Stack'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'

import { firebaseFunctions } from 'src/services/firebase'


export function InvoicePoNumber({ invoicePoNumber, accountId }) {

  const loader = useLoader()
  const snackbar = useSnackbar()

  const [isEditing, setIsEditing] = React.useState(false)

  const [value, setValue] = React.useState(invoicePoNumber)

  async function handleSave() {
    try {
      loader.open('Updating PO Number...')
      const updatePoNumber = firebaseFunctions.httpsCallable('updatePoNumber')

      const response = await updatePoNumber({ accountId, invoicePoNumber: value })

      if (response?.data?.status !== 'success') {
        throw new Error(response?.message ?? 'There was a problem saving PO Number.')
      }

      snackbar.success('PO Number Updated.')
      setIsEditing(false)
    } catch (err) {
      console.log({ err })
      snackbar.error(err?.message)
    } finally {
      loader.close()
    }
  }

  // Synchronize value to a chagne from props, or when the dialog is toggled.
  React.useEffect(
    () => {
      setValue(invoicePoNumber)
    },
    [invoicePoNumber, isEditing]
  )

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Typography>
          <strong>PO Number: </strong>{invoicePoNumber || 'None'}
        </Typography>

        <Button onClick={() => setIsEditing(true)}>
          Edit
        </Button>
      </Stack>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={isEditing}
        onClose={() => setIsEditing(false)}
      >
        <ClosableDialogTitle onClose={() => setIsEditing(false)}>
          Update Invoice PO Number
        </ClosableDialogTitle>

        <DialogContent>
          <Typography sx={{ mb: 1 }}>
            This optional number will appear at the top of your invoices from Base Builders with the label, "PO Number".
          </Typography>

          <TextField
            placeholder="Optional"
            value={value || ''}
            onChange={event => setValue(event.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSave}
            disabled={invoicePoNumber === value}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

InvoicePoNumber.propTypes = {
  accountId: PropTypes.string,
  invoicePoNumber: PropTypes.string,
}
