import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/styles'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import ButtonBase from '@material-ui/core/ButtonBase'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import CircleLoader from 'genjo-ui/core/CircleLoader'

import Dropzone from 'genjo-ui/core/Dropzone'
import { ImageCropper } from 'src/components/ImageCropper'
import { colors } from 'genjo-ui/core/ThemeProvider'

import { ImageUploaderProgressBar } from './ImageUploaderProgressBar'

import { Image } from 'src/data-model'

import { safeDivide } from 'genjo-ui/core/utils'
import { cropImage, createUploadTask } from './utils'
import { firebaseDb } from 'src/services/firebase'

import ImageIcon from '@material-ui/icons/AddPhotoAlternateRounded'
import AvatarIcon from '@material-ui/icons/AddAPhotoRounded'


const useStyles = makeStyles(theme => ({
  arDiv: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingCropper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 300,
  },
  progressContainer: {
    position: 'absolute',
    bottom: 16,
    left: 16,
    right: 16,
    overflow: 'hidden',
  },
  completeIcon: {
    color: colors.green[500],
    fontSize: theme.typography.h3.fontSize,
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary[50],
  },

  cancelChangeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
  },

  imageButton: {
    overflow: 'hidden',
    borderRadius: props => props.isAvatar
      ? '50%'
      : theme.shape.borderRadius,
    position: 'relative',
    '&:hover': {
      '& $hoverContainer': {
        opacity: 0.5,
      },
    }
  },

  hoverContainer: {
    position: 'absolute',
    inset: 0,
    opacity: 0,
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imageIcon: {
    fontSize: 36,
    color: '#fff',
  },
}))

const ImageUploaderActions = {
  INITIALIZE: 'INITIALIZE',
  LOAD_FILE_TO_CROP: 'LOAD_FILE_TO_CROP',
  LOAD_PREVIEW: 'LOAD_PREVIEW',
  START_UPLOAD: 'START_UPLOAD',
  UPLOAD_UPDATE: 'UPLOAD_UPDATE',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  REQUEST_REMOVAL: 'REQUEST_REMOVAL',
  START_REMOVAL: 'START_REMOVAL',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  UPLOAD_FAIL: 'UPLOAD_FAIL',
}

const ImageUploaderStates = {
  INITIALIZING: 'INITIALIZING',
  READY: 'READY',
  IMAGE_LOADED: 'IMAGE_LOADED',
  CROPPING: 'CROPPING',
  UPLOADING: 'UPLOADING',
  REQUESTING_REMOVAL: 'REQUESTING_REMOVAL',
  REMOVING: 'REMOVING',
  ERROR: 'ERROR',
}

function getInitialState() {
  return {
    status: ImageUploaderStates.READY,
    file: null,
    preview: '',
    error: null,
    imageRef: null,
    original: { size: 0, progress: 0, url: '', task: null },
    cropped: { size: 0, progress: 0, url: '', task: null },
    thumbnail: { size: 0, progress: 0, url: '', task: null },
  }
}


function imageUploaderReducer(state, action) {

  switch (action.type) {
    case ImageUploaderActions.INITIALIZE: {
      return getInitialState()
    }

    case ImageUploaderActions.LOAD_FILE_TO_CROP: {
      return {
        ...state,
        status: ImageUploaderStates.CROPPING,
        file: action.file,
      }
    }

    case ImageUploaderActions.LOAD_PREVIEW: {
      return {
        ...state,
        status: ImageUploaderStates.CROPPING,
        preview: action.preview,
      }
    }

    case ImageUploaderActions.START_UPLOAD: {
      return {
        ...state,
        status: ImageUploaderStates.UPLOADING,
        imageRef: action.imageRef,
        original: { ...state.original, task: action.originalTask, size: action.originalSize },
        cropped: { ...state.cropped, task: action.croppedTask, size: action.croppedSize },
        thumbnail: { ...state.thumbnail, task: action.thumbnailTask, size: action.thumbnailSize },
      }
    }

    case ImageUploaderActions.UPLOAD_UPDATE: {
      return {
        ...state,
        [action.version]: {
          ...state[action.version],
          progress: action.progress,
        },
      }
    }

    case ImageUploaderActions.UPLOAD_SUCCESS: {
      return {
        ...state,
        [action.version]: {
          ...state[action.version],
          url: action.url,
          progress: state[action.version].size,
        }
      }
    }

    case ImageUploaderActions.UPLOAD_FAIL: {
      return {
        ...state,
        status: ImageUploaderStates.ERROR,
        error: action.error,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}


export function ImageUploader({
  ownerId,
  imageType = 'photo',
  title = 'Upload Image',
  size = 'sm',
  image: imageFromProps,
  onRemove,
  onSuccess,
  containerAspectRatio = 1,
  isAvatar = false,

  // Cropperjs Editor Options
  aspectRatio = NaN,
  minCropBoxWidth = 0,
  minCropBoxHeight = 0,
  viewMode = 1,
  guides = true,
  responsive = true,
}) {
  const classes = useStyles({ isAvatar })

  const [state, dispatch] = React.useReducer(
    imageUploaderReducer,
    getInitialState(),
  )

  const [isChanging, setIsChanging] = React.useState(false)
  React.useEffect(
    () => {
      setIsChanging(false)
    },
    [imageFromProps]
  )

  const [menuAnchor, setMenuAnchor] = React.useState(null)

  function reset() {
    dispatch({ type: ImageUploaderActions.INITIALIZE })
  }

  const {
    status,
    file,
    preview,
    original,
    cropped,
    thumbnail,
    imageRef,
  } = state

  const progress = 100 * safeDivide(
    original.progress + cropped.progress + thumbnail.progress,
    original.size + cropped.size + thumbnail.size,
  )

  async function save() {
    if (!window.cropper) {
      return
    }

    const isExisting = !file

    // Get the cropped/thumbnail image from the cropper.
    const croppedBlob = await cropImage(window.cropper, {})
    const thumbnailBlob = await cropImage(window.cropper, {
      maxWidth: 300,
    })

    const newImageRef = firebaseDb.collection('images').doc(
      isExisting ? imageFromProps.id : undefined
    )

    const imageDoc = await newImageRef.get()

    if (!imageDoc.exists) {
      const image = Image.fromDb(newImageRef.id, {
        ownerId,
        type: imageType,
      })

      await newImageRef.set(Image.toDb(image))
    }

    const originalName = `images/${newImageRef.id}@original`
    const croppedName = `images/${newImageRef.id}@cropped`
    const thumbnailName = `images/${newImageRef.id}@thumbnail`

    let originalTask
    if (!isExisting) {
      originalTask = createUploadTask({
        name: originalName,
        file,
        onUpdate: progress => dispatch({
          type: ImageUploaderActions.UPLOAD_UPDATE,
          version: 'original',
          progress,
        }),
        onError: error => dispatch({
          type: ImageUploaderActions.UPLOAD_FAIL,
          error,
        }),
        onSuccess: url => dispatch({
          type: ImageUploaderActions.UPLOAD_SUCCESS,
          version: 'original',
          url,
        })
      })
    }

    const croppedTask = createUploadTask({
      name: croppedName,
      file: croppedBlob,
      onUpdate: progress => dispatch({
        type: ImageUploaderActions.UPLOAD_UPDATE,
        version: 'cropped',
        progress,
      }),
      onError: error => dispatch({
        type: ImageUploaderActions.UPLOAD_FAIL,
        error,
      }),
      onSuccess: url => dispatch({
        type: ImageUploaderActions.UPLOAD_SUCCESS,
        version: 'cropped',
        url,
      })
    })

    const thumbnailTask = createUploadTask({
      name: thumbnailName,
      file: thumbnailBlob,
      onUpdate: progress => dispatch({
        type: ImageUploaderActions.UPLOAD_UPDATE,
        version: 'thumbnail',
        progress,
      }),
      onError: error => dispatch({
        type: ImageUploaderActions.UPLOAD_FAIL,
        error,
      }),
      onSuccess: url => dispatch({
        type: ImageUploaderActions.UPLOAD_SUCCESS,
        version: 'thumbnail',
        url,
      })
    })

    dispatch({
      type: ImageUploaderActions.START_UPLOAD,
      imageRef: newImageRef,
      originalSize: isExisting ? 0 : file.size,
      croppedSize: croppedBlob.size,
      thumbnailSize: thumbnailBlob.size,
      originalTask,
      croppedTask,
      thumbnailTask,
    })
  }

  // Convert the file input to a readable data url for cropper.js
  React.useEffect(
    () => {
      if (file) {
        const imageReader = new FileReader()

        imageReader.onload = upload => dispatch({
          type: ImageUploaderActions.LOAD_PREVIEW,
          preview: upload.target.result,
        })

        imageReader.readAsDataURL(file)
      } else {
        reset()
      }
    },
    [file],
  )

  // Handle success
  React.useEffect(
    () => {
      if ((!file || original.url) && cropped.url && thumbnail.url) {
        console.log({ imageRef })
        if (file) {
          imageRef?.update({
            originalUrl: original.url,
            croppedUrl: cropped.url,
            thumbnailUrl: thumbnail.url,
          })

          onSuccess?.({
            id: imageRef.id,
            originalUrl: original.url,
            croppedUrl: cropped.url,
            thumbnailUrl: thumbnail.url,
          })
        } else {
          imageRef?.update({
            croppedUrl: cropped.url,
            thumbnailUrl: thumbnail.url,
          })

          onSuccess?.({
            id: imageRef.id,
            croppedUrl: cropped.url,
            thumbnailUrl: thumbnail.url,
          })
        }

        reset()
      }
    },
    // eslint-disable-next-line
    [original, cropped, thumbnail, imageRef, onSuccess]
  )

  function getContent() {
    switch (status) {

      case ImageUploaderStates.READY: {
        if (imageFromProps && !isChanging) {
          return (
            <>
              <ButtonBase
                disableRipple
                disableTouchRipple
                className={classes.imageButton}
                onClick={event => setMenuAnchor(event.currentTarget)}
                style={{
                  backgroundImage: `url(${imageFromProps.url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  height: '100%',
                  width: '100%',
                }}
              >
                  <div className={classes.hoverContainer}>
                    {isAvatar
                      ? <AvatarIcon className={classes.imageIcon} />
                      : <ImageIcon className={classes.imageIcon} />
                    }
                  </div>
              </ButtonBase>

              <Menu
                open={Boolean(menuAnchor)}
                anchorEl={menuAnchor}
                onClose={() => setMenuAnchor(null)}
                anchorOrigin={{
                  horizontal: 'center',
                  vertical: 'center',
                }}
                transformOrigin={{
                  horizontal: 'center',
                  vertical: 'center',
                }}
              >
                <MenuItem
                  onClick={() => {
                    setMenuAnchor(null)
                    onRemove?.()
                  }}
                >
                  Remove
                </MenuItem>
              </Menu>
            </>
          )
        }

        return (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Dropzone
              onChange={({ files }) => dispatch({ type: ImageUploaderActions.LOAD_FILE_TO_CROP, file: files[0] })}
              isImage
              label="+ Add Image or Photo"
            />
          </div>
        )
      }

      case ImageUploaderStates.UPLOADING: {
        return (
          <>
            <img
              className={classes.image}
              alt="Upload Preview"
              src={preview}
            />
            <ImageUploaderProgressBar progress={progress} />
          </>
        )
      }

      default: {
        return (
          <div className={classes.loadingContainer}>
            <CircleLoader />
          </div>
        )
      }
    }
  }

  return (
    <div style={{ flex: 1, width: '100%', position: 'relative' }}>
      <div style={{ position: 'absolute', inset: 0}}>
        {getContent()}

        {status === ImageUploaderStates.READY && isChanging && (
          <Button
            variant="outlined"
            className={classes.cancelChangeButton}
            onClick={() => setIsChanging(false)}
          >
            Cancel
          </Button>
        )}
      </div>


      <Dialog maxWidth={size} fullWidth open={status === ImageUploaderStates.CROPPING}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {!preview ? (
              <Grid item xs={12} className={classes.loadingCropper}>
                <CircleLoader />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <ImageCropper
                  src={preview}
                  aspectRatio={aspectRatio}
                  minCropBoxWidth={minCropBoxWidth}
                  minCropBoxHeight={minCropBoxHeight}
                  viewMode={viewMode}
                  guides={guides}
                  responsive={responsive}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={reset}
          >
            Cancel
          </Button>
          <div style={{ flex: 1 }} />
          <Button
            variant="contained"
            color="primary"
            onClick={save}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  )
}

ImageUploader.propTypes = {
  /** Reference object to store the images to */
  relatedRef: PropTypes.string,
  /** Aspect ratio of the wrapping container */
  containerAspectRatio: PropTypes.number,
  /** The title of the uploader modal */
  title: PropTypes.string,
  /**
   * Callback when the upload is successful
   *
   * @param {object} image The Image Model Instance from the successful upload
   */
  onSuccess: PropTypes.func,
  size: PropTypes.string,

  /** Id of the user that own's the image. */
  ownerId: PropTypes.string,
  /** Type of the image. */
  imageType: PropTypes.oneOf(['photo', 'header', 'profile']),
  /** Existing Image Model instance. */
  image: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  }),
  /**
   * Callback when the image should be removed.
   */
  onRemove: PropTypes.func,
  /** If `true` the image is displayed as a circle. */
  isAvatar: PropTypes.bool,

  // Cropperjs Editor Options
  aspectRatio: PropTypes.number,
  minCropBoxWidth: PropTypes.number,
  minCropBoxHeight: PropTypes.number,
  viewMode: PropTypes.oneOf([0, 1, 2, 3]),
  guides: PropTypes.bool,
  responsive: PropTypes.bool,
}
