import * as React from 'react'
import { Button, Group, Menu, ScrollArea, Table, ThemeIcon, Title, UnstyledButton, useMantineTheme } from '@mantine/core'
import { firebaseFunctions, firebaseDb } from 'src/services/firebase'
import { Check, X } from 'tabler-icons-react'

const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

function formatCurrency(value) {
  return currencyFormatter.format(value)
}

function safeParse(value) {
  const parsed = Number.parseInt(value, 10)

  return Number.isFinite(parsed) ? parsed : 0
}

const STICKY_HEADER_PROPS = {
  position: 'sticky',
  zIndex: 1,
  top: 0,
  backgroundColor: 'rgb(248, 249, 250)'
}

export function Stats() {
  const theme = useMantineTheme()

  const borderProps = {
    borderLeft: `1px solid ${theme.colors.gray[2]}`,
  }

  const [statsRecords, setStatsRecords] = React.useState([])

  const today = (new Date()).toISOString().split('T')[0]

  React.useEffect(() => {
    const unsubscribe = firebaseDb
      .collection('stats')
      .limit(1000)
      .onSnapshot((snapshot) => {
        const records = snapshot.docs.map((doc) => ({
          BBID: doc.id,
          ...doc.data(),
        }))
        setStatsRecords(records)
      })

    return unsubscribe
  }, [])

  const updateStats = firebaseFunctions.httpsCallable(
    'updateStats'
  )

  const [loading, setLoading] = React.useState(false)

  async function handleUpdate() {
    
    try {
      setLoading(true)
      await updateStats()
    } finally {
      setLoading(false)
    }
  }

  const totals = React.useMemo(
    () => statsRecords.reduce((totals, record) => {
      // Add totals
      totals.count += 1
      totals.staff += safeParse(record.Stats_Staff_Active, 10)
      totals.income += safeParse(record.Stats_IncomeAmount_Last12)
      totals.invoice += safeParse(record.Stats_InvoiceAmount_Last12)

      // Add module totals
      MODULES.forEach((module) => {
        const key = `Stats_${module.value}_Last12`
        const value = safeParse(record[key]) ? 1 : 0

        if (!totals.modules[module.value]) {
          totals.modules[module.value] = 0
        }

        totals.modules[module.value] += value
      })

      const qbVersion = record.Stats_QBversion || 'None'

      if (qbVersion !== 'None') {
        totals.qb += 1
      }

      if (!totals.qbVersions[qbVersion]) {
        totals.qbVersions[qbVersion] = 0
      }

      totals.qbVersions[qbVersion] += 1

      // Add QB totals
      QUICKBOOKS.forEach((module) => {
        const key = `Stats_QB_${module.value}`
        const value = safeParse(record[key]) ? 1 : 0

        if (!totals.qbModules[module.value]) {
          totals.qbModules[module.value] = 0
        }

        totals.qbModules[module.value] += value
      })

      return totals
    }, { staff: 0, income: 0, invoice: 0, qbVersions: {}, count: 0, modules: {}, qbModules: {}, qb: 0 }),
    [statsRecords],
  )

  return (
    <>
      <Group position="apart">
        <Title>Stats</Title>

        <Button onClick={handleUpdate} loading={loading}>
          Update Stats
        </Button>
      </Group>

      <div style={{ width: 'calc(100vw - 400px)' }}>
        <ScrollArea h="calc(100vh - 200px)">
          <Table>
            <thead style={STICKY_HEADER_PROPS}>
              <tr>
                <th />
                <th>BBID</th>
                <th>Account</th>
                <th>Server</th>
                <th>User Count</th>
                <th style={borderProps}>Income</th>
                <th>Per User</th>
                <th>Billings</th>
                <th>Per User</th>

                {MODULES.map((module) => (
                  <th key={module.value}>{module.label}</th>
                ))}

                <th style={borderProps}>Quickbooks</th>

                {QUICKBOOKS.map((module) => (
                  <th key={module.value}>{module.label}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {statsRecords.map((record, index) => (
                <tr key={index}>
                  <td>
                    {record.datestamp === today ? (
                      <ThemeIcon size="xs" color="green">
                        <Check />
                      </ThemeIcon>
                    ) : (
                      <ThemeIcon size="xs" color="red">
                        <X />
                      </ThemeIcon>
                    )}
                  </td>
                  <td>{record.BBID}</td>
                  <td>{record.accountName}</td>
                  <td>{record.resourceName}</td>
                  <td>{record.Stats_Staff_Active}</td>
                  <td>{formatCurrency(record.Stats_IncomeAmount_Last12)}</td>
                  <td>{formatCurrency(safeDivide(record.Stats_IncomeAmount_Last12, record.Stats_Staff_Active))}</td>
                  <td>{formatCurrency(record.Stats_InvoiceAmount_Last12)}</td>
                  <td>{formatCurrency(safeDivide(record.Stats_InvoiceAmount_Last12, record.Stats_Staff_Active))}</td>
                  {MODULES.map((module) => (
                    <StatModule key={module.value} record={record} module={module} />
                  ))}

                  <td style={borderProps}>{record.Stats_QBversion}</td>

                  {QUICKBOOKS.map((module) => (
                    <td key={module.value}>{record[`Stats_QB_${module.value}`]}</td>
                  ))}
                </tr>
              ))}
            </tbody>

            <tfoot>
              <tr>
                <th />
                <th />
                <th />
                <th />
                <th>{totals.staff}</th>
                <th>{formatCurrency(totals.income)}</th>
                <th>{formatCurrency(safeDivide(totals.income, totals.staff))}</th>
                <th>{formatCurrency(totals.invoice)}</th>
                <th>{formatCurrency(safeDivide(totals.invoice, totals.staff))}</th>
                
                {MODULES.map((module, index) => (
                  <th key={index}>
                    {totals.modules[module.value]}
                  </th>
                ))}
                    
                <th style={borderProps}>
                  {totals.qb}
                </th>
                
                {QUICKBOOKS.map((module, index) => (
                  <th key={index}>
                    {totals.qbModules[module.value]}
                  </th>
                ))}
              </tr>
            </tfoot>
          </Table>

        </ScrollArea>

        <Table mt="xl">
          <thead>
            <tr>
              <th>
                Quickbooks Versions
              </th>
              <th>Count</th>
            </tr>
          </thead>

          <tbody>
            {Object.keys(totals.qbVersions).map((version, index) => (
              <tr key={index}>
                <td>{version || 'None'}</td>
                <td>{totals.qbVersions[version]}</td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <th>Total</th>
              <th>{totals.qb} / {Object.values(totals.qbVersions).reduce((t, v) => t + v, 0)}</th>
            </tr>
          </tfoot>
        </Table>
      </div>
    </>
  )
}

Stats.propTypes = {

}

const MODULES = [
  { value: 'Company', label: 'Company' }, 
  { value: 'Contact', label: 'Contact' }, 
  { value: 'Doc', label: 'Doc' }, 
  { value: 'Log', label: 'Log' }, 
  { value: 'Note', label: 'Note' }, 
  { value: 'Todo', label: 'Todo' }, 
  { value: 'Proposal', label: 'Proposal' }, 
  { value: 'Project', label: 'Project' }, 
  { value: 'Time', label: 'Time' }, 
  { value: 'Resource', label: 'Resource' }, 
  { value: 'Expense', label: 'Expense' }, 
  { value: 'Invoice', label: 'Invoice' }, 
  { value: 'Income', label: 'Income' }, 
  { value: 'sub', label: 'sub' }, 
  { value: 'Request', label: 'Request' }, 
  { value: 'Payout', label: 'Payout' }, 
]

const QUICKBOOKS = [
  { value: 'Expense', label: 'Expense' },
  { value: 'Income', label: 'Income' },
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Payout', label: 'Payout' },
  { value: 'Request', label: 'Request' },
  { value: 'Time', label: 'Time' },
]

function safeDivide(a, b) {
  if (b === 0) {
    return 0
  }

  return a / b
}

const RATIO_SCALES = [24 - 12, 12 - 3, 3 - 1, 1]

function StatModule({ record, module }) {
  const all = safeParse(record?.[`Stats_${module.value}_All`]) || 0
  const one_month = safeParse(record?.[`Stats_${module.value}_Last01`]) || 0
  const three_months = safeParse(record?.[`Stats_${module.value}_Last03`]) || 0
  const twelve_months = safeParse(record?.[`Stats_${module.value}_Last12`]) || 0
  const twenty_four_months = safeParse(record?.[`Stats_${module.value}_Last24`]) || 0

  const count = safeParse(record?.['Stats_Staff_Active'])

  const allRatio = Math.round(safeDivide(all - twenty_four_months, count))

  const ratios = [
    Math.round(safeDivide(twenty_four_months - twelve_months, count)),
    Math.round(safeDivide(twelve_months - three_months, count)),
    Math.round(safeDivide(three_months - one_month, count)),
    Math.round(safeDivide(one_month, count)),
  ]

  return (
    <>
      <td>
        <Menu>
          <Menu.Target>
            <UnstyledButton>
              <Group spacing={0} noWrap>
                <ThemeIcon
                  color={allRatio ? 'cyan.3' : 'gray.3'} size="xs"
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    maxWidth: 10,
                    minWidth: 10,
                  }}
                />

                {ratios.map((ratio, index) => {
                  let color = ratio > 0 ? 'lime.5' : 'gray.3'
                  const scale = RATIO_SCALES[index]

                  if (index > 0) {
                    const prevRatio = ratios[index - 1]
                    const prevScale = RATIO_SCALES[index - 1]

                    const compare = safeDivide(ratio / scale, prevRatio / prevScale)

                    switch (true) {
                      case compare > 3:
                        color = 'lime.9'
                        break

                      case compare > 2:
                        color = 'lime.7'
                        break

                      case compare > 0.50:
                        color = 'lime.3'
                        break

                      case compare > 0.25:
                        color = 'red.3'
                        break

                      default:
                        break
                    }
                  }

                  return (
                    <ThemeIcon
                      key={index}
                      color={color}
                      size="xs"
                      style={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: index < ratios.length - 1 ? 0 : 4,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: index < ratios.length - 1 ? 0 : 4,
                        maxWidth: 10,
                        minWidth: 10,
                      }}
                    />
                  )
                })}
              </Group>
            </UnstyledButton>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>{record.BBID} | {record.accountName}</Menu.Label>
            <Menu.Item>{module.label}</Menu.Item>
            <Menu.Divider />
            <Menu.Item>All: {all} / {allRatio}</Menu.Item>
            <Menu.Item>24 months: {twenty_four_months} / {ratios[0]}</Menu.Item>
            <Menu.Item>12 months: {twelve_months} / {ratios[1]}</Menu.Item>
            <Menu.Item>3 months: {three_months} / {ratios[2]}</Menu.Item>
            <Menu.Item>1 month: {one_month} / {ratios[3]}</Menu.Item>
            <Menu.Divider />
            <Menu.Item>
              Count: {count}
            </Menu.Item>

          </Menu.Dropdown>
        </Menu>
      </td>
    </>
  )
}

