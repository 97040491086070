export function filterUsers(user, filters) {
  // Test search query
  if (filters.search) {
    const pattern = new RegExp(filters.search, 'gi')

    if (!pattern.test(user.name) && !pattern.test(user.email)) {
      return false
    }
  }

  // Test access level
  if (filters.accessLevel) {
    if (user.accessLevel !== filters.accessLevel) {
      return false
    }
  }

  // Test user status ('active' === true, 'inactive' === false)
  if (filters.activeStatus) {
    const isActive = filters.activeStatus === 'active'
    if (user.isActive !== isActive) {
      return false
    }
  }

  // Test is user is in access group
  if (filters.accessGroup) {
    if (user.accessGroupId !== filters.accessGroup?.id) {
      return false
    }
  }

  // Test is user is in profit center
  if (filters.profitCenter) {
    if (user.profitCenterId !== filters.profitCenter?.id) {
      return false
    }
  }

  return true
}