import { firebaseDb } from 'src/services/firebase'
import { AccessLevelChoices, AccessGroupModel } from 'src/data-model'
import { DateTime } from 'luxon'
import { createGlobalId } from './file-maker'


export function convertAccessLevelToChoice(fmLevel) {
  switch (fmLevel) {
    case 3: {
      return AccessLevelChoices.FINANCIAL
    }

    case 2: {
      return AccessLevelChoices.PRODUCTION
    }

    default: {
      return AccessLevelChoices.LIMITED
    }
  }
}


export async function syncAccessGroups({ accountId, fmAccessGroups, fbAccessGroups }) {

  const fmAccessGroupIds = new Set(fmAccessGroups.map(ag => ag._ID_Access))

  // Find the groups still in the Firebase db that need to be removed.
  fbAccessGroups.forEach(async fbAccessGroup => {
    if (!fmAccessGroupIds.has(fbAccessGroup.id)) {
      await firebaseDb.collection(AccessGroupModel.collection).doc(fbAccessGroup.id).delete()
    }
  })

  // Iterate through the FileMaker access groups and either create, update, or
  // leave the same.
  fmAccessGroups.forEach(async fmAccessGroup => {
    const {
      AG_AccessLevel: level = 1,
      AG_Name: name = '',
      _ID_Access: fmid,
    } = fmAccessGroup

    const id = createGlobalId({ accountId, fmid })

    const fbAccessGroup = fbAccessGroups.find(fbAg => fbAg.id === id)
      || AccessGroupModel.create({ id, accountId, fmid })

    // Sync all the values from the FileMaker instance
    fbAccessGroup.name = name
    fbAccessGroup.accessLevel = convertAccessLevelToChoice(level)
    fbAccessGroup.modified = DateTime.local()

    await firebaseDb.collection(AccessGroupModel.collection).doc(id).set(
      AccessGroupModel.toDb(fbAccessGroup)
    )
  })
}