import * as React from 'react'
import GenjoAddressField from 'genjo-ui/core/AddressField'


const COMPONENTS_MAP = {
  country: ['short_name', 'country'],
  administrative_area_level_1: ['short_name', 'state'],
  locality: ['long_name', 'city'],
  postal_town: ['long_name', 'city'],
  postal_code: ['long_name', 'postalCode'],
}


/**
 * GenjoUI Address with custom ComponentsMap.
 */
export function AddressField(props) {

  return (
    <GenjoAddressField componentsMap={COMPONENTS_MAP} {...props} />
  )
}
