import * as React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'genjo-ui/core/ThemeProvider'
import { DateTime } from 'luxon'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
} from 'recharts'
import Stack from '@material-ui/core/Stack'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Cardinal } from 'src/components/Cardinal'

const UNIQUE_COLOR = colors.lightBlue[500]  
const TOTAL_COLOR = colors.purple[500]
const ANIMATION_DURATION = 1500
const TOP_BUFFER = 5


function initializeData({ start, end, units, logs }) {
  const totalUnique = new Set()
  let total = 0

  const endOrTodayMillis = Math.min(
    DateTime.local(),
    end,
  )

  const endOrToday = DateTime.fromMillis(endOrTodayMillis)

  const steps = Math.ceil(endOrToday.diff(start).as(units))

  const uniqueUsersByStep = Array.from({ length: steps }, () => new Set())
  const loginsByStep = Array.from({ length: steps }, () => 0)

  for (let log of logs) {
    const adjustedTimestamp = log.timestamp.setZone('America/Los_Angeles')

    const stepIndex = Math.floor(adjustedTimestamp.diff(start).as(units))

    uniqueUsersByStep[stepIndex]?.add(log.userId)
    totalUnique.add(log.userId)

    loginsByStep[stepIndex] += 1
    total += 1
  }

  const data = []
  const format = units === 'hours' ? 'M/d @ ha' : 'MMM d'

  for (let i = 0; i < steps; i += 1) {

    data.push({
      step: i,
      label: start.plus({ [units]: i }).toFormat(format),
      unique: uniqueUsersByStep[i].size,
      total: loginsByStep[i],
    })
  }

  return {
    data,
    totalUnique: totalUnique.size,
    total,
  }
}


export function Graph({ start, end, units, logs }) {

  const [state, setState] = React.useState({
    ...initializeData({ start, end, units, logs }),
    drag: null,
    left: 0,
    right: Math.ceil(end.diff(start).as(units)),
    top: `dataMax+${TOP_BUFFER}`
  })

  const { data, totalUnique, total, left, right, drag, top } = state

  React.useEffect(
    () => setState(s => ({
      ...s,
      ...initializeData({ start, end, units, logs }),
    })),
    [start, end, units, logs],
  )

  function zoomIn() {
    if (drag) {
      setState(s => {
        const l = s.drag[0] < s.drag[1] ? s.drag[0] : s.drag[1]
        const r = s.drag[0] < s.drag[1] ? s.drag[1] : s.drag[0]

        const t = data.slice(l, r + 1).reduce((result, datum) => {
          return Math.max(result, datum.total + TOP_BUFFER)
        }, 5)

        return {
          ...s,
          drag: null,
          data: s.data.slice(),
          left: l,
          right: r,
          top: t,
        }
      })
    }
  }

  function zoomOut() {
    setState(s => ({
      ...s,
      drag: null,
      left: 0,
      right: Math.ceil(end.diff(start).as(units)),
      top: `dataMax+${TOP_BUFFER}`,
    }))
  }

  function startDrag(event) {
    if (!event) {
      return
    }

    setState(s => ({
      ...s,
      drag: [
        event.activeTooltipIndex,
        event.activeTooltipIndex,
      ],
    }))
  }

  function onDrag(event) {
    if (drag && event) {
      setState(s => ({
        ...s,
        drag: [
          s.drag[0],
          event.activeTooltipIndex,
        ],
      }))
    }
  }

  const title = React.useMemo(
    () => {
      const s = start.toFormat('MMMM d, yyyy')
      const e = end.toFormat('MMMM d, yyyy')
      return s === e
        ? s
        : `${s} \u2014 ${e}`
    },
    [start, end],
  )

  return (
    <>
      <div
        style={{
          fontSize: 12,
        }}
      >
        <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
          <Typography variant="h4">
            {title}
          </Typography>

          {left !== 'dataMin' && right !== 'dataMax' && (
            <Button onClick={zoomOut}>Zoom Out</Button>
          )}

          <div style={{ flex: 1 }} />

          <Cardinal value={totalUnique} label="Total Unique" places={0} color={UNIQUE_COLOR} />

          <Cardinal value={total} label="Total Logins" places={0} color={TOTAL_COLOR} />
      </Stack>

        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            height={250}
            data={data}
            onMouseDown={startDrag}
            onMouseMove={onDrag}
            onMouseUp={zoomIn}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              allowDataOverflow
              dataKey="step"
              domain={[left, right]}
              type="number"
              tickFormatter={value => data[value]?.label ?? ''}
            />

            <YAxis allowDataOverflow domain={[0, top]} type="number" yAxisId="1" />

            <Tooltip
              labelFormatter={value => data[value]?.label ?? ''}
            />

            <Line
              yAxisId="1"
              type="monotone"
              dataKey="unique"
              stroke={UNIQUE_COLOR}
              isAnimationActive
              animationDuration={ANIMATION_DURATION}
              dot={false}
            />

            <Line
              yAxisId="1"
              type="monotone"
              dataKey="total"
              stroke={TOTAL_COLOR}
              animationDuration={ANIMATION_DURATION}
              isAnimationActive

              dot={false}
            />

            {Boolean(drag) && (
              <ReferenceArea
                yAxisId="1"
                x1={drag[0]}
                x2={drag[1]}
                strokeOpacity={0.3}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>


    </>
  )
}

Graph.propTypes = {
  start: PropTypes.object,
  end: PropTypes.object,
  units: PropTypes.oneOf(['hours', 'days']),
  logs: PropTypes.arrayOf(PropTypes.object),
}
