import * as React from 'react'
import { AccountContext } from './AccountContext'

export function useAccount() {
  const context = React.useContext(AccountContext)

  if (AccountContext === undefined) {
    throw new Error(`useAccount must be used with AccountProvider`)
  }

  return context
}
