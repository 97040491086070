import * as React from 'react'

import { firebaseFunctions } from 'src/services/firebase'

const getAccountStripeInfo = firebaseFunctions.httpsCallable('getAccountStripeInfo')


export function useStripeInfo({ account, enabled = true }) {

  const [stripeInfo, setStripeInfo] = React.useState(null)
  const [updateId, forceUpdate] = React.useReducer(u => u + 1, 1)

  function refreshStripeInfo() {
    forceUpdate()
  }

  React.useEffect(
    () => {
      let isActive = true

      if (!enabled) {
        return
      }

      if (account) {
        ;(async () => {
          try {
            const response = await getAccountStripeInfo(account)

            if (isActive) {
              setStripeInfo(response.data || {})
            }
          } catch (error) {
            console.log({ error })
          }
        })()
      } else {
        setStripeInfo(null)
      }

      return () => isActive = false
    },
    [account, updateId, enabled]
  )

  return { stripeInfo, refreshStripeInfo }
}
