import * as React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import DeleteIcon from '@material-ui/icons/DeleteRounded'

import { useAccount } from 'src/components/AccountProvider'
import FileMakerDataProvider, {
  useFileMakerData,
  compareAccessGroupToFileMaker,
  compareProfitCenterToFileMaker,
  compareUserToFileMaker,
} from 'src/components/FileMakerDataProvider'

import { syncAccessGroups } from 'src/services/access-groups'
import { syncProfitCenters } from 'src/services/profit-centers'

import AllGoodIcon from '@material-ui/icons/TaskAltRounded'
import UpdateIcon from '@material-ui/icons/WarningRounded'
import CreateIcon from '@material-ui/icons/ErrorRounded'
import RefreshIcon from '@material-ui/icons/RefreshRounded'
import { useAuth } from 'src/components/AuthProvider'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'
import ConfirmationDialog from 'genjo-ui/core/ConfirmationDialog'
import {
  importUsersFromFileMaker,
} from 'src/services/users'
import { firebaseDb } from 'src/services/firebase'


// These fmids are used for special guest accounts that allow the file maker
// client to check to the previous session info. This allows the users to auto
// log in when the page is refreshed. We need to ignore them when syncing the
// users.
const SPECIAL_ADMIN_IDS = [
  'BaseBuilders',
  'jacobi',
]


function StatusChip({ status }) {
  if (!status) {
    return <Chip label="Loading..." />
  }

  const label = status === 'all_good'
    ? 'All Good'
    : status === 'update'
    ? 'Needs update'
    : 'Missing Record'

  const color = status === 'all_good'
    ? 'success'
    : status === 'update'
    ? 'warning'
    : 'error'

  const icon = status === 'all_good'
    ? <AllGoodIcon />
    : status === 'update'
    ? <UpdateIcon />
    : <CreateIcon />

  return (
    <Chip label={label} color={color} icon={icon} />
  )
}


function AccountFileMakerDataInner() {
  const { user: systemUser } = useAuth()
  const loader = useLoader()
  const snackbar = useSnackbar()
  const { account, users, accessGroups, profitCenters } = useAccount()
  const { handleRefresh, data } = useFileMakerData()

  const fileMakerAccessGroupsByFMID = data?.accessGroups?.reduce((result, ag) => {
    result[ag._ID_Access] = ag
    return result
  }, {})

  const unsyncedAccessGroupsCount = data?.accessGroups?.filter(
    fmag => !accessGroups?.find(ag => ag.fmid === fmag._ID_Access)
  ).length ?? 0

  const fileMakerProfitCentersByFMID = data?.offices?.reduce((result, pc) => {
    result[pc._ID_Office] = pc
    return result
  }, {})

  const unsyncedProfitCentersCount = data?.offices?.filter(
    fmpc => !profitCenters?.find(pc => pc.fmid === fmpc._ID_Office)
  ).length ?? 0

  const fileMakerUsersByFMID = data?.staff?.reduce((result, staff) => {
    result[staff._ID_Staff] = staff
    return result
  }, {})

  const unsyncedUsersCount = data?.staff?.filter(
    fmuser => {
      // Ignore special admin users for LIVE accounts
      if (account.accountType === 'LIVE' && SPECIAL_ADMIN_IDS.includes(fmuser._ID_Staff)) {
        return false
      }

      // Try to find a matching user record. If not, we have an unsynced user.
      const user = users?.find(user => user.fmid === fmuser._ID_Staff)
      return !user
    }
  ).length ?? 0

  async function handleSyncAccessGroups() {
    try {
      loader.open('Syncing access groups...')
      await syncAccessGroups({
        accountId: account.id,
        fmAccessGroups: data.accessGroups,
        fbAccessGroups: accessGroups,
      })

      snackbar.addMessage({
        text: 'Access groups synced.',
        type: 'success',
      })
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem syncing access groups.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  const [requestDeleteProfitCenter, setRequestDeleteProfitCenter] = React.useState(null)

  async function handleDeleteProfitCenter() {
    if (!requestDeleteProfitCenter) {
      return
    }

    try {
      loader.open('Deleting profit center...')
      await firebaseDb.collection('profitCenters').doc(requestDeleteProfitCenter).delete()
      setRequestDeleteProfitCenter(null)
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem deleting profit center.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  const [requestDeleteAccessGroup, setRequestDeleteAccessGroup] = React.useState(null)

  async function handleDeleteAccessGroup() {
    if (!requestDeleteAccessGroup) {
      return
    }

    try {
      loader.open('Deleting access group...')
      await firebaseDb.collection('accessGroups').doc(requestDeleteAccessGroup).delete()
      setRequestDeleteAccessGroup(null)
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem deleting access group.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  async function handleSyncProfitCenters() {
    try {
      loader.open('Syncing profit centers...')
      await syncProfitCenters({
        accountId: account.id,
        fmProfitCenters: data?.offices,
        fbProfitCenters: profitCenters,
      })

      snackbar.addMessage({
        text: 'Profit centers synced.',
        type: 'success',
      })
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem syncing profit centers.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  async function handleImportUsers() {
    // const updateFileMakerUser = firebaseFunctions.httpsCallable('updateFileMakerUser')

    try {
      loader.open('Importing users...')

      await importUsersFromFileMaker({
        account,
        fmUsers: data.staff,
        fbUsers: users,
        accessGroups,
        profitCenters,
      })

      // Loop through each user and update their file-maker data.
      // const updateUserPromises = fbUsers.map(fbUser => updateFileMakerUser({ userId: fbUser.id }))
      // await Promise.all(updateUserPromises)

      snackbar.addMessage({
        text: 'Users synced.',
        type: 'success',
      })
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem syncing users.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">
            Manage Application Data
          </Typography>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            onClick={handleRefresh}
            startIcon={<RefreshIcon />}
          >
            Refresh from FileMaker
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Users</Typography>
              <List>
              {unsyncedUsersCount > 0 && (
                  <ListItem>
                    <ListItemIcon>
                      <CreateIcon color="error" style={{ fontSize: 24 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={`There ${
                        unsyncedUsersCount === 1 ? 'is' : 'are'
                      } ${
                        unsyncedUsersCount
                      } unsynced user record${
                        unsyncedUsersCount === 1 ? '' : 's'
                      }.`}
                    />

                    {systemUser.isAdmin && (
                      <Button
                        variant="contained"
                        onClick={handleImportUsers}
                      >
                        Import Users from File Maker
                      </Button>
                    )}
                  </ListItem>
                )}

                {users?.map(user => (
                  <ListItem key={user.id}>
                    <ListItemText
                      primary={user.name}
                      secondary={user.accessLevel}
                    />

                    <StatusChip
                      status={Boolean(data) && compareUserToFileMaker(
                        user,
                        fileMakerUsersByFMID?.[user.fmid],
                      )}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
                      </Grid>

        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Access Groups</Typography>
              <List>
                {unsyncedAccessGroupsCount > 0 && (
                  <ListItem>
                    <ListItemIcon>
                      <CreateIcon color="error" style={{ fontSize: 24 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={`There ${
                        unsyncedAccessGroupsCount === 1 ? 'is' : 'are'
                      } ${
                        unsyncedAccessGroupsCount
                      } unsynced access group record${
                        unsyncedAccessGroupsCount === 1 ? '' : 's'
                      }.`}
                    />

                    <Button
                      variant="contained"
                      onClick={handleSyncAccessGroups}
                    >
                      Sync
                    </Button>
                  </ListItem>
                )}
                {accessGroups?.map(ag => {
                  const status = Boolean(data) && compareAccessGroupToFileMaker(
                    ag,
                    fileMakerAccessGroupsByFMID?.[ag.fmid],
                  )

                  return (
                    <ListItem key={ag.id}>
                      <ListItemText
                        primary={ag.name}
                        secondary={ag.accessLevel}
                      />

                      <StatusChip status={status} />

                      {!['all_good', 'update'].includes(status) && (
                        <IconButton onClick={() => setRequestDeleteAccessGroup(ag.id)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  )
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Profit Centers</Typography>
              <List>
              {unsyncedProfitCentersCount > 0 && (
                  <ListItem>
                    <ListItemIcon>
                      <CreateIcon color="error" style={{ fontSize: 24 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={`There ${
                        unsyncedProfitCentersCount === 1 ? 'is' : 'are'
                      } ${
                        unsyncedProfitCentersCount
                      } unsynced profit center record${
                        unsyncedProfitCentersCount === 1 ? '' : 's'
                      }.`}
                    />

                    <Button
                      variant="contained"
                      onClick={handleSyncProfitCenters}
                    >
                      Sync
                    </Button>
                  </ListItem>
                )}
                {profitCenters?.map(pc => {
                  const status = Boolean(data) && compareProfitCenterToFileMaker(
                    pc,
                    fileMakerProfitCentersByFMID?.[pc.fmid],
                  )

                  return (
                    <ListItem key={pc.id}>
                      <ListItemText
                        primary={pc.name}
                      />

                      <StatusChip status={status} />

                      {!['all_good', 'update'].includes(status) && (
                        <IconButton onClick={() => setRequestDeleteProfitCenter(pc.id)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>
                  )
                })}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ConfirmationDialog
        isOpen={Boolean(requestDeleteProfitCenter)}
        onClose={() => setRequestDeleteProfitCenter(null)}
        onConfirm={handleDeleteProfitCenter}
        title="Delete profit center?"
      />

      <ConfirmationDialog
        isOpen={Boolean(requestDeleteAccessGroup)}
        onClose={() => setRequestDeleteAccessGroup(null)}
        onConfirm={handleDeleteAccessGroup}
        title="Delete access group?"
        message="This will permanently delete this access group. After deleting make sure all your users are updated to existing access groups."
      />

    </>
  )
}


export function AccountFileMakerData() {
  return (
    <FileMakerDataProvider>
      <AccountFileMakerDataInner />
    </FileMakerDataProvider>
  )
}
