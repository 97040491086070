import * as React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import BackIcon from '@material-ui/icons/ArrowBackRounded'
import LinkBehavior from 'src/components/LinkBehavior'


export function BackButton({ to = "/", ...buttonProps }) {

  return (
    <Button
      {...buttonProps}
      component={LinkBehavior}
      to={to}
      startIcon={<BackIcon />}
    />
  )
}

BackButton.propTypes = {
  /** Href url link for the button. */
  to: PropTypes.string,
}
