import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Logo from 'src/components/Logo'

const useStyles = makeStyles({
  wrapper: {
    // height: '100%',
    // width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

/**
 * Full width/height to display when loading page content
 */
const LoadingContainer = () => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Logo width={300} isLoading />
      </div>
    </div>
  )
}

export { LoadingContainer }
