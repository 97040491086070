import { DateTime } from 'luxon'

import {
  dateFieldFactory,
  modelFactory,
  numberFieldFactory,
  stringFieldFactory,
  submodelFieldFactory,
  booleanFieldFactory,
  dateTimeFieldFactory,
} from 'pebble-orm'

import {
  createTimestampFields,
  createAddressFields,
} from './mixins'

import {
  AccountTypeChoices,
  SubscriptionTypeChoices,
  BillingIntervalChoices,
  DisciplineChoices,
} from './choices'

import { ImageRefModel } from './images'


export const AccountModel = modelFactory({
  name: 'AccountModel',
  collection: 'accounts',
  fields: [
    ...createTimestampFields(),

    stringFieldFactory({
      name: 'name',
      required: true,
    }),

    stringFieldFactory({
      name: 'email',
      required: true,
    }),

    stringFieldFactory({
      name: 'phoneNumber',
    }),

    ...createAddressFields(),

    stringFieldFactory({
      name: 'BBID',
    }),

    stringFieldFactory({
      name: 'discipline',
      choices: DisciplineChoices.getChoices(),
      defaultValue: DisciplineChoices.OTHER,
    }),

    stringFieldFactory({
      name: 'signUpInfo',
    }),

    stringFieldFactory({
      name: 'ownerId',
    }),

    stringFieldFactory({
      name: 'resourceId',
    }),

    submodelFieldFactory({
      name: 'logo',
      model: ImageRefModel,
    }),

    stringFieldFactory({
      name: 'databaseFileName',
    }),

    stringFieldFactory({
      name: 'interfaceFileName',
    }),

    // Stripe
    // -- Pricing
    stringFieldFactory({
      name: 'financialUserPriceId',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'productionUserPriceId',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'limitedUserPriceId',
      defaultValue: '',
    }),

    // -- Quantities
    numberFieldFactory({
      name: 'financialUsersLicenseCount',
      defaultValue: 1,
    }),

    numberFieldFactory({
      name: 'productionUsersLicenseCount',
      defaultValue: 0,
    }),

    numberFieldFactory({
      name: 'limitedUsersLicenseCount',
      defaultValue: 0,
    }),

    // -- Billing
    stringFieldFactory({
      name: 'stripeCustomerId',
    }),

    stringFieldFactory({
      name: 'stripeSubscriptionId',
    }),

    stringFieldFactory({
      name: 'stripeSubscriptionStatus',
      defaultValue: 'none',
    }),

    dateTimeFieldFactory({
      name: 'stripeCurrentPeriodStart',
      defaultValue: null,
    }),

    dateTimeFieldFactory({
      name: 'stripeCurrentPeriodEnd',
      defaultValue: null,
    }),

    dateTimeFieldFactory({
      name: 'stripeBillingCycleAnchor',
      defaultValue: null,
    }),

    stringFieldFactory({
      name: 'stripePaymentMethodId',
    }),

    booleanFieldFactory({
      name: 'isOldSchool',
      defaultValue: false,
    }),

    stringFieldFactory({
      name: 'activationCode',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: '_DEBUG_loggedBillingDate',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'accountType',
      choices: AccountTypeChoices.getChoices(),
      defaultValue: AccountTypeChoices.LIVE,
    }),

    stringFieldFactory({
      name: 'subscriptionType',
      choices: SubscriptionTypeChoices.getChoices(),
      defaultValue: SubscriptionTypeChoices.AUTOMATIC,
    }),

    stringFieldFactory({
      name: 'billingInterval',
      choices: BillingIntervalChoices.getChoices(),
      defaultValue: BillingIntervalChoices.MONTHLY,
    }),

    dateFieldFactory({
      name: 'billingStart',
      defaultValue: () => DateTime.local(),
    }),

    stringFieldFactory({
      name: 'invoicePoNumber',
    }),

    numberFieldFactory({
      name: 'activeUserCount',
      defaultValue: 0,
    }),

    numberFieldFactory({
      name: 'activeFinancialUserCount',
      defaultValue: 0,
    }),

    numberFieldFactory({
      name: 'activeProductionUserCount',
      defaultValue: 0,
    }),

    numberFieldFactory({
      name: 'activeLimitedUserCount',
      defaultValue: 0,
    }),

    stringFieldFactory({
      name: 'billingData',
      defaultValue: '',
    }),
  ],
})
