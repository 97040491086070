import * as React from 'react'

import { UserModel } from 'src/data-model'
import useModelList from 'src/components/useModelList'
import { AccountCard } from './AccountCard'
import { useAuth } from 'src/components/AuthProvider'

import { useDocumentTitle } from '@mantine/hooks'

import LinkBehavior from 'src/components/LinkBehavior'

import {
  Divider,
  Container,
  Text,
  Image,
  Button,
} from '@mantine/core'


export const Dashboard = () => {
  const { user, logout } = useAuth()

  useDocumentTitle('Base Builders | Accounts Admin')

  const { results: users = [] } = useModelList({
    model: UserModel,
    filter: query => query.where('authUserId', '==', user.id),
    filterKey: user.id,
  })


  const activeUsers = React.useMemo(
    () => users?.filter(u => u.isActive),
    [users],
  )

  return (
    <Container size="xs" my="lg">
      <Image
        mb="md"
        src={`${process.env.PUBLIC_URL}/img/logo/logo_horizontal.svg`}
        alt="Base Builders logo"
      />

      <Text size="xl" mb="md">
        {`Welcome${user.name ? `, ${user.name}` : ''}`}
      </Text>

      <Text color="dimmed" size="lg">
        Please select an account...
      </Text>

      {activeUsers.map(user => (
        <AccountCard key={user.id} user={user} />
      ))}

      {activeUsers.length === 0 && (
        <Text>
          You do not have any active user accounts.
        </Text>
      )}

      <Divider label="OR" labelPosition="center" />

      <Button onClick={logout} mt="xl" fullWidth color="indigo">
        Logout
      </Button>

      {user.isAdmin && (
        <Button
          mt="md"
          color="red"
          component={LinkBehavior}
          to="/admin"
          fullWidth
        >
          Administrator Tools
        </Button>
      )}
    </Container>
  )
}
