import { DateTime } from 'luxon'


export function humanize(timestamp) {
  const delta = DateTime.now().diff(timestamp)

  const unit = ['years', 'months', 'weeks', 'days', 'hours'].find(
    unit => Math.floor(Math.abs(delta.as(unit)))
  ) || 'minutes'

  const value = Math.abs(Math.round(delta.as(unit)))

  const displayUnits = value === 1 ? unit.slice(0, -1) : unit

  if (!value) {
    return 'just now'
  }

  return `${value} ${displayUnits} ago`
}