import {
  modelFactory,
  stringFieldFactory,
  numberFieldFactory,
} from 'pebble-orm'

import {
  createTimestampFields,
} from './mixins'


import { ServerStatusChoices } from './choices'



export const ResourceModel = modelFactory({
  name: 'ResourceModel',
  collection: 'resources',
  fields: [
    ...createTimestampFields(),

    stringFieldFactory({
      name: 'name',
      defaultValue: '',
    }),

    /**
     * Denormalized value: the number of accounts hosted on this resource.
     * Should be controlled by services/accounts
     */
    numberFieldFactory({
      name: 'numAccounts',
      defaultValue: 0,
    }),

    stringFieldFactory({
      name: 'status',
      defaultValue: ServerStatusChoices.ACTIVE,
      choices: ServerStatusChoices.getChoices(),
    }),

    stringFieldFactory({
      name: 'domain',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'ip',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'region',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'notes',
      defaultValue: '',
    }),
  ],
})
