import { firebaseStorage } from 'src/services/firebase'


export function createUploadTask({ name, file, onUpdate, onError, onSuccess }) {
  const uploadTask = firebaseStorage.child(name).put(file)

  uploadTask.on(
    'state_changed',
    // Update callback
    snapshot => {
      onUpdate(snapshot.bytesTransferred)
    },

    // Error callback
    error => {
      onError(error)
    },

    // Success callback
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
        onSuccess(downloadUrl)
      })
    }
  )

  return uploadTask
}
