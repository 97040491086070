import * as React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { UserModel } from 'src/data-model'
import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'

import { firebaseFunctions, firebaseDb } from 'src/services/firebase'
import { useAccount } from 'src/components/AccountProvider'



export function ActivateUserDialog({ userId, isOpen, onClose }) {
  const snackbar = useSnackbar()
  const loader = useLoader()

  const { users, accessGroups, licensesAvailable } = useAccount()
  const user = users?.find(u => u.id === userId)

  const [accessGroupId, setAccessGroupId] = React.useState(null)
  // Make sure the field is reset on open/close
  React.useEffect(
    () => {
      setAccessGroupId(null)
    },
    [isOpen]
  )

  const accessLevel = accessGroups.find(ag => ag.id === accessGroupId)?.accessLevel || 'NONE'

  async function handleActivate() {
    try {
      loader.open('Activating user...')

      const ref = firebaseDb.collection(UserModel.collection).doc(user.id)
      await ref.update({
        isActive: true,
        accessLevel,
        accessGroupId,
      })

      const updateFileMakerUser = firebaseFunctions.httpsCallable('updateFileMakerUser')
      const updateFileMakerResponse = await updateFileMakerUser({ userId: user.id })

      if ( updateFileMakerResponse.data.status !== 'success') {
        console.log({ updateFileMakerResponse })
        throw new Error(updateFileMakerResponse?.error ?? 'Something went wrong syncing with FileMaker')
      }

      const updateAuthUser = firebaseFunctions.httpsCallable('updateAuthUser')
      const updateAuthUserResponse = await updateAuthUser({ userId: user.id })

      if (updateAuthUserResponse.data.status !== 'success') {
        console.log({ updateAuthUserResponse })
        throw new Error(updateAuthUserResponse?.error ?? 'Something went creating auth user')
      }

      snackbar.addMessage({
        text: 'User updated',
        type: 'success',
      })

      onClose()
    } catch (error) {
      console.log({ error })
      const ref = firebaseDb.collection(UserModel.collection).doc(user.id)
      await ref.update({
        isActive: false,
      })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem updating user.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }


  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      style={{ minHeight: 400 }}
    >
      <ClosableDialogTitle onClose={onClose}>Activate User</ClosableDialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                label="Access Group"
                select
                value={accessGroupId || ''}
                onChange={event => setAccessGroupId(event.target.value)}
                SelectProps={{
                  displayEmpty: true,
                }}
                helperText="If an access group is unavailable, you can add licenses to your account subscription or deactivate any users with that access level."
              >
                <MenuItem disabled value="">
                  Please select an available access group...
                </MenuItem>
                {accessGroups?.map((accessGroup, index) => (
                  <MenuItem
                    key={accessGroup.id}
                    value={accessGroup.id}
                    disabled={licensesAvailable[accessGroup.accessLevel] <= 0}
                    divider={accessGroups[index + 1]?.accessLevel !== accessGroup.accessLevel}
                  >
                    {`${accessGroup.name} (${accessGroup.accessLevel} - ${
                      licensesAvailable[accessGroup.accessLevel]
                    } available)`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleActivate}
          disabled={!accessGroupId || accessLevel === 'NONE'}
          variant="contained"
        >
          Activate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ActivateUserDialog.propTypes = {
  userId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
