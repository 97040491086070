import * as React from 'react'
import { Button } from '@mantine/core'
import { firebaseFunctions } from 'src/services/firebase'

export function BillingDataButton() {
  const [loading, setLoading] = React.useState(false)
  const getBillingData = firebaseFunctions.httpsCallable(
    'getBillingData'
  )

  async function handleClick() {
    try {
      setLoading(true)
      const response = await getBillingData()
      console.log(response)
    } catch (err) {
      console.log({ err })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      onClick={handleClick}
      loading={loading}
    >
      Get Billing Data
    </Button>
  )
}
