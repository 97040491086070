import * as React from 'react'
import { useAccount } from 'src/components/AccountProvider'


import ActivityLayout from 'src/layouts/ActivityLayout'


export function AdminAccountActivity() {
  const { account } = useAccount()

  return (
    <ActivityLayout account={account} />
  )
}
