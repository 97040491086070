import * as React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { useCurrency } from 'src/utils/currency'
import { useSignUp } from './useSignUp'
import { calculateBillingValues } from './utils'


export function PlanSummary() {
  const { values, userPrices, onboardingPrice } = useSignUp()
  const currency = useCurrency()

  const {
    financialPrice,
    financialSubtotal,
    productionPrice,
    productionSubtotal,
    limitedPrice,
    limitedSubtotal,
    subscriptionSubtotal,
    total,
  } = calculateBillingValues({ values, userPrices, onboardingPrice })

  return (
    <Card>
      <CardContent>
        <Typography variant="h3">
          Plan Summary
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Products
              </TableCell>

              <TableCell align="right">
                Quantity
              </TableCell>

              <TableCell align="right">
                Price
              </TableCell>

              <TableCell align="right">
                Subtotal
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>
                Financial Users
              </TableCell>

              <TableCell align="right">
                {values.financialUsersLicenseCount}
              </TableCell>

              <TableCell align="right">
                {`${currency(financialPrice / 100)} / ${
                  values.billingInterval === 'YEARLY' ? 'year' : 'month'
                }`}
              </TableCell>

              <TableCell align="right">
                {currency(financialSubtotal / 100)}
              </TableCell>
            </TableRow>

            {values.productionUsersLicenseCount > 0 && (
              <TableRow>
                <TableCell>
                  Production Users
                </TableCell>

                <TableCell align="right">
                  {values.productionUsersLicenseCount}
                </TableCell>

                <TableCell align="right">
                  {`${currency(productionPrice / 100)} / ${
                  values.billingInterval === 'YEARLY' ? 'year' : 'month'
                }`}
                </TableCell>

                <TableCell align="right">
                  {currency(productionSubtotal / 100)}
                </TableCell>
              </TableRow>
            )}

            {values.limitedUsersLicenseCount > 0 && (
              <TableRow>
                <TableCell>
                  Limited Users
                </TableCell>

                <TableCell align="right">
                  {values.limitedUsersLicenseCount}
                </TableCell>

                <TableCell align="right">
                  {`${currency(limitedPrice / 100)} / ${
                  values.billingInterval === 'YEARLY' ? 'year' : 'month'
                }`}
                </TableCell>

                <TableCell align="right">
                  {currency(limitedSubtotal / 100)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell colSpan={2} />

              <TableCell>
                {`Subscription Subtotal (billed ${
                  values.billingInterval === 'YEARLY'
                     ? 'yearly'
                     : 'monthly'
                })`}
              </TableCell>

              <TableCell align="right">
                {currency(subscriptionSubtotal / 100)}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={2} />

              <TableCell>
                Personalized Onboarding (one-time)
              </TableCell>

              <TableCell align="right">
                {currency(onboardingPrice / 100)}
              </TableCell>
            </TableRow>

          <TableRow>
              <TableCell colSpan={2} />

              <TableCell sx={{ fontWeight: 700, fontSize: 18 }}>
                Today's Total
              </TableCell>

              <TableCell align="right" sx={{ fontWeight: 700, fontSize: 18 }}>
                {currency(total / 100)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>

    </Card>
  )
}
