import React from 'react'

import {
  useLocation,
  useNavigate,
} from 'react-router-dom'


// When the redirect encounters these paths we want to skip using the next
// param. Example: if `next=/logout` then as soon as the user logs in the page
// will imediately redirect to the logout.
const IGNORE_PATHS = ['/', '/logout', '/activate']


export const AuthRedirect = () => {
  const location = useLocation()
  const navigate = useNavigate()

  React.useEffect(
    () => {
      if (location && navigate) {
        let nextParam = ''

        if (!IGNORE_PATHS.includes(location.pathname)) {
          nextParam = `?next=${location.pathname}`
        }

        navigate(`/${nextParam}`)
      }
    },
    [location, navigate]
  )
  return ''
}

export default AuthRedirect
