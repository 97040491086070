import * as React from 'react'

import SearchField from 'genjo-ui/core/SearchField'
import { useAccount } from 'src/components/AccountProvider'

export function SearchAccountUsersField() {
  const { filters, setFilterValue } = useAccount()

  return (
    <SearchField
      value={filters.search}
      onChange={value => setFilterValue('search', value)}
      placeholder="Find a user..."
    />
  )
}
