import * as React from 'react'
import PropTypes from 'prop-types'

import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { useMap } from './useMap'
import { createMarker } from './utils'


function createMarkerFromChild({ child, google, map }) {
  const {
    position,
    icon,
    label,
    title,
    draggable,
    infoWindow,
    ...listeners
  } = child.props

  const marker = createMarker({
    google,
    map,
    position,
    icon,
    label,
    title,
    draggable,
    infoWindow,
    listeners,
  })

  return marker
}


export function Clusterer({ children = [] }) {
  const { map, google } = useMap()
  const clustererRef = React.useRef(null)
  const markersRef = React.useRef(null)

  React.useEffect(
    () => {
      if (!map || !google || !children?.length) {
        return
      }

      const markers = children
        ?.filter?.(child => !child.props.shouldHide)
        ?.map?.(child => createMarkerFromChild({ child, google, map }))

      if (clustererRef.current) {
        clustererRef.current.removeMarkers(markersRef.current)
        clustererRef.current.addMarkers(markers)
      } else {
        clustererRef.current = new MarkerClusterer({
          markers,
          map,
        })
      }

      markersRef.current = markers
    },
    [children, google, clustererRef, map]
  )

  return null
}

Clusterer.propTypes = {
  children: PropTypes.node,
}
