import * as React from 'react'
// import PropTypes from 'prop-types'

import { useLocation, useParams } from 'react-router-dom'


export function MailTo() {
  const { search } = useLocation()
  const { email } = useParams()

  React.useEffect(
    () => {
      window.location.href = `mailto:${email}${search}`
    },
    [email, search]
  )

  return (
    ''
  )
}
