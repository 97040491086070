import React from 'react'
import {
  Routes,
  Route,
} from 'react-router-dom'

import { useAuth, AuthStatus } from 'src/components/AuthProvider'
import UsersProvider from 'src/components/UsersProvider'
import LoadingContainer from 'src/components/LoadingContainer'

// - Utilities, redirects, and layouts
import AppLauncher from './AppLauncher'
import AuthRedirect from './utils/AuthRedirect'
import Logout from './utils/Logout'
import { MailTo } from './MailTo'

// Main Pages
import { Dashboard } from './Dashboard'
import { Account } from './Account'
import { AccountFileMakerData } from './AccountFileMakerData'
import { AccountUsers } from './AccountUsers'
import { AccountUser } from './AccountUser'
import AccountOverview from './AccountOverview'

// (Super) Admin
import { Admin } from './Admin'
import { AdminAccounts } from './AdminAccounts'
import { AdminResources } from './AdminResources'
import { AdminAccountDetails } from './AdminAccountDetails'
import { AdminResource } from './AdminResource'
import { AdminStripe } from './AdminStripe'
import { SystemUsers } from './SystemUsers'
import { SystemUser } from './SystemUser'
import CashFlow from './CashFlow'
import AdminAccountActivity from './AdminAccountActivity'
import AdminActivity from './AdminActivity'
import AdminMap from './AdminMap'
import { Stats } from './Stats'

// - Pages
import ActivateAccount from './ActivateAccount'
import SignUp from './SignUp'

const Login = React.lazy(() => import('./Login'))


const PagesInner = () => {
  const { authStatus } = useAuth()

  if (authStatus === AuthStatus.INITIALIZING) {
    return <LoadingContainer />
  }

  if (authStatus === AuthStatus.IS_AUTHENTICATED) {
    return (
      <UsersProvider>
        <AppLauncher>
          <React.Suspense fallback={<LoadingContainer/>}>
            <Routes>
              <Route path="accounts/:accountId" element={<Account />}>
                <Route path="" element={<AccountOverview />} />
                <Route path="users" element={<AccountUsers />} />
                <Route path="users/:userId" element={<AccountUser />} />
                <Route path="file-maker" element={<AccountFileMakerData />} />
                <Route path="admin-activity" element={<AdminAccountActivity />} />
                <Route path="admin-details" element={<AdminAccountDetails />} />
              </Route>

              <Route path="admin" element={<Admin />}>
                <Route path="" element={<AdminAccounts />} />
                <Route path="system-users" element={<SystemUsers />} />
                <Route path="system-users/:systemUserId" element={<SystemUser />} />
                <Route path="resources" element={<AdminResources />} />
                <Route path="resources/:resourceId" element={<AdminResource />} />
                <Route path="stripe" element={<AdminStripe />} />
                <Route path="activity" element={<AdminActivity />} />
                <Route path="charts" element={<CashFlow />} />
                <Route path="stats" element={<Stats />} />
              </Route>
              <Route path="admin/map" element={<AdminMap />} />
              <Route path="*" element={<Dashboard />} />
            </Routes>

          </React.Suspense>
        </AppLauncher>
      </UsersProvider>
    )
    // return (
    //   <UsersProvider>
    //     <Layout>
    //       <AppLauncher>
    //         <React.Suspense fallback={<LoadingContainer/>}>
    //           <Routes>
    //             <Route path="accounts/:accountId" element={<Account />}>
    //               <Route path="/" element={<AccountOverview />} />
    //               <Route path="/users/:userId" element={<AccountUser />} />
    //               <Route path="/file-maker" element={<AccountFileMakerData />} />
    //               <Route path="/users" element={<AccountUsers />} />

    //               {/* Account Admin Pages */}
    //               <Route path="/admin-details" element={<AdminAccountDetails />} />
    //               <Route path="/admin-activity" element={<AdminAccountActivity />} />
    //             </Route>

    //             <Route path="admin/accounts" element={<AdminAccounts />} />
    //             <Route path="admin/resources" element={<AdminResources />} />
    //             <Route path="admin/resources/:resourceId" element={<AdminResource />} />
    //             <Route path="admin/stripe" element={<AdminStripe />} />
    //             <Route path="admin/system-users" element={<SystemUsers />} />
    //             <Route path="admin/system-users/:systemUserId" element={<SystemUser />} />
    //             <Route path="admin/settings" element={<AdminSettings />} />
    //             <Route path="admin/charts" element={<CashFlow />} />
    //             <Route path="admin/activity" element={<AdminActivity />} />
    //             <Route path="admin/map" element={<AdminMap />} />

    //             <Route path="*" element={<Dashboard />} />
    //           </Routes>
    //         </React.Suspense>
    //       </AppLauncher>
    //     </Layout>
    //   </UsersProvider>
    // )
  }

  // authStatus === 'anonymous'
  return (
    <React.Suspense fallback={<LoadingContainer/>}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<AuthRedirect />} />
      </Routes>
    </React.Suspense>
  )
}


export function Pages() {
  return (
    <Routes>
      <Route path="logout" element={<Logout />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="/activate/:token" element={<ActivateAccount />} />
      <Route path="mailto/:email" element={<MailTo />} />
      <Route path="*" element={<PagesInner />} />
    </Routes>
  )
}

export default Pages
