import React from 'react'
import { firebaseDb } from 'src/services/firebase'


function useModelInstanceReducer(state, action) {
  switch (action.type) {
    case 'clear': {
      return {
        status: 'idle',
        instance: null,
        errors: null,
      }
    }

    case 'load': {
      return {
        status: 'loading',
        instance: null,
        errors: null,
      }
    }

    case 'success': {
      return {
        status: 'success',
        instance: action.instance,
        errors: null,
      }
    }

    case 'notFound': {
      return {
        status: 'notFound',
        instance: null,
        errors: null,
      }
    }

    case 'fail': {
      return {
        status: 'error',
        instance: null,
        errors: action.errors,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}


export function useModelInstance({ model, id }) {
  const [state, dispatch] = React.useReducer(
    useModelInstanceReducer,
    { instance: null, errors: null, status: 'idle' },
  )

  React.useEffect(
    () => {
      if (!id) {
        dispatch({ type: 'clear' })
      }

      dispatch({ type: 'load' })

      try {
        const ref = firebaseDb.collection(model.collection).doc(id)

        const unsubscribe = ref.onSnapshot(doc => {
          if (!doc.exists) {
            return dispatch({ type: 'notFound' })
          }

          const instance = model.fromDb(doc.id, doc.data())
          return dispatch({ type: 'success', instance })
        })

        return () => unsubscribe()
      } catch (errors) {
        // TODO: handle error codes from firebase
        dispatch({ type: 'fail', errors })
      }
    },
    // eslint-disable-next-line
    [id]
  )

  return state
}
