import * as React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'

import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'

import { firebaseFunctions } from 'src/services/firebase'
import { CopyButton } from './CopyButton'



export function ActivationLinkDialog({ userId, isOpen, onClose }) {
  const snackbar = useSnackbar()
  const [link, setLink] = React.useState('')
  const [error, setError] = React.useState('')

  React.useEffect(
    () => {
      let isMounted = true

      if (isOpen && !link && !error) {
        (async () => {
          const getActivationLink = firebaseFunctions.httpsCallable('getActivationLink')

          const response = await getActivationLink({ userId })

          if (response?.data?.status === 'success') {
            const newLink = response?.data?.url
            if (isMounted) {
              setLink(newLink)
              setError('')
            }
          } else {
            const error = response?.data?.error || 'Something went wrong'
            if (isMounted) {
              snackbar.error(response?.data?.error)
              setLink('')
              setError(error)
            }
          }

        })()
      }

      return () => isMounted = false
    },
    [isOpen, link, snackbar, userId, error]
  )

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      style={{ minHeight: 400 }}
    >
      <ClosableDialogTitle onClose={onClose}>Activation Link</ClosableDialogTitle>

      <DialogContent>
        <Typography style={{ marginBottom: 16 }}>
          This link will be valid for 30 days. You can copy and paste this link and send it via email or text to the employee that needs to create their account.
        </Typography>

        <Typography variant="h6" color={error ? 'error' : 'textPrimary'} style={{ marginBottom: 16 }}>
          {link || error || 'Loading...'}
        </Typography>

        {Boolean(link) && (
          <CopyButton variant="contained" value={link}>
            Copy Link
          </CopyButton>
        )}
      </DialogContent>
    </Dialog>
  )
}

ActivationLinkDialog.propTypes = {
  userId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
