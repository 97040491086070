import React from 'react'
import { useAuth } from 'src/components/AuthProvider'
import { useNavigate } from 'react-router-dom'


export const Logout = () => {
  const navigate = useNavigate()
  const { logout } = useAuth()

  React.useEffect(
    () => {
      logout()
      navigate('/')
    },
    [logout, navigate]
  )

  return ''
}

export default Logout
