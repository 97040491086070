import {
  modelFactory,
  stringFieldFactory,
  booleanFieldFactory,
} from 'pebble-orm'

import { createTimestampFields } from './mixins'


// id = `${accountId}__${fmid}
export const ProfitCenterModel = modelFactory({
  name: 'ProfitCenterModel',
  collection: 'profitCenters',
  fields: [
    ...createTimestampFields(),

    stringFieldFactory({
      name: 'accountId',
    }),

    stringFieldFactory({
      name: 'fmid',
    }),

    stringFieldFactory({
      name: 'name',
      defaultValue: '',
    }),

    booleanFieldFactory({
      name: 'isActive',
      defaultValue: true,
    }),
  ],
})
