import * as React from 'react'
import { AuthContext } from './AuthContext'


export function useAuth() {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used with the AuthContext')
  }

  return context
}
