import * as React from 'react'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/RefreshRounded'

import { CopyButton } from '../AccountUser/CopyButton'

import { firebaseFunctions } from 'src/services/firebase'


export function ActivationCode({ account }) {
  const [response, setResponse] = React.useState(null)

  async function getActivationCode(BBID) {
    const testActivationCodes = firebaseFunctions.httpsCallable('testActivationCodes')
    const response = await testActivationCodes({ BBID })
    setResponse(response.data)
  }

  async function refresh() {
    const refreshSubscriptionInfo = firebaseFunctions.httpsCallable('refreshSubscriptionInfo')
    const response = await refreshSubscriptionInfo({ accountId: account.id })
    setResponse(response.data)
  }

  React.useEffect(
    () => {
      if (!account.BBID) {
        return
      }

      ;(async () => {
        await getActivationCode(account.BBID)
      })()
    },
    [account]
  )

  const codeMatchesAccount = React.useMemo(
    () => {
      console.log({ response })
      if (!account || !response?.record?.fieldData) {
        return true
      }

      const codeData = response.record.fieldData

      const billingDate = account.stripeCurrentPeriodEnd
        ?.minus({ days: account.billingInterval === 'YEARLY' ? 365 : 31 })
        ?.toFormat('MM/dd/yyyy')

      return billingDate === codeData.ActivationDateStart
        && account.financialUsersLicenseCount === codeData.ActivationFinancial
        && account.productionUsersLicenseCount === codeData.ActivationStandard
        && account.limitedUsersLicenseCount === codeData.ActivationLimited
    },
    [account, response]
  )

  return (
    <Card>
      <CardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Activation Code</Typography>
          {Boolean(account.BBID) && (
            <Button
              startIcon={<RefreshIcon />}
              variant="contained"
              onClick={async () => {
                setResponse(null)
                await refresh()
              }}
            >
              Refresh
            </Button>
          )}
        </Stack>

        <Typography variant="h6" sx={{ my: 2 }}>
          {!response?.status
            ? 'Loading...'
            : response?.status === 'success'
            ? response?.record?.fieldData?.ClientActivation
            : response?.message || 'There was a problem getting activation code.'
          }
        </Typography>

        {!codeMatchesAccount && (
          <Typography color="error.main">
            This activation code does not match your current account configuration. The code may still be syncing with your Filemaker database. Please wait a few moments and try clicking the refresh button. If the problem persists please contact us.
          </Typography>
        )}

        {Boolean(response?.record?.fieldData?.ClientActivation) && (
          <CopyButton
            value={response?.record?.fieldData?.ClientActivation}
          >
            Copy Activation Code
          </CopyButton>
        )}
      </CardContent>
    </Card>
  )
}

ActivationCode.propTypes = {
  account: PropTypes.object,
}
