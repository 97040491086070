import * as React from 'react'
import PropTypes from 'prop-types'

import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'

import { AccessLevelChoices } from 'src/data-model'
import { createCurrencyFormatter } from 'src/utils/currency'

const OLD_SCHOOL_LABELS = {
  FINANCIAL: 'Super User',
  PRODUCTION: 'Standard User',
  LIMITED: 'Limited User',
}


export function UserCountBar({ accessLevel, licenseCount = 0, userCount = 0, price = 0, isOldSchool }) {

  const label = isOldSchool
    ? OLD_SCHOOL_LABELS[accessLevel]
    : AccessLevelChoices.getLabel(accessLevel)

  const currencyFormatter = createCurrencyFormatter()

  const color = userCount > licenseCount
    ? 'error'
    : userCount < licenseCount
    ? 'warning'
    : 'success'

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="subtitle2">
          {isOldSchool
            ? `${licenseCount} ${label}${licenseCount === 1 ? '' : 's'}`
            : `${userCount} of ${licenseCount} ${label} Users`
          }
        </Typography>

        <Typography color="text.secondary">
          {`${
            currencyFormatter.format(price / 100)
          } per user for ${
            currencyFormatter.format(price * licenseCount / 100)
          }`}
        </Typography>
      </Stack>

      <LinearProgress
        sx={{ height: 16, borderRadius: 8 }}
        color={isOldSchool ? 'success' : color}
        variant="determinate"
        value={isOldSchool ? 100 : 100 * userCount / licenseCount}
      />
    </>
  )
}

UserCountBar.propTypes = {
  accessLevel: PropTypes.oneOf(AccessLevelChoices.getChoices()),
}
