import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

let firebaseConfig
try {
  firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
} catch (e) {
  console.log(process.env.REACT_APP_FIREBASE_CONFIG)
  console.log({ e })
}

// Initialize firebase
// Firebase + NextJS boilerplate example can be found here:
// https://github.com/vercel/next.js/tree/canary/examples/with-firebase-authentication
try {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  }
} catch (err) {
  console.log(`Firebase initialization error: ${err}`)
}

const firebaseApp = firebase
const firebaseAuth = firebaseApp.auth()
const firebaseDb = firebaseApp.firestore()
const firebaseFunctions = firebaseApp.functions()
const firebaseStorage = firebaseApp.storage().ref()
const GeoPoint = firebaseApp.firestore.GeoPoint
const TimeStamp = firebaseApp.firestore.Timestamp


// Connect the functions emulator
// https://firebase.google.com/docs/functions/local-emulator#web-v9
if (process.env.NODE_ENV === 'development') {
  firebaseFunctions.useEmulator('localhost', 5001)
}


export {
  firebaseApp,
  firebaseDb,
  firebaseFunctions,
  firebaseStorage,
  GeoPoint,
  TimeStamp,
  firebaseAuth,
}
