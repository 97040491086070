export function compareUserToFileMaker(user, fileMakerRecord) {
  if (!user) {
    return 'create_firebase'
  }

  if (!fileMakerRecord) {
    return 'create_file_maker'
  }

  const [, profitCenterFmid = ''] = user.profitCenterId?.split('__')
  const [, accessGroupFmid = ''] = user.accessGroupId?.split('__')

  const conditions = [
    user.name !== fileMakerRecord.StaffNamePreferred,  // name
    user.isActive !== Boolean(fileMakerRecord._KF_Active),  // isActive
    profitCenterFmid !== (fileMakerRecord._ID_Office_f === 'undefined' ? '' : fileMakerRecord._ID_Office_f),  // profit center
    accessGroupFmid !== (fileMakerRecord._ID_Access_f === 'undefined' ? '' : fileMakerRecord._ID_Access_f),  // access group
  ]

  const shouldUpdate = conditions.some(condition => condition)

  if (shouldUpdate) {
    return 'update'
  }

  return 'all_good'
}
