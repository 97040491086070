import * as React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Stack from '@material-ui/core/Stack'
import Typography from '@material-ui/core/Typography'
import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'
import SimpleTabs from 'genjo-ui/core/SimpleTabs'
import SimpleTab from 'genjo-ui/core/SimpleTab'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, Elements, CardElement } from '@stripe/react-stripe-js'

import CheckIcon from '@material-ui/icons/CheckRounded'
import StripeCardField from 'src/components/StripeCardField'
import { firebaseFunctions } from 'src/services/firebase'

import { usePlaid } from './usePlaid'


const testStripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLIC_KEY)
const liveStripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PUBLIC_KEY)



function AddPaymentMethodInner({ account, onClose, onSuccess }) {
  const { accountType, stripeCustomerId } = account || {}
  const elements = useElements()
  const stripe = useStripe()

  const loader = useLoader()
  const snackbar = useSnackbar()

  const [type, setType] = React.useState('ach')

  const [ccInfo, setCcInfo] = React.useState(null)
  const [cardHolderName, setCardHolderName] = React.useState('')

  function handleTypeChange(event, newType) {
    setType(newType === 'ach' ? 'ach' : 'cc')
  }

  const { status: plaidStatus, getPlaidLink, plaidInfo, reset: resetPlaid } = usePlaid({
    account
  })

  const isReady = React.useMemo(
    () => {
      const achIsComplete = plaidStatus === 'loaded'

      const creditCardInfoIsComplete = Boolean(ccInfo?.cardInfo?.complete)
        && !ccInfo?.cardInfo?.error
        && Boolean(cardHolderName)

      return (type === 'ach' && achIsComplete)
        || (type === 'cc' && creditCardInfoIsComplete)
    },
    [plaidStatus, ccInfo, cardHolderName, type]
  )

  const [, setError] = React.useState(null)

  async function handleAdd() {
    try {
      loader.open('Saving payment method...')

      let paymentMethodId

      if (type === 'cc') {
        const paymentResult = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            name: cardHolderName,
          },
        })

        if (!paymentResult || paymentResult.error) {

          snackbar.error(paymentResult.error?.message ?? 'Something went wrong.')
          return setError(paymentResult.error?.message ?? 'Something went wrong.')
        }

        paymentMethodId = paymentResult.paymentMethod.id
      }

      const addPaymentMethod = firebaseFunctions.httpsCallable('addPaymentMethod')

      const response = await addPaymentMethod({
        accountType,
        customerId: stripeCustomerId,
        paymentMethodId,
        plaidInfo: type === 'ach' ? plaidInfo : null,
      })

      if (response?.data?.status !== 'success') {
        snackbar.error(response?.data?.message || 'Something went wrong.')
        return setError(response?.data?.message)
      }

      snackbar.success('Payment method added.')
      onSuccess?.()
    } catch (err) {
      console.log({ err })
      snackbar.error(err?.message || 'Something went wrong')
      return setError(err)
    } finally {
      loader.close()
    }
  }

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xs"
    >
      <ClosableDialogTitle onClose={onClose}>Add Payment Method</ClosableDialogTitle>

      <DialogContent>
        <SimpleTabs value={type} onChange={handleTypeChange}>
          <SimpleTab value="ach" label="ACH / Checking Account" />
          <SimpleTab value="cc" label="Credit Card" />
        </SimpleTabs>

        <Grid container spacing={2} sx={type !== 'cc' ? { position: 'absolute', top: -9999, left: -9999, opacity: 0 } : {}}>
          <Grid item xs={12}>
            <TextField
              required
              value={cardHolderName}
              onChange={event => setCardHolderName(event.target.value)}
              label="Card Holder Name"
            />
          </Grid>
          <Grid item xs={12}>
            <StripeCardField
              onChange={cardInfo => setCcInfo({
                ...ccInfo,
                cardInfo,
              })}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={type !== 'ach' ? { position: 'absolute', top: -9999, left: -9999, opacity: 0 } : {}}>
          <Grid item xs={12}>
            {plaidStatus === 'idle' ? (
              <Button
                variant="contained"
                onClick={getPlaidLink}
              >
                Setup ACH Account
              </Button>
            ) : plaidStatus === 'loading' ? (
              <Typography>Loading...</Typography>
            ) : (
              <>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <CheckIcon color="success" />
                  <div>
                    <Typography variant="h6">
                      Your <strong>{`${plaidInfo?.metadata?.institution?.name}`}</strong> account has been successfully selected.
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                      <em>{`${plaidInfo?.metadata?.account?.name ?? ''} (...${plaidInfo?.metadata?.account?.mask ?? ''})`}</em>
                    </Typography>
                  </div>

                  <Button
                    variant="contained"
                    onClick={resetPlaid}
                  >
                    Clear
                  </Button>
                </Stack>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleAdd}
          disabled={!isReady}
        >
          Add Payment Method
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export function AddPaymentMethod({ account, onClose, onSuccess }) {
  const { accountType } = account

  const stripePromise = React.useMemo(
    () => accountType === 'LIVE' ? liveStripePromise : testStripePromise,
    [accountType]
  )

  return (
    <Elements stripe={stripePromise}>
      <AddPaymentMethodInner account={account} onClose={onClose} onSuccess={onSuccess} />
    </Elements>
  )
}


AddPaymentMethod.propTypes = {
  account: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}
