import React from 'react'
import PropTypes from 'prop-types'
import AdapterLuxon from '@material-ui/lab/AdapterLuxon'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'

// Providers
import AuthProvider from 'src/components/AuthProvider'

import ThemeProvider from 'genjo-ui/core/ThemeProvider'
import LoaderProvider from 'genjo-ui/core/LoaderProvider'
import GoogleMapsProvider from 'genjo-ui/core/GoogleMapsProvider'
import DeviceProvider from 'genjo-ui/core/DeviceProvider'
import SnackbarProvider from 'genjo-ui/core/SnackbarProvider'
import { colors } from 'genjo-ui/core/ThemeProvider'

import { MantineProvider, ColorSchemeProvider } from '@mantine/core'
// import { NotificationsProvider } from '@mantine/notifications'
// import { ModalsProvider } from '@mantine/modals'


/**
 * Wrapper to combine all of the app providers.
 */
export const RootProvider = ({ children }) => {

  const [colorScheme, setColorScheme] = React.useState('light')

  function toggleColorScheme(value) {
    setColorScheme(value || (colorScheme === 'light' ? 'light' : 'dark'))
  }

  return (
    <DeviceProvider>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          withNormalizeCSS
          withGlobalStyles
          theme={{
            colorScheme,
            headings: {
              fontFamily: 'Montserrat, sans-serif',
            }
          }}
        >
          <ThemeProvider
            mode="light"
            primary={{
              ...colors.blue,
              main: colors.blue[700],
              dark: colors.blue[900],
            }}
            secondary={{
              ...colors.amber,
              main: colors.amber[700],
              dark: colors.amber[900],
            }}
          >
            <GoogleMapsProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <SnackbarProvider>
                  <LoaderProvider>
                    <AuthProvider>
                      {children}
                    </AuthProvider>
                  </LoaderProvider>
                </SnackbarProvider>
              </LocalizationProvider>
            </GoogleMapsProvider>
          </ThemeProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </DeviceProvider>
  )
}

RootProvider.propTypes = {
  /** Wrapped content of the provider */
  children: PropTypes.node,

}
