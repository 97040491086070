import * as React from 'react'

import { useAuth } from 'src/components/AuthProvider'

import {
  Box,
  UnstyledButton,
  Group,
  Avatar,
  Text,
  Menu,
} from '@mantine/core'

import { ChevronRight, Logout } from 'tabler-icons-react'


export function UserMenu() {
  const { user, logout } = useAuth()

  return (
    <Menu position="right">
      <Menu.Target>
        <Box
          sx={theme => ({
            paddingTop: theme.spacing.sm,
            borderTop: `1px solid ${
              theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
            }`,
          })}
        >
          <UnstyledButton
            sx={theme => ({
              display: 'block',
              width: '100%',
              padding: theme.spacing.xs,
              borderRadius: theme.radius.sm,
              color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

              '&:hover': {
                backgroundColor:
                  theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
              },
            })}
          >
            <Group>
              <Avatar
                src={user.photoUrl}
                radius="xl"
              >
                {(user?.name?.slice(0, 1) || '').toUpperCase()}
              </Avatar>
              <Box sx={{ flex: 1 }}>
                <Text size="sm" weight={500}>
                  {user.name}
                </Text>
                <Text color="dimmed" size="xs">
                  {user.email}
                </Text>
              </Box>

              <ChevronRight size={18} />
            </Group>
          </UnstyledButton>
        </Box>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          onClick={logout}
          icon={<Logout size={14} />}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
