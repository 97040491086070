import * as React from 'react'

import LinkBehavior from 'src/components/LinkBehavior'

import MenuPanelItem from 'genjo-ui/core/MenuPanelItem'

import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'

import BackIcon from '@material-ui/icons/ArrowBackRounded'


export function MapMenu({ children }) {

  const accountsRoute = '/admin'

  return (
    <List>
      <MenuPanelItem
        icon={<BackIcon />}
        title="Back to accounts"
        component={LinkBehavior}
        to={accountsRoute}
      />

      <ListItem>
        <Typography variant="h6">
          Account Map
        </Typography>
      </ListItem>

      {children}
    </List>
  )
}
