import * as React from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'
import { firebaseFunctions } from 'src/services/firebase'


export function usePlaid({ account }) {
  const loader = useLoader()
  const snackbar = useSnackbar()

  const [plaidInfo, setPlaidInfo] = React.useState(null)
  const [plaidToken, setPlaidToken] = React.useState(null)

  const config = {
    onSuccess: (public_token, metadata) => {
      setPlaidInfo({ public_token, metadata })
      console.log({ public_token, metadata })
    },

    onExit: (err, metadata) => {
      console.log({ err, metadata })
      setPlaidToken(null)
      setPlaidInfo(null)
    },

    onEvent: (eventName, metadata) => {},

    token: plaidToken,

  }

  const { open, ready } = usePlaidLink(config)

  React.useEffect(
    () => {
      if (ready && plaidToken && !plaidInfo) {
        open()
      }
    },
    [plaidToken, plaidInfo, ready, open]
  )

  // Ug, this is so hacky. The plaid element exiting (or maybe MUI) is turning
  // off the scrollbars but then forgetting to turn them back on.
  React.useEffect(
    () => {
      document.body.style.overflow = 'unset'
    },
    [
      plaidInfo,
      setPlaidInfo,
      plaidToken,
      setPlaidToken,
    ]
  )

  async function getPlaidLink() {
    const requestPlaidLink = firebaseFunctions.httpsCallable('requestPlaidLink')

    try {
      loader.open('Requesting ACH verification link...')
      const response = await requestPlaidLink({
        clientUserId: account.id,
        email: account.email,
        name: account.name,
        accountType: account.accountType,
      })

      if (response?.data?.status !== 'success') {
        throw new Error('Something went wrong.')
      }

      const { token } = response.data
      loader.close()
      setPlaidToken(token)
    } catch (error) {
      console.log({ error })
      snackbar.error(error?.message ?? error ?? 'Something went wrong requesting link.')
    } finally {
      loader.close()
    }
  }

  function reset() {
    setPlaidInfo(null)
    setPlaidToken(null)
  }

  const status = !plaidToken
    ? 'idle'
    : plaidInfo
    ? 'loaded'
    : 'loading'


  return { status, getPlaidLink, reset, plaidInfo }
}
