import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import Card from '@material-ui/core/Card'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Stack from '@material-ui/core/Stack'
import Chip from '@material-ui/core/Chip'

import ActiveIcon from '@material-ui/icons/TaskAltRounded'
import InactiveIcon from '@material-ui/icons/CloseRounded'

import TableResultsLoader from 'genjo-ui/core/TableResultsLoader'
import Spacer from 'genjo-ui/core/Spacer'

import { useAccount } from 'src/components/AccountProvider'
import AccountUsersFilters from 'src/components/AccountUsersFilters'
import SearchAccountUsersField from 'src/components/SearchAccountUsersField'
import { AccessLevelChoices } from 'src/data-model'
import { LicenseCounter } from './LicenseCounter'

import { AddUser } from './AddUser'

import { useNavigate } from 'react-router-dom'


export function AccountUsers() {
  const {
    status,
    userLicenseCounts,
    filteredUsers,
    accessGroups,
    profitCenters,
    account,
  } = useAccount()

  const navigate = useNavigate()

  const [isAdding, setIsAdding] = React.useState(false)

  const accessGroupNames = React.useMemo(
    () => accessGroups.reduce((result, ag) => {
      result[ag.id] = `${ag.name} (${AccessLevelChoices.getLabel(ag.accessLevel)})`
      return result
    }, {}),
    [accessGroups]
  )

  const profitCenterNames = React.useMemo(
    () => profitCenters.reduce((result, pc) => {
      result[pc.id] = pc.name
      return result
    }, {}),
    [profitCenters]
  )

  if (!account || status !== 'success') {
    return 'Loading...'
  }

  const {
    limitedUsersLicenseCount,
    productionUsersLicenseCount,
    financialUsersLicenseCount,
  } = account

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h1">
              Manage Users
            </Typography>
          </Grid>

          <Grid item style={{ flex: 1 }} />

          {!account.isOldSchool && (
            <Grid item>
              <SearchAccountUsersField />
            </Grid>
          )}

          {(!account.isOldSchool || userLicenseCounts.FINANCIAL === 0) && (
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setIsAdding(true)}
              >
                + Add User
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      {!account.isOldSchool && (
        <>
          <Grid item xs={4}>
            <LicenseCounter
              accessLevel="FINANCIAL"
              countUsed={userLicenseCounts.FINANCIAL}
              countTotal={financialUsersLicenseCount}
            />
          </Grid>

          <Grid item xs={4}>
            <LicenseCounter
              accessLevel="PRODUCTION"
              countUsed={userLicenseCounts.PRODUCTION}
              countTotal={productionUsersLicenseCount}
            />
          </Grid>

          <Grid item xs={4}>
            <LicenseCounter
              accessLevel="LIMITED"
              countUsed={userLicenseCounts.LIMITED}
              countTotal={limitedUsersLicenseCount}
            />
          </Grid>

          <Grid item xs={12}>
            <Spacer axis="vertical" size={16} />
            <AccountUsersFilters />
          </Grid>
        </>
      )}


      <Grid item xs={12}>
        <Card style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>

                {!account.isOldSchool && (
                  <>
                    <TableCell>Access Group</TableCell>
                    <TableCell>Profit Center</TableCell>
                  </>
                )}
                <TableCell>Active</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredUsers.map(user => (
                <TableRow
                  key={user.id}
                  hover
                  onClick={() => navigate(`/accounts/${account.id}/users/${user.id}`)}
                >
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography>
                        {user.name}
                      </Typography>

                      {user.isAccountAdmin && <Chip label="Admin" color="secondary" />}
                    </Stack>
                  </TableCell>
                  <TableCell>{user.email}</TableCell>

                  {!account.isOldSchool && (
                    <>
                      <TableCell>{accessGroupNames[user.accessGroupId] ?? 'None'}</TableCell>
                      <TableCell>{profitCenterNames[user.profitCenterId] ?? 'None'}</TableCell>
                    </>
                  )}
                  <TableCell>
                    {user.isActive
                      ? <ActiveIcon color="success" style={{ fontSize: 24 }} />
                      : <InactiveIcon color="error" style={{ fontSize: 24 }} />
                    }
                  </TableCell>
                </TableRow>
              ))}

              <TableResultsLoader
                isLoading={status === 'loading'}
                count={filteredUsers.length}
                colSpan={4}
              />

            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell colSpan={5} align="right">
                  {`Showing ${filteredUsers.length} user${filteredUsers.length === 1 ? '' : 's'}`}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Card>
      </Grid>

      {isAdding && (
        <AddUser onClose={() => setIsAdding(false)} />
      )}
    </Grid>
  )
}
