import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import AddressField from 'src/components/AddressField'
import { useSignUp } from './useSignUp'

import { DisciplineChoices } from 'src/data-model'


export function InfoStep() {
  const {
    values,
    setFieldValue,
    setValues,
    touched,
    setFieldTouched,
  } = useSignUp()

  function requiredFieldMixin(field) {
    const hasError = !values[field] && touched.has(field)

    return {
      onBlur: () => setFieldTouched(field),
      error: hasError,
      helperText: hasError ? 'Required' : '',
    }
  }

  function handleGeocoderResults(results) {
    setValues({
      ...values,
      country: results?.components?.country ?? '',
      state: results?.components?.state ?? '',
      city: results?.components?.city ?? '',
      postalCode: results?.components?.postalCode ?? '',
    })
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h2">
            Company Information
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Company Name"
                required
                autoComplete="organization"
                value={values.companyName}
                onChange={event => setFieldValue('companyName', event.target.value)}
                {...requiredFieldMixin('companyName')}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Company Discipline"
                value={values.discipline}
                onChange={event => setFieldValue('discipline', event.target.value)}
                select
                SelectProps={{
                  displayEmpty: true,
                }}
                {...requiredFieldMixin('discipline')}
              >
                <MenuItem value="" disabled>Please select a discipline...</MenuItem>

                {DisciplineChoices.getChoices().map(choice => (
                  <MenuItem key={choice} value={choice}>
                    {DisciplineChoices.getLabel(choice)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Account Owner Full Name"
                required
                autoComplete="name"
                value={values.fullName}
                onChange={event => setFieldValue('fullName', event.target.value)}
                {...requiredFieldMixin('fullName')}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Email"
                type="email"
                required
                autoComplete="organization email"
                value={values.email}
                onChange={event => setFieldValue('email', event.target.value)}
                {...requiredFieldMixin('email')}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Phone Number"
                required
                type="tel"
                autoComplete="organization tel"
                value={values.phoneNumber}
                onChange={event => setFieldValue('phoneNumber', event.target.value)}
                {...requiredFieldMixin('phoneNumber')}
              />
            </Grid>

            <Grid item xs={12}>
              <AddressField
                required
                value={values.address}
                autoComplete="street-address address-level2 address-level1 postal-code"
                onInputChange={event => setFieldValue('address', event.target.value)}
                onGeocoderSuccess={handleGeocoderResults}
                label="Address"
                {...requiredFieldMixin('address')}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

    </>
  )
}

InfoStep.propTypes = {

}
