import * as React from 'react'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { AccountTypeChoices } from 'src/data-model'


export function AccountTypeField(props) {

  return (
    <TextField
      select
      {...props}
    >
      {AccountTypeChoices.getChoices().map(choice => (
        <MenuItem key={choice} value={choice}>
          {AccountTypeChoices.getLabel(choice)}
        </MenuItem>
      ))}
    </TextField>
  )
}
