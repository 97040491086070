import * as React from 'react'

import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/CheckRounded'
import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Checkbox from 'genjo-ui/core/Checkbox'

import {
  usePlaidLink,
} from 'react-plaid-link';

import { useSignUp } from './useSignUp'


export function ACH() {
  const {
    plaidInfo,
    setPlaidInfo,
    plaidToken,
    getPlaidLink,
    setPlaidToken,
    values,
    paymentTermsAccepted,
    setPaymentTermsAccepted,
  } = useSignUp()

  const config = {
    onSuccess: (public_token, metadata) => {
      setPlaidInfo({ public_token, metadata })
      console.log({ public_token, metadata })
      document.body.style.overflow = 'unset'
    },
    onExit: (err, metadata) => {
      console.log({ err, metadata })
      setPlaidToken(null)
      setPlaidInfo(null)
    },
    onEvent: (eventName, metadata) => {},
    token: plaidToken,
    //required for OAuth; if not using OAuth, set to null or omit:
    // receivedRedirectUri: window.location.href,
  }

  const { open, ready } = usePlaidLink(config)

  React.useEffect(
    () => {
      if (ready && plaidToken && !plaidInfo) {
        open()
      }
    },
    [plaidToken, plaidInfo, ready, open]
  )

  // Ug, this is so hacky. The plaid element exiting (or maybe MUI) is turning
  // off the scrollbars but then forgetting to turn them back on.
  React.useEffect(
    () => {
      document.body.style.overflow = 'unset'
    },
    [
      plaidInfo,
      setPlaidInfo,
      plaidToken,
      getPlaidLink,
      setPlaidToken,
      values,
      paymentTermsAccepted,
      setPaymentTermsAccepted,
    ]
  )

  return !plaidToken ? (
    <Button
      variant="contained"
      onClick={getPlaidLink}
    >
      Setup ACH Account
    </Button>
  ) : !plaidInfo ? (
    <Typography>Loading...</Typography>
  ) : (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <CheckIcon color="success" />
        <div>
          <Typography variant="h6">
            Your <strong>{`${plaidInfo?.metadata?.institution?.name}`}</strong> account has been setup.
          </Typography>
          <Typography variant="h6" color="text.secondary">
            <em>{`${plaidInfo?.metadata?.account?.name ?? ''} (...${plaidInfo?.metadata?.account?.mask ?? ''})`}</em>
          </Typography>
        </div>

        <Button
          variant="contained"
          onClick={() => {
            setPlaidInfo(null)
            setPlaidToken(null)
          }}
        >
          Clear
        </Button>
      </Stack>

      <FormControlLabel
        sx={{ mt: 2 }}
        control={
          <Checkbox
            checked={paymentTermsAccepted}
            onChange={event => setPaymentTermsAccepted(event.target.checked)}
          />
        }
        label={`I (${values.fullName} on behalf of ${values.companyName}) authorize Base Builders LLC to charge this bank account as described in the summary above.`}
      />
    </>
  )
}
