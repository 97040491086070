import {
  modelFactory,
  stringFieldFactory,
} from 'pebble-orm'

import { createTimestampFields } from './mixins'

import { AccessLevelChoices } from './choices'


// Id = accountId__fmid
export const AccessGroupModel = modelFactory({
  name: 'AccessGroupModel',
  collection: 'accessGroups',
  fields: [
    ...createTimestampFields(),

    stringFieldFactory({
      name: 'accountId',
    }),

    stringFieldFactory({
      name: 'fmid',
    }),

    stringFieldFactory({
      name: 'name',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'accessLevel',
      choices: AccessLevelChoices.getChoices(),
      defaultValue: AccessLevelChoices.LIMITED,
    }),
  ],
})
