import * as React from 'react'


export function createCurrencyFormatter(options = {}) {
  const { language = 'en-US', currency = 'usd' } = options

  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
  })
}


export function useCurrency(options = {}) {
  const formatter = React.useMemo(
    () => createCurrencyFormatter(options),
    [options],
  )

  return formatter.format
}
