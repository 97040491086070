import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useSignUp } from './useSignUp'

export function Buttons() {
  const {
    handleNext,
    handlePrev,
    completeSteps,
    step,
    activateAccount,
    error,
  } = useSignUp()

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Button onClick={handlePrev}>Back</Button>
      </Grid>

      <Grid item>
        {step < 2 ? (
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={!completeSteps[step]}
          >
            Next
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={activateAccount}
            disabled={!completeSteps[step]}
            color={error ? 'error' : 'primary'}
          >
            {error ? 'Retry Create Account' : 'Create Account'}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}
