export function extractUserFromToken(token) {

  return {
    id: token.user_id ?? '',
    photoUrl: token.picture ?? '',
    email: token.email ?? '',
    emailVerified: Boolean(token.email_verified),
    phoneNumber: token.phone_number ?? '',
    disabled: false,

    // Note that the display name is not available from an idToken!
    name: '',

    // Custom claims
    isAdmin: Boolean(token?.isAdmin),
    termsAcceptedOn: token?.termsAcceptedOn
    // phoneNumberIsVerified: Boolean(token?.phoneNumberIsVerified),
  }
}

export function extractUserFromRecord(record) {
  return {
    id: record.uid,
    photoUrl: record.photoURL ?? '',
    email: record.email ?? '',
    name: record.displayName ?? '',
    emailVerified: record.emailVerified,
    phoneNumber: record.phoneNumber ?? '',
    disabled: Boolean(record.disabled),

    // Custom claims
    isAdmin: Boolean(record.customClaims?.isAdmin),
    // phoneNumberIsVerified: Boolean(record.customClaims?.phoneNumberIsVerified),
  }
}


