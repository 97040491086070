import React from 'react'
import isEqual from 'react-fast-compare'


export function useInstanceValues({ model, instance, fields }) {
  const [values, setValues] = React.useState(null)
  const [originalValues, setOriginalValues] = React.useState('')
  const isDirty = !isEqual(JSON.stringify(values), originalValues)

  function setFieldValue(field, value) {
    setValues({
      ...values,
      [field]: value,
    })
  }

  function resetValues() {
    setValues(JSON.parse(originalValues))
  }

  React.useEffect(
    () => {
      if (!instance) {
        setValues(null)
        setOriginalValues('')
        return
      }

      const newValues = model.extractFields(instance, fields)
      setOriginalValues(JSON.stringify(newValues))
      setValues(newValues)
    },
    [instance, fields, model]
  )

  return {
    values,
    setValues,
    setFieldValue,
    isDirty,
    resetValues,
  }
}
