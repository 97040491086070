import * as React from 'react'

import useModelList from 'src/components/useModelList'
import { ResourceModel } from 'src/data-model'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'
import Button from '@material-ui/core/Button'

import TableResultsLoader from 'genjo-ui/core/TableResultsLoader'

// import SortableTableHeadCell from 'genjo-ui/core/SortableTableHeadCell'
import { CreateResourceDialog } from './CreateResourceDialog'
import { useNavigate } from 'react-router-dom'


export function AdminResources() {
  const navigate = useNavigate()

  const { status, results: resources = [] } = useModelList({
    model: ResourceModel,
  })

  const [isCreating, setIsCreating] = React.useState(false)

  function openCreateDialog() {
    setIsCreating(true)
  }

  function closeCreateDialog() {
    setIsCreating(false)
  }

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">Resources</Typography>
        <Button
          variant="contained"
          onClick={openCreateDialog}
        >
          + Add Resource
        </Button>
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Active Accounts</TableCell>
            <TableCell>Domain</TableCell>
            <TableCell>IP</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Region</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {resources.map(resource => (
            <TableRow
              key={resource.id}
              hover
              onClick={() => navigate(`/admin/resources/${resource.id}`)}
            >
              <TableCell>{resource.name}</TableCell>
              <TableCell>{0}</TableCell>
              <TableCell>{resource.domain}</TableCell>
              <TableCell>{resource.ip}</TableCell>
              <TableCell>{resource.provider}</TableCell>
              <TableCell>{resource.region}</TableCell>
              <TableCell>{resource.status}</TableCell>
            </TableRow>
          ))}

          <TableResultsLoader
            isLoading={status === 'loading'}
            count={resources.length}
            colSpan={8}
          />
        </TableBody>

        {Boolean(resources.length) && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={8} align="right">
                {`Showing ${resources.length} resource${resources.length === 1 ? '' : 's'}`}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>

      <CreateResourceDialog
        isOpen={isCreating}
        onClose={closeCreateDialog}
      />
    </>
  )
}
