import * as React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import useInstanceValues from 'src/components/useInstanceValues'
import SaveButtonsDialog from 'genjo-ui/core/SaveButtonsDialog'
import ConfirmationDialog from 'genjo-ui/core/ConfirmationDialog'
import Checkbox from 'genjo-ui/core/Checkbox'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'

import ResourceField from 'src/components/ResourceField'

import { useAccount } from 'src/components/AccountProvider'

import {
  AccountModel,
} from 'src/data-model'
import { firebaseDb, firebaseFunctions } from 'src/services/firebase'


const _ALLOWED_FIELDS = [
  // Details
  'name', 'resourceId', 'databaseFileName', 'interfaceFileName', 'BBID',
  'isOldSchool',
]


export function AdminAccountDetails() {
  const { account, users } = useAccount()
  const loader = useLoader()
  const snackbar = useSnackbar()

  const [emailsError, setEmailsError] = React.useState('')

  const [requestSendEmails, setRequestSendEmails] = React.useState(false)

  const { values, setFieldValue, isDirty, resetValues } = useInstanceValues({
    model: AccountModel,
    instance: account,
    fields: _ALLOWED_FIELDS,
  })

  function handleChange(event) {
    setFieldValue(event.target.name, event.target.value)
  }

  async function handleSave() {
    try {
      loader.open('Saving account details...')

      await firebaseDb
        .collection(AccountModel.collection)
        .doc(account.id)
        .update(AccountModel.toDb(values))

      snackbar.success('Account details updated.')
    } catch (error) {
      console.log({ error })
      snackbar.error(error?.message ?? 'There was a problem saving account.')
    } finally {
      loader.close()
    }
  }

  async function handleSendActivationEmails() {
    const failedEmails = []

    const sendActivationEmail = firebaseFunctions.httpsCallable('sendActivationEmail')

    try {
      loader.open('Sending emails...')

      console.log(`Sending emails to ${users.filter(u => u.isActive).length} out of ${users.length}`)
      for (let user of users.filter(u => u.isActive)) {
        try {
          if (user.authUserId) {
            await sendActivationEmail({ userId: user.authUserId })
          } else {
            failedEmails.push(`\t${user.name} (${user.email})`)
          }
        } catch (error) {
          console.log(error)
          failedEmails.push(`\t${user.name} (${user.email})`)
        }
      }
    } catch (error) {
      console.log({ error })
      snackbar.error(error?.message ?? 'There was a problem sending emails')
    } finally {
      setEmailsError(`${failedEmails.length} cound not be sent:\n\t${failedEmails.join('\n')}`)
      setRequestSendEmails(false)
      loader.close()
    }
  }

  if (!values) {
    return 'Loading...'
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Account Details</Typography>


            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="BBID"
                  value={values.BBID}
                  name="BBID"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Database File Name"
                  value={values.databaseFileName}
                  name="databaseFileName"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Interface File Name"
                  value={values.interfaceFileName}
                  name="interfaceFileName"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <ResourceField
                  label="Server Resource"
                  value={values.resourceId}
                  name="resourceId"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isOldSchool}
                      onChange={event => setFieldValue('isOldSchool', event.target.checked)}
                    />
                  }
                  label="Old School"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Stripe Links
        </Typography>

        <Link
          href={`https://dashboard.stripe.com/${
            account.accountType === 'DEMO' ? 'test/' : ''
          }customers/${
            account.stripeCustomerId
          }`}
          target="_blank"
        >
          Go to Stripe Customer Page
        </Link>

        <br />

        <Link
          href={`https://dashboard.stripe.com/${
            account.accountType === 'DEMO' ? 'test/' : ''
          }subscriptions/${
            account.stripeSubscriptionId
          }`}
          target="_blank"
        >
          Go to Stripe Subscription Page
        </Link>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => setRequestSendEmails(true)}
        >
          Send Activation Emails
        </Button>

        {Boolean(emailsError) && (
          <Typography color="error" whiteSpace="pre">
            {emailsError}
          </Typography>
        )}
      </Grid>

      <SaveButtonsDialog
        isIn={isDirty}
        onCancel={resetValues}
        onSave={handleSave}
      />

      <ConfirmationDialog
        isOpen={requestSendEmails}
        onClose={() => setRequestSendEmails(false)}
        onConfirm={handleSendActivationEmails}
        title="Send Activation Emails?"
        message="This will send an email to every active user in the account."
      />

    </Grid>
  )
}
