import * as React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import DialogContent from '@material-ui/core/DialogContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

import { useLoader } from 'genjo-ui/core/LoaderProvider'
import Checkbox from 'genjo-ui/core/Checkbox'
import { firebaseFunctions } from 'src/services/firebase'
import { useAuth } from 'src/components/AuthProvider'


export function Terms() {
  const loader = useLoader()

  const { user: systemUser, reload } = useAuth()

  const [termsChecked, setTermsChecked] = React.useState(false)
  const [isSavingTerms, setIsSavingTerms] = React.useState(false)

  async function acceptTerms() {
    setIsSavingTerms(true)

    const acceptTermsAndConditions = firebaseFunctions.httpsCallable('acceptTermsAndConditions')

    try {
      loader.open('Updating account...')
      const response = await acceptTermsAndConditions({
        systemUserId: systemUser.id
      })

      if (response.data?.status !== 'success') {
        throw new Error(response.data?.message)
      }

      await reload()
    } catch (err) {
      console.log({ err })
    } finally {
      loader.close()
      setIsSavingTerms(false)
    }
  }

  function handleClick(event) {
    event.stopPropagation()
    acceptTerms()
  }

  const termsLink = (
    <Link
      href="https://www.basebuilders.com/terms-of-use"
      target="_blank"
    >
      Terms and Conditions
    </Link>
  )

  return (
    <>
      <Dialog
        open
        onClose={() => {}}
        maxWidth="sm"
      >
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 1 }}>
            Before continuing you must accept the updated terms and conditions.
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                checked={termsChecked}
                onChange={event => setTermsChecked(event.target.checked)}
              />
            }
            label={
              <Typography>
                I have read and agree to the {termsLink}.
              </Typography>
            }
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClick}
            disabled={!termsChecked || isSavingTerms}
          >
            {isSavingTerms ? 'Please wait...' : 'Accept and Continue'}
          </Button>
        </DialogActions>

      </Dialog>
    </>
  )
}

Terms.propTypes = {
  account: PropTypes.object,
  user: PropTypes.object,
}
