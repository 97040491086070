import * as React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { ResourceModel } from 'src/data-model'
import useModelList from 'src/components/useModelList'


export const ResourceField = ({ value, onChange, ...textFieldProps }) => {

  const { results: resources = [] } = useModelList({
    model: ResourceModel,
    limit: 100,
  })

  return (
    <TextField
      {...textFieldProps}
      value={value || ''}
      onChange={onChange}
      select
      SelectProps={{ displayEmpty: true }}
    >
      <MenuItem value={null}>None</MenuItem>

      {resources.map(resource => (
        <MenuItem key={resource.id} value={resource.id}>
          {resource.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

ResourceField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
}
