import * as React from 'react'

import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { useSubscriptionPrices } from 'src/components/SubscriptionPricesProvider'
import { createCurrencyFormatter } from 'src/utils/currency'



export function SubscriptionPriceField({
  accountType,
  accessLevel,
  billingInterval,
  ...textFieldProps
}) {

  const { getPrices } = useSubscriptionPrices()

  const prices = getPrices({ accountType, accessLevel, billingInterval })
  // const prices = []
  // console.log({ getPrices })

  const currencyFormatter = createCurrencyFormatter()

  return (
    <TextField
      select
      {...textFieldProps}
    >
      {prices.map(price => (
        <MenuItem key={price.id} value={price.id}>
          {`${price.variant}${price.isDefault ? '*' : ''} (${currencyFormatter.format(price.amount / 100)})`}
        </MenuItem>
      ))}
    </TextField>
  )
}
