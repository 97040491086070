import * as React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useModelList from 'src/components/useModelList'
import {
  AccountModel,
  SubscriptionPriceModel,
} from 'src/data-model'

import { MonthlyRevenue } from './MonthlyRevenue'
import { CohortRevenue } from './CohortRevenue'
import { LicenseRevenue } from './LicenseRevenue'
import { OldSchoolNewSchool } from './OldSchoolNewSchool'
import { BillingDataButton } from 'src/components/BillingDataButton'


export function CashFlow() {
  const { results: accounts = [] } = useModelList({
    model: AccountModel,
    filter: query => query
      .where('accountType', '==', 'LIVE')
      .where('stripeSubscriptionStatus', 'in', ['active', 'past_due']),
    limit: 1000,
  })

  console.log(accounts.find(a => a.BBID === '22005'))

  const { results: prices = [] } = useModelList({
    model: SubscriptionPriceModel,
    filter: query => query.where('accountType', '==', 'LIVE'),
    limit: 1000,
  })

  const pricesById = prices.reduce((result, price) => {
    result[price.id] = price.amount / 100
    return result
  }, {})

  return (
    <Container maxWidth="md">
      <Typography variant="h1" component="h1">
        Charts
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MonthlyRevenue accounts={accounts} pricesById={pricesById} />
        </Grid>

        <Grid item xs={12}>
          <CohortRevenue accounts={accounts} pricesById={pricesById} />
        </Grid>

        <Grid item xs={12}>
          <LicenseRevenue accounts={accounts} pricesById={pricesById} />
        </Grid>

        <Grid item xs={12}>
          <OldSchoolNewSchool accounts={accounts} />
        </Grid>

      </Grid>

      <BillingDataButton />
    </Container>
  )
}
