import * as React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'genjo-ui/core/ThemeProvider'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
} from "recharts"

import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'
import Cardinal from 'src/components/Cardinal'


const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function formatCurrency(value) {
  return currencyFormatter.format(value)
}

const COHORTS = [1, 6, 11, 21, 51, 76, 101, 201, 501, Infinity]

function createPoint(_, index) {

  return {
    index,
    label: `${
      COHORTS[index]
    }\u2014${
      COHORTS[index + 1] === Infinity
        ? ''
        : COHORTS[index + 1] - 1
    }`,
    size: COHORTS[index],
    MONTHLY: 0,
    YEARLY: 0,
    total: 0,
  }
}

function legendFormatter(value, entry, index) {
  return value === 'MONTHLY'
    ? 'Monthly'
    : value === 'YEARLY'
    ? 'Yearly'
    : 'Total'
}

function tooltipFormatter(value, name, props) {
  return name === 'MONTHLY'
    ? [formatCurrency(value), 'Monthly']
    : name === 'YEARLY'
    ? [formatCurrency(value), 'Yearly']
    : [formatCurrency(value), 'Total']
}

function initializeData({ accounts, pricesById }) {
  const data = Array.from({ length: COHORTS.length - 1 }, createPoint)

  for (const account of accounts) {
    if (!account.billingStart) {
      console.log(`Account ${account.name} has no billing start!`)
      continue
    }

    const totalLicenses = +account.financialUsersLicenseCount
      + +account.productionUsersLicenseCount
      + +account.limitedUsersLicenseCount

    const cohortIndex = COHORTS.findIndex((_, i) => totalLicenses < COHORTS[i + 1])

    const financialValue = (pricesById[account.financialUserPriceId] ?? 0) * account.financialUsersLicenseCount
    const productionValue = (pricesById[account.productionUserPriceId] ?? 0) * account.productionUsersLicenseCount
    const limitedValue = (pricesById[account.limitedUserPriceId] ?? 0) * account.limitedUsersLicenseCount

    const totalValue = (
      financialValue + productionValue + limitedValue
    ) * (account.billingInterval === 'MONTHLY' ? 12 : 1)

    data[cohortIndex][account.billingInterval] += totalValue
    data[cohortIndex].total += totalValue
  }

  return { data }
}


export function CohortRevenue({ accounts, pricesById }) {

  const { data } = React.useMemo(
    () => initializeData({ accounts, pricesById }),
    [accounts, pricesById]
  )

  return (
    <div style={{ fontSize: 12 }}>
      <Stack alignItems="flex-end" direction="row" spacing={1}>
        <Typography variant="h6">
          Revenue by Size
        </Typography>

        <div style={{ flex: 1 }} />

        <Cardinal
          color={colors.indigo[600]}
          value={accounts.length}
          places={0}
          label="Total Customers"
        />
      </Stack>
      <ResponsiveContainer height={300} width="100%">
        <ComposedChart
          width="100%"
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" label interval={0} />
          <YAxis />
          <Tooltip formatter={tooltipFormatter} />
          <Legend
            formatter={legendFormatter}
          />
          <Bar dataKey="YEARLY" stackId="a" fill={colors.purple[300]} />
          <Bar dataKey="MONTHLY" stackId="a" fill={colors.lightBlue[300]} />
          <Line dataKey="total" type="monotone" stroke={colors.orange[500]} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

CohortRevenue.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  pricesById: PropTypes.object,
}
