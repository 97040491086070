import React from 'react'
import { firebaseDb } from 'src/services/firebase'


function useModelListReducer(state, action) {
  switch (action.type) {
    case 'clear': {
      return {
        status: 'idle',
        results: [],
        errors: null,
        snapshotId: state.snapshotId,
      }
    }

    case 'load': {
      return {
        status: 'loading',
        results: [],
        errors: null,
        snapshotId: state.snapshotId,
      }
    }

    case 'success': {
      return {
        status: 'success',
        results: action.results,
        errors: null,
        snapshotId: action.snapshotId,
      }
    }

    case 'fail': {
      return {
        status: 'error',
        results: [],
        error: action.error,
        id: state.snapshotId,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}


function getQuery(ref, cursor, limit, direction = 'asc') {
  if (cursor && direction === 'asc') {
    return ref
      .startAfter(cursor)
      .limit(limit)
  }

  if (cursor && direction === 'desc') {
    return ref
      .endBefore(cursor)
      .limitToLast(limit)
  }

  return ref.limit(limit)
}

let snapshotIdCounter = 1

/**
 *
 * @param {*} param0
 * Filter key is a unqiue id/key that signals the filter function should be
 * rememoized
 */
export function useModelList({ model, limit = 100, cursor = null, filter, filterKey, skip = false, direction = 'asc' }) {
  const [state, dispatch] = React.useReducer(
    useModelListReducer,
    { results: [], error: null, status: 'idle', snapshotId: snapshotIdCounter },
  )

  React.useEffect(
    () => {
      if (skip) {
        return dispatch({ type: 'clear' })
      }

      dispatch({ type: 'load' })

      try {
        const ref = firebaseDb.collection(model.collection)

        let query = getQuery(ref, cursor, limit, direction)

        if (filter) {
          query = filter(query)
        }

        const unsubscribe = query.onSnapshot(
          querySnapshot => {
            const results = []

            querySnapshot.forEach(doc => {
              const instance = model.fromDb(doc.id, doc.data())
              results.push(instance)
            })

            dispatch({ type: 'success', results, snapshotId: ++snapshotIdCounter })
          },

          error => {
            console.log(error)
            dispatch({ type: 'fail', error })
          },
        )

        return () => {
          unsubscribe()
        }
      } catch (error) {
      // TODO: handle error codes from firebase
        dispatch({ type: 'fail', error })
      }
    },
    // eslint-disable-next-line
    [limit, cursor, filterKey, skip]
  )

  return state
}
