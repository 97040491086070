import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'
import { ResourceModel } from 'src/data-model'

import { useCreateResource } from 'src/services/resources'

import { useNavigate } from 'react-router-dom'
import PrintableError from 'src/components/PrintableError'


export const CreateResourceDialog = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate()

  const [resource, setResource] = React.useState(ResourceModel.create())

  const [error, setError] = React.useState(null)

  React.useEffect(
    () => {
      setResource(ResourceModel.create())
    },
    [isOpen],
  )

  const setFieldValue = (field, value) => {
    setResource({ ...resource, [field]: value })
  }

  const createResource = useCreateResource()

  function handleSubmit(event) {
    event.preventDefault()

    createResource({
      values: resource,
      onSuccess: resourceId => {
        navigate(`/admin/resources/${resourceId}`)
      },
      onError: setError,
    })
  }

  return (
    <Dialog
      keepMounted
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit}>
        <ClosableDialogTitle onClose={onClose}>Create Resource</ClosableDialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <PrintableError error={error} />
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="filled"
                value={resource.name}
                required
                onChange={event => setFieldValue('name', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Domain"
                variant="filled"
                value={resource.domain}
                onChange={event => setFieldValue('domain', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Server IP"
                variant="filled"
                value={resource.ip}
                onChange={event => setFieldValue('ip', event.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Region"
                value={resource.region}
                onChange={event => setFieldValue('region', event.target.value)}
                select
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" disabled>Please select a region...</MenuItem>
                <MenuItem value="Las Vegas">Las Vegas</MenuItem>
                <MenuItem value="Atlanta">Atlanta</MenuItem>
              </TextField>
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={!resource?.name}
            type="submit"
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>

  )
}

CreateResourceDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}
