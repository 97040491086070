import * as React from 'react'
import PropTypes from 'prop-types'

import { LinkBehavior } from 'src/components/LinkBehavior'

import useModelInstance from 'src/components/useModelInstance'
import { AccountModel } from 'src/data-model'

import GhostLoginButton from 'src/components/GhostLoginButton'

import {
  Card,
  Image,
  Text,
  Button,
  Group,
} from '@mantine/core'


export const AccountCard = ({ user }) => {
  const { instance: account } = useModelInstance({
    model: AccountModel,
    id: user.accountId,
  })

  if (!account) {
    return 'Loading...'
  }

  return (
    <>
      <Group position="apart" mb="xs">
        <Text size="xl" weight={500}>
          {account.name}
        </Text>

        {user.isAccountAdmin && (
          <Button
            color="orange"
            component={LinkBehavior}
            to={`/accounts/${account.id}`}
          >
            Manage Account
          </Button>
        )}
      </Group>

      <Card
        shadow="sm"
        p="lg"
        withBorder
        radius="md"
        mb="md"
        style={{ textAlign: 'center' }}
      >
        <Card.Section mb="md">
          <Image
            src={account?.logo?.url ?? `/img/bg/architects.jpg`}
            height={200}
            alt={`Logo image from ${account?.name}`}
          />
        </Card.Section>



        {!account.isOldSchool && (
          <GhostLoginButton
            style={{ fontSize: 24, height: 56, borderRadius: 28 }}
            user={user}
            account={account}
          >
            Launch BaseBuilders
          </GhostLoginButton>
        )}

      </Card>
    </>
  )
}

AccountCard.propTypes = {
  account: PropTypes.object,
}

export default AccountCard
