import * as React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'genjo-ui/core/ThemeProvider'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'
import Cardinal from 'src/components/Cardinal'


const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function formatCurrency(value) {
  return currencyFormatter.format(value)
}

const LICENSES = [
  { value: 'FINANCIAL', label: 'Financial' },
  { value: 'PRODUCTION', label: 'Production' },
  { value: 'LIMITED', label: 'Limited' },
]

function createPoint(_, index) {
  const { value, label } = LICENSES[index]

  return {
    value,
    label,
    count: 0,
    revenue: 0,
  }
}

function legendFormatter(value, entry, index) {
  return value === 'revenue'
    ? 'Revenue'
    : 'Count'
}

function tooltipFormatter(value, name, props) {
  return name === 'count'
    ? [value, 'Count']
    : name === 'revenue'
    ? [formatCurrency(value), 'Revenue']
    : [value, 'Unknown']
}

function initializeData({ accounts, pricesById }) {
  const data = Array.from({ length: 3 }, createPoint)

  let totalCount = 0

  for (const account of accounts) {
    if (!account.billingStart) {
      console.log(`Account ${account.name} has no billing start!`)
      continue
    }

    const totalLicenses = +account.financialUsersLicenseCount
      + +account.productionUsersLicenseCount
      + +account.limitedUsersLicenseCount

    totalCount += totalLicenses

    const financialValue = (pricesById[account.financialUserPriceId] ?? 0) * account.financialUsersLicenseCount
    const productionValue = (pricesById[account.productionUserPriceId] ?? 0) * account.productionUsersLicenseCount
    const limitedValue = (pricesById[account.limitedUserPriceId] ?? 0) * account.limitedUsersLicenseCount

    const multiplier = account.billingInterval === 'MONTHLY' ? 12 : 1

    data[0].revenue += financialValue * multiplier
    data[0].count += +account.financialUsersLicenseCount

    data[1].revenue += productionValue * multiplier
    data[1].count += +account.productionUsersLicenseCount

    data[2].revenue += limitedValue * multiplier
    data[2].count += +account.limitedUsersLicenseCount
  }

  return { data, totalCount }
}


export function LicenseRevenue({ accounts, pricesById }) {

  const { data, totalCount } = React.useMemo(
    () => initializeData({ accounts, pricesById }),
    [accounts, pricesById]
  )

  return (
    <div style={{ fontSize: 12 }}>
      <Stack alignItems="flex-end" direction="row" spacing={1}>
        <Typography variant="h6">
          Revenue by Licenses
        </Typography>

        <div style={{ flex: 1 }} />

        <Cardinal
          color={colors.indigo[600]}
          value={totalCount}
          places={0}
          label="Total Licenses"
        />
      </Stack>
      <ResponsiveContainer height={300} width="100%">
        <ComposedChart
          width="100%"
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="label" label interval={0} />
          <YAxis yAxisId="revenue" />
          <YAxis yAxisId="count" />
          <Tooltip formatter={tooltipFormatter} />
          <Legend
            formatter={legendFormatter}
          />
          <Bar dataKey="revenue" yAxisId="revenue" fill={colors.purple[300]} />
          <Bar dataKey="count" yAxisId="count" fill={colors.lightBlue[300]} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

LicenseRevenue.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  pricesById: PropTypes.object,
}
