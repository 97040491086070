import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { PlanSummary } from './PlanSummary'
import { ConfirmDetails } from './ConfirmDetails'
import StripeCardField from 'src/components/StripeCardField'
import { useSignUp } from './useSignUp'
import SimpleTabs from 'genjo-ui/core/SimpleTabs'
import SimpleTab from 'genjo-ui/core/SimpleTab'
import Checkbox from 'genjo-ui/core/Checkbox'

import { ACH } from './ACH'


export function ConfirmStep() {
  const {
    values,
    touched,
    setFieldTouched,
    paymentInfo,
    setPaymentInfo,
    error,
    setPlaidToken,
    setPlaidInfo,
    serviceTermsAccepted,
    setServiceTermsAccepted,
    paymentTermsAccepted,
    setPaymentTermsAccepted,
  } = useSignUp()

  const [paymentMethod, setPaymentMethod] = React.useState('ach')
  function handlePaymentMethodChange(event, newMethod) {
    setPlaidInfo(null)
    setPlaidToken(null)
    setPaymentTermsAccepted(false)
    setPaymentMethod(newMethod)
  }

  function cardHolderNameRequiredMixin() {
    const hasError = !paymentInfo.cardHolderName && touched.has('cardHolderName')

    return {
      onBlur: () => setFieldTouched('cardHolderName'),
      error: hasError,
      helperText: hasError ? 'Required' : '',
    }
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ConfirmDetails />
        </Grid>

        <Grid item xs={12}>
          <PlanSummary />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h3">
                Payment
              </Typography>

              <SimpleTabs value={paymentMethod} onChange={handlePaymentMethodChange}>
                <SimpleTab value="ach" label="ACH / Checking Account" />
                <SimpleTab value="cc" label="Credit Card" />
              </SimpleTabs>

              <Grid container spacing={2} sx={paymentMethod !== 'cc' ? { position: 'absolute', top: -9999, left: -9999, opacity: 0 } : {}}>
                <Grid item xs={12}>
                  <TextField
                    label="Card Holder Name"
                    autoComplete="cc-name"
                    value={values.cardHolderName}
                    onChange={event => setPaymentInfo({
                      ...paymentInfo,
                      cardHolderName: event.target.value,
                    })}
                    required
                    {...cardHolderNameRequiredMixin()}
                  />
                </Grid>

                <Grid item xs={12}>
                  <StripeCardField
                    onChange={cardInfo => setPaymentInfo({
                      ...paymentInfo,
                      cardInfo,
                    })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    control={
                      <Checkbox
                        checked={paymentTermsAccepted}
                        onChange={event => setPaymentTermsAccepted(event.target.checked)}
                      />
                    }
                    label={`I (${values.fullName} on behalf of ${values.companyName}) authorize Base Builders LLC to charge this credit card as described in the summary above.`}
                  />
                </Grid>
              </Grid>



              <Grid container spacing={2} sx={paymentMethod !== 'ach' ? { position: 'absolute', top: -9999, left: -9999, opacity: 0 } : {}}>
                <Grid item xs={12}>
                  <ACH />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h3">
                Terms and Conditions
              </Typography>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceTermsAccepted}
                    onChange={event => setServiceTermsAccepted(event.target.checked)}
                  />
                }
                label={
                  <Typography>I have read and agree to the <a href="https://www.basebuilders.com/terms-of-use" rel="noopener noreferrer" target="_blank">Terms and Conditions</a>.</Typography>
                }
              />
            </CardContent>
          </Card>
        </Grid>

        {Boolean(error) && (
          <Grid item xs={12}>
            <Typography variant="h6" color="error.main" align="center">
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  )
}


ConfirmStep.propTypes = {

}
