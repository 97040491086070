import { DateTime } from 'luxon'

import {
  dateTimeFieldFactory,
  stringFieldFactory,
  numberFieldFactory,
  modelFactory,
  submodelFieldFactory,
} from 'pebble-orm'


const GeoPoint = modelFactory({
  name: 'GeoPoint',
  isSubmodel: true,
  fields: [
    numberFieldFactory({
      name: 'latitude',
    }),

    numberFieldFactory({
      name: 'longitude',
    }),

    stringFieldFactory({
      name: 'geohash',
    }),
  ]
})


const createTimestampFields = () => [
  dateTimeFieldFactory({
    name: 'created',
    defaultValue: () => DateTime.local(),
    allowNull: false,
    description: 'Timestamp when instance was created.',
    example: 1445410800000,
  }),

  dateTimeFieldFactory({
    name: 'modified',
    defaultValue: () => DateTime.local(),
    allowNull: false,
    description: 'Timestamp when instance was last modified.',
    example: 1515744000000,
  }),
]

const createAddressFields = () => [
  stringFieldFactory({
    name: 'city',
  }),

  stringFieldFactory({
    name: 'state',
  }),

  stringFieldFactory({
    name: 'postalCode',
  }),

  stringFieldFactory({
    name: 'country',
  }),

  submodelFieldFactory({
    name: 'geopoint',
    model: GeoPoint,
  }),

  stringFieldFactory({
    name: 'address',
    defaultValue: '',
  }),
]


export {
  createTimestampFields,
  createAddressFields,
}
