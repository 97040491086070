import * as React from 'react'
import { FileMakerDataContext } from './FileMakerDataContext'

export function useFileMakerData() {
  const context = React.useContext(FileMakerDataContext)

  if (context === undefined) {
    throw new Error('useFileMakerData must be within FileMakerDataProvider')
  }

  return context
}
