import React from 'react'
import PropTypes from 'prop-types'


import jwt from 'jsonwebtoken'
import { firebaseApp, firebaseAuth } from 'src/services/firebase'
import { extractUserFromToken } from 'src/data-model'

import { AuthContext } from './AuthContext'


const AuthStatus = {
  INITIALIZING: 'INITIALIZING',
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
  IS_ANONYMOUS: 'IS_ANONYMOUS',
}


const AuthAction = {
  LOG_OUT: 'LOG_OUT',
  REGISTER: 'REGISTER',
  REAUTHENTICATE: 'REAUTHENTICATE',
}

/** Authentication reducer */
function reducer(state, action) {
  switch (action.type) {
    case AuthAction.REAUTHENTICATE: {
      return {
        user: action.user,
        token: action.token,
        status: AuthStatus.IS_AUTHENTICATED,
      }
    }

    case AuthAction.REGISTER: {
      return {
        user: action.user,
        token: action.token,
        status: AuthStatus.IS_AUTHENTICATED,
      }
    }

    case AuthAction.LOG_OUT: {
      return {
        user: null,
        token: null,
        status: AuthStatus.IS_ANONYMOUS,
      }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

/**
 * Merges the Firebase User object with the custom claims that can only be
 * accessed from a firebase user token into a User Interface.
 *
 * @param {*} user Firebase User
 * @param {*} token Firebase id token
 */
function composeUser(user, token) {
  // compose user object from firebase user and token claims
  const decodedToken = token ? jwt.decode(token) : null

  const composedUser = user && decodedToken ? {
    ...extractUserFromToken(decodedToken),
    // The display name is not available from the idToken
    name: user.displayName ?? '',
    id: user.uid ?? '',
  } : null

  return composedUser
}


/**
 * Provides AuthContext and manages the app's authentication state.
 *
 */
const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    status: AuthStatus.INITIALIZING,
    user: null,
    token: null,
  })

  /** Log out of the app. */
  const logout = React.useCallback(
    async () => {
      dispatch({ type: AuthAction.LOG_OUT })
      await firebaseApp.auth().signOut()
    },
    [],
  )

  /**
   * Registers an authenticated user object and sets the auth token for
   * server-based requests.
   *
   * @param {*} [user]
   */
  const register = React.useCallback(
    async user => {

      if (!user) {
        return dispatch({ type: AuthAction.LOG_OUT })
      }

      // If the user is a demo user, we need to set the current firebase user
      // session's persistence to be NONE. This way the session is lost on
      // closing and the user will be forced back to the login screen.
      if (user.email === 'demo@basebuilders.com') {
        firebaseAuth.setPersistence(firebaseApp.auth.Auth.Persistence.NONE)
      }

      const token = await user.getIdToken(true)
      localStorage.setItem('token', token)

      dispatch({ type: AuthAction.REGISTER, user, token })
    },
    [],
  )

  /**
   * Updates the user's credentials and id token. Useful for auth-based
   * functions that Firebase requires a recent login (e.g. < 1 hour)
   */
  const reauthenticate = React.useCallback(
    async ({ password = '' }) => {
      const credential = firebaseApp.auth.EmailAuthProvider.credential(state.user.email, password)

      // This fails loudly. Errors should be caught when using.
      await state.user.reauthenticateWithCredential(credential)
      const token = await state.user.getIdToken(true)
      localStorage.setItem('token', token)

      dispatch({ type: AuthAction.REAUTHENTICATE, token })
    },
    [state.user],
  )

  // Reload user
  const reload = React.useCallback(
    async () => {
      await firebaseApp.auth().updateCurrentUser(state.user)
    },
    [state.user]
  )

  // When the app is in the INITIALIZING state subscribe to firebase's auth
  // state. If a registered user is already stored on the client's device the
  // callback will load the user and proceed right to the main app. Otherwise,
  // an anonymous user will be loaded and the user will be routed to the sign
  // in page.
  React.useEffect(
    () => {
      const unsubscribe = firebaseApp.auth().onIdTokenChanged(register)
      return unsubscribe
    },
    // eslint-disable-next-line
    []
  )

  return (
    <AuthContext.Provider
      value={{
        user: composeUser(state.user, state.token),
        token: state.token,
        firebaseUser: state.user,
        authStatus: state.status,
        register,
        reauthenticate,
        logout,
        reload,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}


AuthProvider.propTypes = {
  /** Wrapped content. */
  children: PropTypes.node.isRequired,
}

export {
  AuthProvider,
  AuthStatus,
}
