import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import jwt from 'jsonwebtoken'
import { DateTime } from 'luxon'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import PasswordField from 'genjo-ui/core/PasswordField'
import Spacer from 'genjo-ui/core/Spacer'

import { firebaseFunctions } from 'src/services/firebase'
import { useParams, useNavigate } from 'react-router-dom'

import SuccessIcon from '@material-ui/icons/TaskAlt'



const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundImage: `url('/img/bg/activate.jpg')`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    position: 'absolute',
    top: 24,
    left: 24,
    height: 48,
    width: 'auto',
    cursor: 'pointer',
    zIndex: theme.zIndex.modal + 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successIcon: {
    fontSize: 48,
    color: theme.palette.success.main,
  },
}))


export function ActivateAccount() {
  const classes = useStyles()
  const loader = useLoader()
  const { token } = useParams()
  const navigate = useNavigate()
  const [redirectCountDown, setRedirectCountDown] = React.useState(3)

  // States
  // - idle
  // - errorMissing
  // - errorInvalid
  // - errorExpired
  // - password
  // - verifying
  // - verifyFail
  // - verifySuccess
  const [state, setState] = React.useState({
    status: 'idle',
    email: '',
  })

  function updateStatus(status) {
    setState(s => ({ ...s, status }))
  }

  const [passwords, setPasswords] = React.useState({
    value: '',
    confirm: '',
    isValid: false,
  })

  function handlePasswordChange(value, isValid) {
    setPasswords(p => ({ ...p, value, isValid }))
  }

  function handleConfirmationChange(confirm) {
    setPasswords(p => ({ ...p, confirm }))
  }

  async function handleActivate() {
    const verifyActivationToken = firebaseFunctions.httpsCallable('verifyActivationToken')

    try {
      loader.open('Activating account...')
      const response = await verifyActivationToken({ token, password: passwords.value })

      if (response?.data?.status === 'success') {
        updateStatus('verifySuccess')
      } else {
        updateStatus('verifyFail')
      }
    } catch (error) {
      console.log({ error })
      updateStatus('verifyFail')
    } finally {
      loader.close()
    }
  }

  React.useEffect(
    () => {
      console.log({ token })
      if (!token) {
        return
      }

      const decoded = jwt.decode(token)

      if (!decoded) {
        updateStatus('errorInvalid')
        return
      }

      const expiration = DateTime.fromMillis(decoded.exp * 1000)

      if (expiration < DateTime.local()) {
        updateStatus('errorExpired')
        return
      }

      setState({
        status: 'password',
        email: decoded.email,
      })
    },
    [token]
  )

  React.useEffect(
    () => {
      let timer
      if (state.status !== 'verifySuccess') {
        return
      }

      if (redirectCountDown > 0) {
        timer = setTimeout(() => {
          setRedirectCountDown(redirectCountDown - 1)
        }, 1000)
      } else {
        navigate('/')
      }

      return () => clearTimeout(timer)
    },
    [navigate, state, redirectCountDown]
  )

  function renderContent() {
    switch (state.status) {
      case 'idle': {
        return (
          <>
            <Typography>Loading...</Typography>
          </>
        )
      }

      case 'errorInvalid': {
        return (
          <>
            <Typography variant="h6">Invalid verification token</Typography>
            <Typography>Please try clicking on the verification button again.</Typography>
          </>
        )
      }

      case 'errorExpired': {
        return (
          <>
            <Typography variant="h6">Verification link has expired</Typography>
            <Typography>Please resed the verification email.</Typography>
          </>
        )
      }

      case 'password': {
        return (
          <>
            <Typography variant="h6">Activate your account</Typography>
            <Typography variant="body1">
              <strong>{state.email}</strong>
            </Typography>
            <Grid container spacing={2}>
              <Grid item>
              </Grid>

              <Grid item xs={12}>
                <Typography color="textSecondary">
                  Please create a new password and click "Activate" to complete activation.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <PasswordField
                  label="Password"
                  value={passwords.value}
                  onChange={handlePasswordChange}
                  detectPasswordStrength
                />
              </Grid>

              <Grid item xs={12}>
                <PasswordField
                  label="Confirm your password"
                  value={passwords.confirm}
                  onChange={handleConfirmationChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Spacer axis="vertical" size={16} />
                <Button
                  fullWidth
                  variant="contained"
                  disabled={!passwords.isValid || passwords.value !== passwords.confirm}
                  onClick={handleActivate}
                >
                  Activate
                </Button>
              </Grid>
            </Grid>
          </>
        )
      }

      case 'verifying': {
        return (
          <div>verifying...</div>
        )
      }

      case 'verifyFail': {
        return (
          <div>FAILS</div>
        )
      }

      case 'verifySuccess': {
        return (
          <>
            <SuccessIcon className={classes.successIcon} />
            <Typography variant="h6" style={{ marginBottom: 32 }}>Your account has been activated!</Typography>
            <Typography color="textSecondary">
              {`Redirecting to log in page in... ${redirectCountDown}`}
            </Typography>
          </>
        )
      }

      default: {
        return (
          <div>Hello</div>
        )
      }
    }
  }

  return (
    <div className={classes.root}>
      <a href="/">
        <img
          alt="Base Builders"
          src={`${process.env.PUBLIC_URL}/img/logo/logo_horizontal.svg`}
          className={classes.logo}
        />
      </a>
      <Container maxWidth="xs" className={classes.container}>
        <Card>
          <CardContent className={classes.innerContainer}>
            {renderContent()}
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
