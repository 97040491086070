import * as React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'genjo-ui/core/ThemeProvider'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from "recharts"

import Typography from '@material-ui/core/Typography'
import Stack from '@material-ui/core/Stack'
import Cardinal from 'src/components/Cardinal'

import useDimensions from 'genjo-ui/core/useDimensions'


const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function formatCurrency(value) {
  return currencyFormatter.format(value)
}

const SCHOOLS = [
  { value: 'NEW', label: 'Cloud' },
  { value: 'OLD', label: 'Old School' },
]

const LICENSES = [
  { value: 'FINANCIAL', label: 'Financial' },
  { value: 'PRODUCTION', label: 'Production' },
  { value: 'LIMITED', label: 'Limited' },
]

function createSchoolPoint(_, index) {
  const { value, label } = SCHOOLS[index]

  return {
    value,
    label: 'Total Licenses',
    count: 0,
    schoolLabel: label,
  }
}

function createLicensePoint(_, index) {
  const { value, label } = LICENSES[index % 3]
  const { value: schoolValue, label: schoolLabel} = SCHOOLS[Number(index >= 3)]

  return {
    schoolValue,
    schoolLabel,
    value,
    label: ` ${label} Licenses`,
    count: 0,
  }
}


function initializeData({ accounts, pricesById }) {
  const schoolData = Array.from({ length: 2 }, createSchoolPoint)
  const licenseData = Array.from({ length: 6 }, createLicensePoint)

  let totalAccounts = [0, 0]

  for (const account of accounts) {
    const schoolIndex = Number(account.isOldSchool)

    const totalLicenses = +account.financialUsersLicenseCount
      + +account.productionUsersLicenseCount
      + +account.limitedUsersLicenseCount

    totalAccounts[schoolIndex] += 1

    schoolData[schoolIndex].count += totalLicenses
    licenseData[0 + schoolIndex * 3].count += +account.financialUsersLicenseCount
    licenseData[1 + schoolIndex * 3].count += +account.productionUsersLicenseCount
    licenseData[2 + schoolIndex * 3].count += +account.limitedUsersLicenseCount

  }

  return { schoolData, licenseData, totalAccounts }
}

const renderActiveShape = ringIndex => props => {
  const RADIAN = Math.PI / 180

  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    label,
    schoolLabel,
    value,
  } = props

  // To get the sector for the inner ring outside the outer ring, we need to
  // conditionally add some extra padding.
  const innerBuffer = ringIndex === 0 ? 30 : 0

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10 + innerBuffer) * cos;
  const sy = cy + (outerRadius + 10 + innerBuffer) * sin;
  const mx = cx + (outerRadius + 30 + innerBuffer) * cos;
  const my = cy + (outerRadius + 30 + innerBuffer) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6 + innerBuffer}
        outerRadius={outerRadius + 10 + innerBuffer}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}${label}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {schoolLabel}
      </text>
    </g>
  )
}


export function OldSchoolNewSchool({ accounts }) {

  const { schoolData, licenseData, totalAccounts } = React.useMemo(
    () => initializeData({ accounts }),
    [accounts]
  )

  // [Inner/Outer, Index]
  const [activeIndex, setActiveIndex] = React.useState([0, 0]);
  const onPieEnter = React.useCallback(
    ringIndex => (_, index) => {
      setActiveIndex([ringIndex, index]);
    },
    [setActiveIndex]
  )

  const [ref, { width }, ] = useDimensions()

  return (
    <div style={{ fontSize: 12 }} ref={ref}>
      <Stack alignItems="flex-end" direction="row" spacing={1}>
        <Typography variant="h6">
          Old School vs Cloud
        </Typography>

        <div style={{ flex: 1 }} />

        <Cardinal
          color={colors.indigo[600]}
          value={totalAccounts[1]}
          places={0}
          label="Old School Accounts"
        />

        <Cardinal
          color={colors.indigo[600]}
          value={totalAccounts[0]}
          places={0}
          label="Cloud Accounts"
        />
      </Stack>
      <ResponsiveContainer height={300} width="100%">
        <PieChart
          width="100%"
          height={300}
        >
          <Pie
            activeIndex={activeIndex[0] === 0 ? activeIndex[1] : null}
            activeShape={renderActiveShape(0)}
            onMouseEnter={onPieEnter(0)}
            data={schoolData}
            dataKey="count"
            cx={width / 2}
            cy={150}
            outerRadius={60}
            fill="#8884d8"
          />

          <Pie
            activeIndex={activeIndex[0] === 1 ? activeIndex[1] : null}
            activeShape={renderActiveShape(1)}
            onMouseEnter={onPieEnter(1)}
            data={licenseData}
            dataKey="count"
            cx={width /2 }
            cy={150}
            innerRadius={70}
            outerRadius={90}
            fill="#82ca9d"
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

OldSchoolNewSchool.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
}
