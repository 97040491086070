import * as React from 'react'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import Typography from '@material-ui/core/Typography'
import TableResultsLoader from 'genjo-ui/core/TableResultsLoader'
import { useNavigate } from 'react-router-dom'

// import SortableTableHeadCell from 'genjo-ui/core/SortableTableHeadCell'
import { firebaseFunctions } from 'src/services/firebase'

import TrueIcon from '@material-ui/icons/TaskAltRounded'
import FalseIcon from '@material-ui/icons/CloseRounded'


function BooleanIcon({ value }) {
  return value
    ? <TrueIcon color="success" />
    : <FalseIcon color="error" />
}


export function SystemUsers() {
  const navigate = useNavigate()

  const [queryParams, ] = React.useState({
    limit: 1000,
    nextPageToken: undefined,
  })

  const [status, setStatus] = React.useState('idle')
  const [users, setUsers] = React.useState([])

  React.useEffect(
    () => {
      setStatus('loading')
    },
    [queryParams]
  )

  React.useEffect(
    () => {
      if (status !== 'loading') {
        return
      }

      const getSystemUsers = firebaseFunctions.httpsCallable('getSystemUsers')

      let isMounted = true
      ;(async () => {
        try {
          const result = await getSystemUsers(queryParams)

          result.data.users?.sort((a, b) => a.email < b.email
            ? -1
            : a.email > b.email
            ? 1
            : 0
          )

          if (isMounted) {
            setUsers(result.data.users)
            setStatus('success')
          }
        } catch (error) {
          console.log({ error })
        }
      })()

      return () => isMounted = false
    },
    [status, queryParams]
  )

  return (
    <>
      <Typography variant="h4">System Users</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Verified</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Admin</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {users.map(user => (
            <TableRow
              hover
              key={user.uid}
              onClick={() => navigate(`/admin/system-users/${user.uid}`)}
            >
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.displayName}</TableCell>
              <TableCell><BooleanIcon value={user.emailVerified} /></TableCell>
              <TableCell><BooleanIcon value={!user.disabled} /></TableCell>
              <TableCell><BooleanIcon value={user?.customClaims?.isAdmin ?? false} /></TableCell>
            </TableRow>
          ))}

          <TableResultsLoader
            isLoading={status === 'loading'}
            count={users.length}
            colSpan={5}
          />
        </TableBody>

        {Boolean(users.length) && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={5} align="right">
                {`Showing ${users.length} system user${users.length === 1 ? '' : 's'}`}
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>

    </>
  )
}
