import * as React from 'react'


import ActivityLayout from 'src/layouts/ActivityLayout'


export function AdminActivity() {

  return (
    <ActivityLayout />
  )
}
