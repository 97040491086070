import { choicesFactory } from 'pebble-orm'


export const AccessLevelChoices = choicesFactory({
  items: [
    { key: 'FINANCIAL', value: 'FINANCIAL', label: 'Financial' },
    { key: 'PRODUCTION', value: 'PRODUCTION', label: 'Production' },
    { key: 'LIMITED', value: 'LIMITED', label: 'Limited' },
  ],
  name: 'AccessLevelChoices',
  type: 'string',
})


export const AccountTypeChoices = choicesFactory({
  items: [
    { key: 'DEMO', value: 'DEMO', label: 'Demo' },
    { key: 'LIVE', value: 'LIVE', label: 'Live' },
  ],
  name: 'AccountTypeChoices',
  type: 'string',
})


export const SubscriptionTypeChoices = choicesFactory({
  items: [
    { key: 'AUTOMATIC', value: 'AUTOMATIC', label: 'Automatic' },
    { key: 'SEND_INVOICE', value: 'SEND_INVOICE', label: 'Send Invoice' },
  ],
  name: 'SubscriptionTypeChoices',
  type: 'string',
})


export const BillingIntervalChoices = choicesFactory({
  items: [
    { key: 'MONTHLY', value: 'MONTHLY', label: 'Monthly' },
    { key: 'YEARLY', value: 'YEARLY', label: 'Yearly' },
  ],
  name: 'BillingIntervalChoices',
  type: 'string',
})


export const ServerStatusChoices = choicesFactory({
  items: [
    { key: 'ACTIVE', value: 'ACTIVE', label: 'Active' },
    { key: 'INACTIVE', value: 'INACTIVE', label: 'Inactive' },
  ],
  name: 'ServeStatusChoices',
  type: 'string',
})


export const UserStatusChoices = choicesFactory({
  items: [
    { key: 'ACTIVE', value: 'ACTIVE', label: 'Active' },
    { key: 'INACTIVE', value: 'INACTIVE', label: 'Inactive' },
  ],
  name: 'UserStatusChoices',
  type: 'string',
})


export const DisciplineChoices = choicesFactory({
  items: [
    { key: 'ARCH', value: 'ARCH', label: 'Architecture' },
    { key: 'ARCH_ENG', value: 'ARCH_ENG', label: 'Archtecture and Engineering' },
    { key: 'ENG_CIVIL', value: 'ENG_CIVIL', label: 'Civil Engineering' },
    { key: 'ENG_ELEC', value: 'ENG_ELEC', label: 'Electrical Engineering' },
    { key: 'ENG_ENV', value: 'ENG_ENV', label: 'Environmental Engineering' },
    { key: 'ENG_FIRE', value: 'ENG_FIRE', label: 'Fire Engineering' },
    { key: 'ENG_MECH', value: 'ENG_MECH', label: 'Mechanical Engineering' },
    { key: 'ENG_MEP', value: 'ENG_MEP', label: 'Multi-discipline Engineering' },
    { key: 'ENG_STRUCT', value: 'ENG_STRUCT', label: 'Structural Engineering' },
    { key: 'INTERIORS', value: 'INTERIORS', label: 'Interiors' },
    { key: 'OTHER', value: 'OTHER', label: 'Other' },
  ],
  name: 'DisciplineChoices',
  type: 'string',
})
