import * as React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import BusinessIcon from '@material-ui/icons/BusinessRounded'
import EmailIcon from '@material-ui/icons/EmailRounded'
import PhoneIcon from '@material-ui/icons/PhoneRounded'
import PlaceIcon from '@material-ui/icons/PlaceRounded'

import ImageUploader from 'src/components/ImageUploader'
import { AccountModel, ImageRefModel } from 'src/data-model'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'
import { firebaseStorage, firebaseDb } from 'src/services/firebase'
import useDimensions from 'genjo-ui/core/useDimensions'

import { EditDetails } from './EditDetails'


export function Details({ account }) {
  const loader = useLoader()
  const snackbar = useSnackbar()

  const [isEditing, setIsEditing] = React.useState(false)

  const [ref, dim, ] = useDimensions()

  async function handleDeleteLogo() {
    if (!account?.logo) {
      throw new Error('There is no logo to delete.')
    }

    try {
      loader.open('Deleting logo...')

      const originalName = `images/${account.logo.imageId}@original`
      const croppedName = `images/${account.logo.imageId}@cropped`
      const thumbnailName = `images/${account.logo.imageId}@thumbnail`

      await firebaseStorage.child(originalName).delete()
      await firebaseStorage.child(croppedName).delete()
      await firebaseStorage.child(thumbnailName).delete()

      await firebaseDb.collection('images').doc(account.logo.imageId).delete()
      await firebaseDb.collection('accounts').doc(account.id).update({
        logo: null,
      })

      snackbar.addMessage({
        text: 'Logo deleted.',
        type: 'success',
      })
    } catch (error) {
      console.log({ error })

      snackbar.addMessage({
        text: error?.message ?? 'There was a problem removing logo.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  async function handleLogoUpload(newImage) {
    try {
      const photo = {
        imageId: newImage.id,
        url: newImage.croppedUrl,
        thumbnailUrl: newImage.thumbnailUrl,
      }

      const image = ImageRefModel.create(photo)

      await firebaseDb.collection(AccountModel.collection).doc(account.id).update({
        logo: image,
      })
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({

      })
    }
  }

  return (
    <>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h6">
            Account Details
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Card ref={ref}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Company Information
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon><BusinessIcon /></ListItemIcon>

                      <ListItemText
                        primary={account.name}
                        secondary="Company Name"
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon><EmailIcon /></ListItemIcon>

                      <ListItemText
                        primary={account.email || 'None'}
                        secondary="Billing Email"
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon><PhoneIcon /></ListItemIcon>

                      <ListItemText
                        primary={account.phoneNumber || 'None'}
                        secondary="Phone Number"
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon><PlaceIcon /></ListItemIcon>

                      <ListItemText
                        primary={account.address || 'None'}
                        secondary="Address"
                      />
                    </ListItem>

                  </List>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Button variant="contained" onClick={() => setIsEditing(true)}>
                Update Details
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={7}>
          <Card
            sx={{
              minHeight: dim?.height ?? 0, maxHeight: dim?.height ?? 0, padding: 2,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Typography variant="subtitle2" color="textSecondary">
              Company Logo
            </Typography>

            <ImageUploader
              image={account?.logo}
              onRemove={handleDeleteLogo}
              ownerId={account.id}
              imageType="photo"
              onSuccess={handleLogoUpload}
            />
          </Card>
        </Grid>
      </Grid>

      {isEditing && (
        <EditDetails
          account={account}
          onClose={() => setIsEditing(false)}
        />
      )}
    </>
  )
}

Details.propTypes = {
  account: PropTypes.object,
}
