import * as React from 'react'

import { useMediaQuery } from '@mantine/hooks'

import {
  AppShell,
} from '@mantine/core'


export function Shell({ children, navbar }) {

  const matches = useMediaQuery('(min-width: 800px)')

  if (!matches) {
    return (
      <>
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 64, overflow: 'auto' }}>
          <AppShell
            padding="md"
            styles={(theme) => ({
              main: {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
              },
            })}
          >
            {children}
          </AppShell>
        </div>

        <div style={{ position: 'fixed', bottom: 0, height: 64, left: 0, right: 0}}>
          {navbar}
        </div>
      </>
    )
  }

  return (
    <AppShell
      padding="md"
      navbar={navbar}
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          marginBottom: matches ? 0 : 64,
        },
      })}
    >
      {children}
    </AppShell>
  )
}
