import * as React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'
import ConfirmationDialog from 'genjo-ui/core/ConfirmationDialog'
import Spacer from 'genjo-ui/core/Spacer'

import { createCurrencyFormatter } from 'src/utils/currency'

import { useUpdateAccount } from 'src/services/accounts'

import { useInvoicePreview } from './useInvoicePreview'
import { ChangeCounter } from './ChangeCounter'


const inputStyle = {
  fontSize: 32,
  textAlign: 'center',
}



function parsePositiveInteger(value) {
  const parsedValue = Number.parseInt(value, 10)

  return Number.isFinite(parsedValue)
    ? Math.max(0, parsedValue)
    : 0
}


const COUNT_INDEXES = {
  financialUsersLicenseCount: 0,
  productionUsersLicenseCount: 1,
  limitedUsersLicenseCount: 2,
}



export function EditSubscriptionPlan({ account, stripeInfo, onClose, unitPrices, refreshStripeInfo, userLicenseCounts }) {
  const updateAccount = useUpdateAccount()
  const { subscription } = stripeInfo

  const [originalCounts, setOriginalCounts] = React.useState([0, 0, 0])
  const [newCounts, setNewCounts] = React.useState([0, 0, 0])

  const [isConfirming, setIsConfirming] = React.useState(false)

  const isDirty = Boolean(originalCounts[0] - newCounts[0])
     || Boolean(originalCounts[1] - newCounts[1])
     || Boolean(originalCounts[2] - newCounts[2])

  // Extract the new price
  const newPrice = newCounts.reduce((total, count, index) => total + count * unitPrices[index], 0)

  const [invoicePreview, previewStatus] = useInvoicePreview({
    account,
    subscription,
    isDirty: isConfirming,
    newCounts,
  })

  React.useEffect(
    () => {
      const financial = parsePositiveInteger(account.financialUsersLicenseCount)
      const production = parsePositiveInteger(account.productionUsersLicenseCount)
      const limited = parsePositiveInteger(account.limitedUsersLicenseCount)

      setOriginalCounts([financial, production, limited])
      setNewCounts([financial, production, limited])
    },
    [account],
  )

  function handleCountChange(event) {
    const index = COUNT_INDEXES[event.target.name]

    if (index === undefined) {
      return
    }

    const newNewCounts = [ ...newCounts ]
    newNewCounts[index] = parsePositiveInteger(event.target.value)
    setNewCounts(newNewCounts)
  }

  const currencyFormatter = createCurrencyFormatter()

  function handleSubmit(event) {
    event.preventDefault()

    setIsConfirming(true)
  }

  async function updateCounts(event) {
    event.preventDefault()

    setIsConfirming(false)

    const values = {
      financialUsersLicenseCount: newCounts[0],
      productionUsersLicenseCount: newCounts[1],
      limitedUsersLicenseCount: newCounts[2],
    }

    updateAccount({
      values,
      accountId: account.id,
      onError: error => console.log({ error }),
      onSuccess: async () => {
        onClose()
        refreshStripeInfo()
      },
      shouldUpdateBilling: true,
    })
  }

  const delta = invoicePreview?.data?.status === 'success'
    ? invoicePreview.data.invoicePreview.subtotal
    : 0

  const hasEnougnLicenses = React.useMemo(
    () => newCounts[0] >= userLicenseCounts.FINANCIAL
      && newCounts[1] >= userLicenseCounts.PRODUCTION
      && newCounts[2] >= userLicenseCounts.LIMITED,
    [newCounts, userLicenseCounts]
  )

  return (
    <>
      <Dialog
        open
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <form
          onSubmit={handleSubmit}
        >
          <ClosableDialogTitle onClose={onClose}>Edit Subscription Plan</ClosableDialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    style: inputStyle,
                  }}
                  disabled={account.isOldSchool}
                  name="financialUsersLicenseCount"
                  label={account.isOldSchool ? 'Super User' : "Financial Users"}
                  value={newCounts[0].toString()}
                  type="number"
                  onChange={handleCountChange}
                  helperText={Boolean(userLicenseCounts.FINANCIAL)
                    && `${userLicenseCounts.FINANCIAL} active`
                  }
                  FormHelperTextProps={{ sx: { textAlign: 'center' }}}
                  error={newCounts[0] < userLicenseCounts.FINANCIAL}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    style: inputStyle,
                  }}
                  name="productionUsersLicenseCount"
                  label={account.isOldSchool ? 'Standard Users' : "Production Users"}
                  value={newCounts[1].toString()}
                  type="number"
                  onChange={handleCountChange}
                  helperText={Boolean(userLicenseCounts.PRODUCTION)
                    && `${userLicenseCounts.PRODUCTION} active`
                  }
                  FormHelperTextProps={{ sx: { textAlign: 'center' }}}
                  error={newCounts[1] < userLicenseCounts.PRODUCTION}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  inputProps={{
                    style: inputStyle,
                  }}
                  name="limitedUsersLicenseCount"
                  label="Limited Users"
                  value={newCounts[2].toString()}
                  type="number"
                  onChange={handleCountChange}
                  helperText={Boolean(userLicenseCounts.LIMITED)
                    && `${userLicenseCounts.LIMITED} active`
                  }
                  FormHelperTextProps={{ sx: { textAlign: 'center' }}}
                  error={newCounts[2] < userLicenseCounts.LIMITED}
                />
              </Grid>

              <Grid item xs={12}>

                <Typography variant="h6">
                  {`${
                    isDirty ? 'New billing rate' : 'Current billing rate'
                  }: ${
                    currencyFormatter.format(newPrice / 100)
                  }`}
                </Typography>

              </Grid>

              {!hasEnougnLicenses && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="error">
                    There are not enough licenses for your active users. Either increase your license count or deactivate users to update.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              type="submit"
              disabled={!isDirty || !hasEnougnLicenses}
            >
              Update Subscription
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationDialog
        isOpen={isConfirming}
        title="Update subscription?"
        onClose={() => setIsConfirming(false)}
        onConfirm={updateCounts}
      >
        <DialogContent>
          <Typography variant="subtitle" color="textSecondary">
            Summary of changes
          </Typography>

          <ChangeCounter
            newCount={newCounts[0]}
            originalCount={originalCounts[0]}
            title="Financial Users"
          />

          <ChangeCounter
            newCount={newCounts[1]}
            originalCount={originalCounts[1]}
            title="Production Users"
          />

          <ChangeCounter
            newCount={newCounts[2]}
            originalCount={originalCounts[2]}
            title="Limited Users"
          />

          <Spacer axis="vertical" size={16} />

          <Typography variant="h6">
            {`${
              previewStatus === 'loading'
                ? ''
                : delta > 0
                ? 'Upgrade charge: '
                : delta < 0
                ? 'Downgrade credit: '
                : 'No price change'
            }${
              previewStatus === 'loading'
                ? 'calculating adjustments...'
                : currencyFormatter.format(delta / 100)
            }`}
          </Typography>

          <Typography color="textSecondary">
            {delta > 0
              ? 'This amount is the prorated cost of the additional licenses for the remainder of your billing period and will be immediately charged using your selected payment method.'
              : delta < 0
              ? 'This amount is the usused portion of your downgraded licenses for the current billing period and will be credited to your account and applied to your next invoice.'
              : 'Your license counts have been changed, but the total price value is unchanged.'
            }
          </Typography>

          <Spacer axis="vertical" size={16} />

          <Typography>
            * Note that this value may be off by a slight amount as the adjusted value is calculated to-the-second when you click "Yes, do it"
          </Typography>
        </DialogContent>
      </ConfirmationDialog>
    </>
  )
}

EditSubscriptionPlan.propTypes = {
  account: PropTypes.object,
  stripeInfo: PropTypes.object,
  onClose: PropTypes.func,
  unitPrices: PropTypes.arrayOf(PropTypes.number),
}
