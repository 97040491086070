import * as React from 'react'
import { UsersContext } from './UsersContext'


export function useUsers() {
  const context = React.useContext(UsersContext)

  if (context === undefined) {
    throw new Error('useUsers must be used within UsersProvider')
  }

  return context
}
