import {
  modelFactory,
  stringFieldFactory,
} from 'pebble-orm'

import {
  createTimestampFields,
} from './mixins'


/** Singleton id='SETTINGS' */
export const AdminSettingsModel = modelFactory({
  name: 'AdminSettingsModel',
  collection: 'adminSettings',
  fields: [
    ...createTimestampFields(),

    stringFieldFactory({
      name: 'name',
    }),

    stringFieldFactory({
      name: 'defaultLiveFinancialPrice',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'defaultLiveProductionPrice',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'defaultLiveLimitedPrice',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'defaultDemoFinancialPrice',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'defaultDemoProductionPrice',
      defaultValue: '',
    }),

    stringFieldFactory({
      name: 'defaultDemoLimitedPrice',
      defaultValue: '',
    }),
  ],
})
