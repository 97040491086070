import * as React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'


export function PrintableError({ error }) {

  return typeof error === 'string' ? (
    <Typography color="error">
      {error}
    </Typography>
  ) : typeof error === 'object' ? Object.entries(error || {}).map(([key, e]) => (
    <Typography color="error" key={key}>
      {e}
    </Typography>
  )) : ''
}

PrintableError.propTypes = {
  /** The error object/string/thingy. */
  error: PropTypes.any,
}
