import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { colors } from 'genjo-ui/core/ThemeProvider'
import clsx from 'clsx'


const useStyles = makeStyles(theme => ({
  svg: {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeLiterlimit: 1.5,
  },
  line: {
    fill: 'none',
    stroke: colors.blueGrey[500],
    strokeWidth: 1,
    animation: '$drawLine 3s ease-in infinite',
  },
  horizontal: {
    strokeDasharray: 230,
    strokeDashoffset: 230,
  },
  vertical: {
    strokeDasharray: 120,
    strokeDashoffset: 120,
  },
  letter: {
    fillRule: 'nonzero',
    animation: '$drawLetter 3s ease-in-out infinite',
  },
  blueB: {
    fill: colors.blue[900],
  },
  orangeB: {
    fill: colors.amber[700],
  },
  ...(Array.from({ length: 30 }).reduce((result, _, index) => {
    result[`t${index + 1}`] = {
      fill: colors.blueGrey[50],
      animation: `$loading 2s infinite ease-in-out both`,
      animationDelay: `${(index % 15) * 4 / 30}s`,
    }

    return result
  }, {})),
  '@keyframes loading': {
    '0%': {
      fill: colors.blueGrey[100],
    },
    '50%': {
      fill: colors.blueGrey[800],
    },
  },
  '@keyframes drawLine': {
    '0%': {
      opacity: 1,
    },
    '35%': {
      strokeDashoffset: 0,
      opacity: 1,
    },
    '50%': {
      strokeDashoffset: 0,
      opacity: 0,
    },
    '100%': {
      strokeDashoffset: 0,
      opacity: 0,
    },
  },
  '@keyframes drawLetter': {
    '0%': {
      opacity: 0,
    },
    '25%': {
      filter: 'grayscale(1)',
      opacity: 0,
    },
    '40%': {
      opacity: 1,
      filter: 'grayscale(1)'
    },
    '75%': {
      opacity: 1,
      filter: 'grayscale(0)'
    },
    '85%': {
      opacity: 1,
      filter: 'grayscale(0)'
    },
    '95%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },

}))


const VIEW_BOX_ASPECT_RATIO = 250 / 140



export const Logo = ({ width = 100 }) => {
  const classes = useStyles()
  const height = width * VIEW_BOX_ASPECT_RATIO

  return (
    <svg width={width} height={height} viewBox="0 0 250 140" className={classes.svg}>
      <path id="blue_b" d="M224.47,76.111C222.279,72.62 218.999,69.945 215.139,68.501C218.411,67.064 221.151,64.635 222.97,61.558C224.08,59.657 224.943,57.623 225.539,55.504C226.243,52.931 226.58,50.271 226.539,47.603C226.626,43.476 225.835,39.377 224.22,35.578C222.72,32.22 220.445,29.264 217.583,26.955C214.511,24.511 210.964,22.732 207.168,21.734C202.737,20.54 198.163,19.961 193.574,20.012L116.563,20.012L116.563,20.553C128.06,23.441 135.753,31.315 136.378,45.978L194.699,45.978C196.464,45.977 198.198,46.457 199.711,47.367C201.211,48.269 201.975,49.95 201.975,52.449C201.975,54.949 201.239,56.795 199.781,57.74C198.258,58.688 196.492,59.17 194.699,59.128L134.1,59.128C131.865,64.183 131.323,65.974 126.685,68.293C134.35,70.987 138.169,81.721 138.169,90.774C138.203,94.69 137.65,98.589 136.531,102.341C133.753,111.27 126.81,118.324 117.66,119.754C117.427,119.809 117.199,119.884 116.979,119.976L121.673,119.851L194.254,119.851C199.833,120.001 205.399,119.246 210.737,117.616C214.652,116.409 218.236,114.313 221.207,111.492C223.793,108.943 225.703,105.787 226.761,102.313C227.876,98.56 228.424,94.662 228.386,90.746C228.321,84.766 227.016,79.888 224.47,76.111ZM200.461,92.371C199.211,93.565 197.17,94.162 194.324,94.162L146.556,94.162L146.556,80.429L194.324,80.429C196.511,80.234 198.695,80.847 200.461,82.151C201.787,83.463 202.477,85.289 202.35,87.15C202.488,89.078 201.801,90.977 200.461,92.371Z" className={clsx(classes.letter, classes.blueB)} />
      <path id="orange_b_bottom" d="M127.768,76.18C125.47,72.684 122.097,70.027 118.159,68.612C119.729,67.955 121.193,67.071 122.506,65.988C123.98,64.772 125.234,63.311 126.213,61.669C126.703,60.881 127.139,60.059 127.519,59.212L20,59.212L20,119.99L96.372,119.99C102.152,120.134 107.918,119.38 113.466,117.755C117.497,116.563 121.209,114.48 124.325,111.659C126.972,109.123 128.939,105.961 130.046,102.466C131.207,98.698 131.774,94.772 131.726,90.83C131.726,84.85 130.407,79.966 127.768,76.18ZM102.885,92.468C101.58,93.663 99.469,94.26 96.525,94.26L47.022,94.26L47.022,80.513L96.525,80.513C99.469,80.513 101.58,81.082 102.885,82.234C104.249,83.524 104.963,85.361 104.829,87.233C104.969,89.176 104.259,91.088 102.885,92.468Z" className={clsx(classes.letter, classes.orangeB)} />
      <path id="orange_b_top" d="M20,45.992L20,20.012L95.734,20.012C100.486,19.953 105.225,20.523 109.828,21.706C113.74,22.714 117.41,24.499 120.617,26.955C123.593,29.249 125.975,32.226 127.56,35.633C129.024,38.907 129.831,42.436 129.935,46.02L20,45.992Z" className={clsx(classes.letter, classes.orangeB)} />
      <path id="v_11" d="M240,130.008L240,9.992" className={clsx(classes.line, classes.vertical)} />
      <path id="v_10" d="M228.388,10.008L228.388,130.008" className={clsx(classes.line, classes.vertical)} />
      <path id="v_8" d="M202.647,10.008L202.647,130.008" className={clsx(classes.line, classes.vertical)} />
      <path id="v_9" d="M214.518,130.008L214.518,10.008" className={clsx(classes.line, classes.vertical)} />
      <path id="v_7" d="M146.549,129.992L146.549,9.992" className={clsx(classes.line, classes.vertical)} />
      <path id="v_6" d="M138.142,10.008L138.142,130.008" className={clsx(classes.line, classes.vertical)} />
      <path id="v_5" d="M126.132,129.992L126.132,9.992" className={clsx(classes.line, classes.vertical)} />
      <path id="v_4" d="M117.245,10.008L117.245,130.008" className={clsx(classes.line, classes.vertical)} />
      <path id="v_3" d="M104.693,130.008L104.693,10.008" className={clsx(classes.line, classes.vertical)} />
      <path id="v_2" d="M47.14,9.992L47.14,129.992" className={clsx(classes.line, classes.vertical)} />
      <path id="v_1" d="M20,130.008L20,10.008" className={clsx(classes.line, classes.vertical)} />
      <path id="v_0" d="M10,9.992L10,130.008" className={clsx(classes.line, classes.vertical)} />
      <path id="h_7" d="M240,130.008L10,130.008" className={clsx(classes.line, classes.horizontal)} />
      <path id="h_6" d="M10,119.976L240,120.008" className={clsx(classes.line, classes.horizontal)} />
      <path id="h_5" d="M240,94.243L10,94.243" className={clsx(classes.line, classes.horizontal)} />
      <path id="h_4" d="M10,80.475L240,80.475" className={clsx(classes.line, classes.horizontal)} />
      <path id="h_3" d="M240,59.212L10,59.212" className={clsx(classes.line, classes.horizontal)} />
      <path id="h_2" d="M10,46.02L240,46.02" className={clsx(classes.line, classes.horizontal)} />
      <path id="h_1" d="M240,20.009L10,20.008" className={clsx(classes.line, classes.horizontal)} />
      <path id="h_0" d="M10,9.992L240,9.992" className={clsx(classes.line, classes.horizontal)} />
    </svg>
  )
}

Logo.propTypes = {}
