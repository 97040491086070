import * as React from 'react'

import { FileMakerDataContext } from './FileMakerDataContext'

import { getFileMakerAccountData } from 'src/services/file-maker'
import { useAccount } from 'src/components/AccountProvider'


export function FileMakerDataProvider({ children }) {
  const { account } = useAccount()
  const [data, setData] = React.useState(null)
  const [update, forceUpdate] = React.useReducer(state => state + 1, 0)

  function handleRefresh() {
    forceUpdate()
  }

  React.useEffect(
    () => {
      // Bail out for old school accounts
      if (!account || account.isOldSchool) {
        return
      }

      let isActive = true

      ;(async () => {
        const result = await getFileMakerAccountData({ account })

        console.log({ result })

        if (isActive) {
          setData(result?.data)
        }
      })()

      return () => isActive = false
    },
    [account, update]
  )

  return (
    <FileMakerDataContext.Provider
      value={{
        handleRefresh,
        data,
      }}
    >
      {children}
    </FileMakerDataContext.Provider>
  )
}

FileMakerDataProvider.propTypes = {

}
