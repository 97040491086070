import * as React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import ClosableDialogTitle from 'genjo-ui/core/ClosableDialogTitle'
import Checkbox from 'genjo-ui/core/Checkbox'

import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'

import { useAccount } from 'src/components/AccountProvider'
import { UserModel, AccessLevelChoices } from 'src/data-model'
import useInstanceValues from 'src/components/useInstanceValues'
import { firebaseFunctions, firebaseDb } from 'src/services/firebase'
import AccessLevelField from 'src/components/AccessLevelField'


const _ALLOWED_FIELDS = [
  'name', 'email', 'isAccountAdmin', 'isActive', 'accessLevel',
  'accessGroupId', 'profitCenterId',
]


export function AddUser({ onClose }) {
  const loader = useLoader()
  const snackbar = useSnackbar()

  const [sendActivationEmail, setSendActivationEmail] = React.useState(true)
  const {
    account,
    users,
    profitCenters,
    accessGroups,
    licensesAvailable,
  } = useAccount()

  const [userInstance, setUserInstance] = React.useState(null)
  React.useEffect(
    () => {
      if (account) {
        setUserInstance(UserModel.create({
          accountId: account.id,
          ...(account.isOldSchool ? { accessLevel: 'FINANCIAL', isAccountAdmin: true } : {}),
        }))
      }
    },
    [account]
  )

  const { values, setFieldValue, setValues } = useInstanceValues({
    instance:  userInstance,
    model: UserModel,
    fields: _ALLOWED_FIELDS,
  })

  async function handleSubmit(event) {
    event.preventDefault()

    try {
      loader.open('Adding user...')

      // Make sure there is an email
      if (!values.email) {
        throw new Error('Email is required')
      }

      const { email, ...restValues } = values
      const cleanedEmail = email.toLowerCase().trim()

      // Verify that no user with that email exists.
      const usersSnapshot = await firebaseDb.collection(UserModel.collection)
        .where('email', '==', cleanedEmail)
        .where('accountId', '==', account.id)
        .get()

      if (!usersSnapshot.empty) {
        throw new Error('A user with that email already exists on this account.')
      }

      const ref = firebaseDb.collection(UserModel.collection).doc()
      const userId = ref.id
      await ref.set(UserModel.toDb({
        ...restValues,
        email: cleanedEmail,
        accountId: account.id,
        fmid: userId,
      }))

      // Only update the filemaker info for new cloud accounts. Old school accounts
      // can skip this part.
      if (!account.isOldSchool) {
        const updateFileMakerUser = firebaseFunctions.httpsCallable('updateFileMakerUser')
        const updateFileMakerResponse = await updateFileMakerUser({ userId })

        if (updateFileMakerResponse.data.status !== 'success') {
          await ref.delete()
          throw new Error(updateFileMakerResponse?.error ?? 'Something went wrong syncing with FileMaker')
        }
      }

      const updateAuthUser = firebaseFunctions.httpsCallable('updateAuthUser')
      const updateAuthUserResponse = await updateAuthUser({ userId, sendActivationEmail })

      if (updateAuthUserResponse.data.status !== 'success') {
        // await ref.delete()
        console.log({ updateAuthUserResponse })
        // throw new Error(updateAuthUserResponse?.error ?? 'Something went creating auth user')
      }

      snackbar.addMessage({
        text: 'User added',
        type: 'success',
      })

      onClose()
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem adding user.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  const emailIsTaken = React.useMemo(
    () => {
      return Boolean(values?.email)
        && Boolean(users.find(user => user.email === values?.email))
    },
    [values?.email, users]
  )

  const hasNoAvailableLicenses = licensesAvailable['FINANCIAL'] <= 0
    && licensesAvailable['PRODUCTION'] <= 0
    && licensesAvailable['LIMITED'] <= 0

  return (
    <Dialog
      open
      maxWidth="sm"
      onClose={onClose}
      fullWidth
    >
      {hasNoAvailableLicenses ? (
        <>
          <ClosableDialogTitle onClose={onClose}>No Licenses Available</ClosableDialogTitle>
          <DialogContent>
            You have no available licences. Please update your account subscription to add new users.
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              Got it
            </Button>
          </DialogActions>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <ClosableDialogTitle onClose={onClose}>Add User</ClosableDialogTitle>

          <DialogContent>
            {!values ? (
              'Loading'
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    value={values.name}
                    required
                    onChange={event => setFieldValue('name', event.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    value={values.email}
                    required
                    onChange={event => {
                      const cleanedEmail = event.target.value.toLowerCase().trim()
                      setFieldValue('email', cleanedEmail)
                    }}
                    error={emailIsTaken}
                    helperText={emailIsTaken
                      ? 'This email is already in use.'
                      : ''
                    }
                  />
                </Grid>

                {account.isOldSchool ? (
                  null
                ) : (
                  <>
                  <Grid item xs={12}>
                    <AccessLevelField
                      label="Access Level"
                      value={values.accessLevel}
                      onChange={event => setValues({
                        ...values,
                        'accessLevel': event.target.value,
                        'accessGroupId': null,
                        'isAccountAdmin': false,
                      })}
                      error={!licensesAvailable[values.accessLevel]}
                      helperText={!licensesAvailable[values.accessLevel]
                        && `There are no available ${AccessLevelChoices.getLabel(values.accessLevel)} licenses. Either select a different level with available licenses or add more in the "Account Overview" section.`
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Access Group"
                      value={values.accessGroupId ?? ''}
                      onChange={event => setFieldValue('accessGroupId', event.target.value)}
                      required
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>Please select an access group...</MenuItem>

                      {accessGroups.filter(ag => ag.accessLevel === values.accessLevel).map(ag => (
                        <MenuItem value={ag.id} key={ag.id}>
                          {ag.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Profit Center"
                      value={values.profitCenterId ?? ''}
                      onChange={event => setFieldValue('profitCenterId', event.target.value)}
                      required
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" disabled>Please select a profit center...</MenuItem>

                      {profitCenters.map(pc => (
                        <MenuItem value={pc.id} key={pc.id}>
                          {pc.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>


                  {values.accessLevel === 'FINANCIAL' && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="Account Administrator"
                        control={
                          <Checkbox
                            checked={values.isAccountAdmin}
                            onChange={event => setFieldValue('isAccountAdmin', event.target.checked)}
                          />
                        }
                      />
                    </Grid>
                  )}
                </>
              )}

                <Grid item xs={12}>
                  <FormControlLabel
                    label="Send Activation Email"
                    control={
                      <Checkbox
                        checked={sendActivationEmail}
                        onChange={event => setSendActivationEmail(event.target.checked)}
                      />
                    }
                  />
                </Grid>
              </Grid>
          )}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              type="submit"
              disabled={licensesAvailable[values?.accessLevel] <= 0}
            >
              Add User
            </Button>
          </DialogActions>

        </form>
      )}
    </Dialog>
  )
}

AddUser.propTypes = {
  onClose: PropTypes.func,
}
