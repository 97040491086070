import * as React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import useInstanceValues from 'src/components/useInstanceValues'
import { UserModel } from 'src/data-model'

import { firebaseFunctions, firebaseDb } from 'src/services/firebase'

import { colors } from 'genjo-ui/core/ThemeProvider'

import SaveButtonsDialog from 'genjo-ui/core/SaveButtonsDialog'
import Checkbox from 'genjo-ui/core/Checkbox'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'

import { useAccount } from 'src/components/AccountProvider'
import { useParams } from 'react-router-dom'

import BackIcon from '@material-ui/icons/ArrowBackRounded'
import EmailIcon from '@material-ui/icons/EmailRounded'
import ForceUpdateIcon from '@material-ui/icons/UploadRounded'
import ActivationIcon from '@material-ui/icons/LinkRounded'
import LinkBehavior from 'src/components/LinkBehavior'

import GhostLoginButton from 'src/components/GhostLoginButton'
import { useAuth } from 'src/components/AuthProvider'

import { ActivationLinkDialog } from './ActivationLinkDialog'
import { ActivateUserDialog } from './ActivateUserDialog'
import { DeactivateUserDialog } from './DeactivateUserDialog'


const _ALLOWED_FIELDS = [
  'name', 'email', 'accessGroupId', 'profitCenterId', 'isActive',
  'accessLevel', 'isAccountAdmin', 'fmid',
]

export function AccountUser() {
  const { user: systemUser } = useAuth()
  const loader = useLoader()
  const snackbar = useSnackbar()

  const { userId } = useParams()

  const [isGettingLink, setIsGettingLink] = React.useState(false)

  const { account, users, accessGroups, profitCenters, licensesAvailable } = useAccount()

  const [requestActivateUserId, setRequestActivateUserId] = React.useState(null)

  const user = users?.find(u => u.id === userId)

  const [requestDeactivateUserId, setRequestDeactivateUserId] = React.useState(null)

  const { values, setFieldValue, setValues, resetValues, isDirty } = useInstanceValues({
    model: UserModel,
    instance: user,
    fields: _ALLOWED_FIELDS,
  })


  async function handleUpdateUser({ shouldUpdateFirebase = true }) {
    try {
      loader.open('Updating user...')

      // Update the firebase values before updating file maker
      if (shouldUpdateFirebase) {
        const ref = firebaseDb.collection(UserModel.collection).doc(user.id)
        await ref.update(UserModel.toDb(values))
      }

      const updateFileMakerUser = firebaseFunctions.httpsCallable('updateFileMakerUser')
      const updateFileMakerResponse = await updateFileMakerUser({ userId: user.id })

      if ( updateFileMakerResponse.data.status !== 'success') {
        console.log({ updateFileMakerResponse })
        // await ref.delete()
        // throw new Error(updateFileMakerResponse?.error ?? 'Something went wrong syncing with FileMaker')
      }

      const updateAuthUser = firebaseFunctions.httpsCallable('updateAuthUser')
      const updateAuthUserResponse = await updateAuthUser({ userId: user.id })

      if (updateAuthUserResponse.data.status !== 'success') {
        // await ref.delete()
        console.log({ updateAuthUserResponse })
        // throw new Error(updateAuthUserResponse?.error ?? 'Something went creating auth user')
      }

      snackbar.addMessage({
        text: 'User updated',
        type: 'success',
      })
    } catch (error) {
      console.log({ error })
      snackbar.addMessage({
        text: error?.message ?? 'There was a problem updating user.',
        type: 'error',
      })
    } finally {
      loader.close()
    }
  }

  async function handleResendActivationEmail() {
    try {
      loader.open('Sending activation email...')
      const sendActivationEmail = firebaseFunctions.httpsCallable('sendActivationEmail')

      const response = await sendActivationEmail({ userId: user.authUserId })

      console.log(response?.data?.token)
    } catch (error) {
      console.log({ error })
      snackbar.error(error?.message ?? 'There was a problem sending email')
    } finally {
      loader.close()
    }
  }

  if (!values) {
    return 'Loading...'
  }

  const hasLicensesAvailable = licensesAvailable.FINANCIAL > 0
    || licensesAvailable.PRODUCTION > 0
    || licensesAvailable.LIMITED > 0

  return (
    <>
      <Button
        component={LinkBehavior}
        to={`/accounts/${account.id}/users`}
        startIcon={<BackIcon />}
      >
        Back to users
      </Button>
      <Typography variant="h1">{user.name}</Typography>
      {!user.isActive && (
        <Typography variant="h2" color="text.secondary"><em>Deactivated</em></Typography>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Actions</Typography>
              <Grid container spacing={1} alignItems="center">
                {user.isActive ? (
                  <>
                    <Grid item>
                      <Button
                        onClick={handleResendActivationEmail}
                        startIcon={<EmailIcon />}
                        variant="contained"
                      >
                        Resend Activation Email
                      </Button>
                    </Grid>

                    {!account.isOldSchool && (
                      <Grid item>
                        <Button
                          onClick={() => handleUpdateUser({ shouldUpdateFirebase: false })}
                          startIcon={<ForceUpdateIcon />}
                          variant="contained"
                        >
                          Manually Update User
                        </Button>
                      </Grid>
                    )}

                    <Grid item>
                      <Button
                        onClick={() => setIsGettingLink(true)}
                        startIcon={<ActivationIcon />}
                        variant="contained"
                      >
                        Get Activation Link
                      </Button>
                    </Grid>

                    {systemUser.isAdmin && !account.isOldSchool && (
                      <Grid item>
                        <GhostLoginButton
                          disabled={!user.isActive}
                          account={account}
                          user={user}
                        />
                      </Grid>
                    )}

                    {!account.isOldSchool && (
                      <Grid item>
                        <Button
                          onClick={() => setRequestDeactivateUserId(user.id)}
                          variant="contained"
                        >
                          {`Deactivate User`}
                        </Button>
                      </Grid>
                    )}
                  </>

                ) : (
                  <Grid item>
                    <Button
                      onClick={() => setRequestActivateUserId(user.id)}
                      variant="contained"
                      disabled={!hasLicensesAvailable || !user.email}
                    >
                      {`Activate User${!user?.email ? ' (Please update email before activating)': ''}`}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Name"
                    value={values.name}
                    onChange={event => setFieldValue('name', event.target.value)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    value={values.email}
                    onChange={event => {
                      const cleanedEmail = event.target.value.toLowerCase().trim()
                      setFieldValue('email', cleanedEmail)
                    }}
                  />
                </Grid>

                {user.isActive && !account.isOldSchool && (
                  <Grid item xs={12}>
                    <TextField
                      label="Access Group"
                      value={values.accessGroupId}
                      onChange={event => setValues({
                        ...values,
                        accessGroupId: event.target.value,
                        accessLevel: accessGroups?.find(ag => ag.id === event.target.value)?.accessLevel ?? 'LIMITED',
                      })}
                      select
                    >
                      {accessGroups?.map((accessGroup, index) => (
                        <MenuItem
                          key={accessGroup.id}
                          value={accessGroup.id}
                          disabled={accessGroup.accessLevel !== user?.accessLevel
                            && licensesAvailable[accessGroup.accessLevel] < 1
                          }
                          divider={accessGroups[index + 1]?.accessLevel !== accessGroup.accessLevel}
                        >
                          {`${accessGroup.name} (${accessGroup.accessLevel} - ${
                            accessGroup.accessLevel === user?.accessLevel
                              ? 'Current Access Level'
                              : `${licensesAvailable[accessGroup.accessLevel]} available`
                          })`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                {user.isActive && !account.isOldSchool && (
                  <Grid item xs={12}>

                    <TextField
                      label="Profit Center"
                      value={values.profitCenterId}
                      onChange={event => setFieldValue('profitCenterId', event.target.value)}
                      select
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {profitCenters?.map(profitCenter => (
                        <MenuItem key={profitCenter.id} value={profitCenter.id}>
                          {profitCenter.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                {user.isActive && values.accessLevel === 'FINANCIAL' && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Account Administrator"
                      control={
                        <Checkbox
                          disabled={account.isOldSchool}
                          checked={values.isAccountAdmin}
                          onChange={event => setFieldValue('isAccountAdmin', event.target.checked)}
                        />
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {systemUser.isAdmin && (
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: colors.blueGrey[50] }}>
              <CardContent>
                <Typography variant="h6">***Admin Only***</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="colorSecondary">
                      File Maker Username
                    </Typography>
                    <TextField
                      label="FileMaker ID"
                      value={values.fmid}
                      onChange={event => setFieldValue('fmid', event.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="colorSecondary">
                      File Maker Password
                    </Typography>
                    <Typography>
                      {user.fmPassword}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="colorSecondary">
                      Account Name
                    </Typography>
                    <Typography>
                      {account.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="colorSecondary">
                      BBID
                    </Typography>
                    <Typography>
                      {account.BBID}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

        )}
      </Grid>

      <SaveButtonsDialog
        isIn={isDirty}
        onCancel={resetValues}
        onSave={() => handleUpdateUser({ shouldUpdateFirebase: true })}
      />

      <ActivationLinkDialog
        userId={user.authUserId}
        isOpen={isGettingLink}
        onClose={() => setIsGettingLink(false)}
      />

      <ActivateUserDialog
        userId={requestActivateUserId}
        isOpen={Boolean(requestActivateUserId)}
        onClose={() => setRequestActivateUserId(null)}
      />

      <DeactivateUserDialog
        userId={requestDeactivateUserId}
        isOpen={Boolean(requestDeactivateUserId)}
        onClose={() => setRequestDeactivateUserId(null)}
      />
    </>
  )
}

AccountUser.propTypes = {
  account: PropTypes.object,
  user: PropTypes.object,
  accessGroups: PropTypes.arrayOf(PropTypes.object),
  profitCenters: PropTypes.arrayOf(PropTypes.object),
}
