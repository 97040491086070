import * as React from 'react'

import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'

import AllGoodIcon from '@material-ui/icons/TaskAltRounded'
import TooManyIcon from '@material-ui/icons/ErrorRounded'
import TooFewIcon from '@material-ui/icons/WarningRounded'

import { colors } from 'genjo-ui/core/ThemeProvider'

const { red, blue, green, yellow, common } = colors


function getContent(countUsed, countTotal) {
  const delta = Math.abs(countTotal - countUsed)
  const pluralized = delta === 1 ? '' : 's'

  if (countUsed > countTotal) {
    return {
      IconComponent: TooManyIcon,
      message: `${delta} user${pluralized} without license!`,
      color: red[300],
      iconColor: red.A400,
    }
  }

  if (countUsed < countTotal) {
    return {
      IconComponent: TooFewIcon,
      message: `${delta} license${pluralized} available`,
      color: blue[200],
      iconColor: yellow.A700,
    }
  }

  return {
    IconComponent: AllGoodIcon,
    message: 'All licenses used',
    color: common.black,
    iconColor: green.A400
  }
}


export function LicenseCounter({ accessLevel, countUsed = 0, countTotal = 0 }) {

  const { IconComponent, message, color, iconColor } = getContent(countUsed, countTotal)

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
          <Grid item style={{ width: '100%' }}>
            <Typography variant="subtitle2" color="textSecondary">
              {accessLevel}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h3" style={{ color }}>{countUsed}</Typography>
          </Grid>

        </Grid>
      </CardContent>

      <CardActions style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
        <IconComponent style={{ color: iconColor, marginRight: 8 }} />

        <Typography color="textSecondary">
          {message}
        </Typography>
      </CardActions>
    </Card>
  )
}

LicenseCounter.propTypes = {

}
